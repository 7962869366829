import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_EQUIPMENTS_SUCCESS,
  FETCH_EQUIPMENTS_FAILURE,
  FETCH_EQUIPMENTS_REQUEST,
  CLEAR_EQUIPMENTS_REQUEST,
  DELETE_EQUIPMENT_FAILURE,
  DELETE_EQUIPMENT_REQUEST,
  DELETE_EQUIPMENT_SUCCESS,
  CREATE_EQUIPMENT_REQUEST,
  UPDATE_EQUIPMENT_FAILURE,
  UPDATE_EQUIPMENT_SUCCESS,
  UPDATE_EQUIPMENT_REQUEST,
  CREATE_EQUIPMENT_FAILURE,
  CREATE_EQUIPMENT_SUCCESS,
  CALCULATE_EQUIPMENT_CELL_BLOCK_MAPPINGS_SUCCESS,
  CALCULATE_EQUIPMENT_CELL_BLOCK_MAPPINGS_FAILURE,
  CALCULATE_EQUIPMENT_CELL_BLOCK_MAPPINGS_REQUEST,
  CLEAR_EQUIPMENT_CELL_BLOCK_MAPPINGS_REQUEST,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type { NewEquipment, PatchEquipment } from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchEquipmentsRequest = () => ({
  type: FETCH_EQUIPMENTS_REQUEST,
});

const fetchEquipmentsSuccess = (response: any) => ({
  type: FETCH_EQUIPMENTS_SUCCESS,
  response,
});

const fetchEquipmentsFailure = (error: any) => ({
  type: FETCH_EQUIPMENTS_FAILURE,
  error,
});

export const fetchEquipments =
  (productInstanceId?: string) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchEquipmentsRequest());
    axios
      .get('/api/equipments', {
        params: {
          ...(productInstanceId
            ? { product_instance_id: productInstanceId }
            : {}),
        },
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(fetchEquipmentsSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error)) dispatch(fetchEquipmentsFailure('canceled'));
        else dispatch(fetchEquipmentsFailure(error));
      });
  };

const deleteEquipmentRequest = (id: string) => ({
  type: DELETE_EQUIPMENT_REQUEST,
  id,
});

const deleteEquipmentSuccess = (response: any, id: string) => ({
  type: DELETE_EQUIPMENT_SUCCESS,
  response,
  id,
});

const deleteEquipmentFailure = (error: any) => ({
  type: DELETE_EQUIPMENT_FAILURE,
  error,
});

export const deleteEquipment =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteEquipmentRequest(id));
    fetch(`/api/equipments/${id}`, {
      method: 'DELETE',
      headers: getHTTPRequestHeaders(getState()),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(deleteEquipmentSuccess(result, id)))
      .catch((error) => dispatch(deleteEquipmentFailure(error)));
  };

const createEquipmentRequest = (newEquipment: NewEquipment) => ({
  type: CREATE_EQUIPMENT_REQUEST,
  newEquipment,
});

const createEquipmentSuccess = (response: any) => ({
  type: CREATE_EQUIPMENT_SUCCESS,
  response,
});

const createEquipmentFailure = (error: any) => ({
  type: CREATE_EQUIPMENT_FAILURE,
  error,
});

export const createEquipment =
  (newEquipment: NewEquipment) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(createEquipmentRequest(newEquipment));
    return axios
      .post('/api/equipments', newEquipment, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(createEquipmentSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createEquipmentFailure(err.message));
      });
  };

const updateEquipmentRequest = (id: string, patch: PatchEquipment) => ({
  type: UPDATE_EQUIPMENT_REQUEST,
  id,
  patch,
});

const updateEquipmentSuccess = (response: any) => ({
  type: UPDATE_EQUIPMENT_SUCCESS,
  response,
});

const updateEquipmentFailure = (error: any) => ({
  type: UPDATE_EQUIPMENT_FAILURE,
  error,
});

export const updateEquipment =
  (id: string, patch: PatchEquipment) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateEquipmentRequest(id, patch));
    return axios
      .patch(`/api/equipments/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(updateEquipmentSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateEquipmentFailure(err.message));
      });
  };

const calculateEquipmentCellBlockMappingsRequest = (response: any) => ({
  type: CALCULATE_EQUIPMENT_CELL_BLOCK_MAPPINGS_REQUEST,
  response,
});

const calculateEquipmentCellBlockMappingsSuccess = (response: any) => ({
  type: CALCULATE_EQUIPMENT_CELL_BLOCK_MAPPINGS_SUCCESS,
  response,
});

const calculateEquipmentCellBlockMappingsFailure = (error: any) => ({
  type: CALCULATE_EQUIPMENT_CELL_BLOCK_MAPPINGS_FAILURE,
  error,
});

export const calculateEquipmentCellBlockMappings =
  (patchEquipment: PatchEquipment) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(calculateEquipmentCellBlockMappingsRequest(patchEquipment));
    return axios
      .post('/api/equipments/calculatemappings', patchEquipment, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(calculateEquipmentCellBlockMappingsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(calculateEquipmentCellBlockMappingsFailure(err.message));
      });
  };

const clearEquipmentsRequest = () => ({
  type: CLEAR_EQUIPMENTS_REQUEST,
});

export const clearEquipments = () => (dispatch: Dispatch) => {
  dispatch(clearEquipmentsRequest());
};

const clearEquipmentCellBlockMappingsRequest = () => ({
  type: CLEAR_EQUIPMENT_CELL_BLOCK_MAPPINGS_REQUEST,
});

export const clearEquipmentCellBlockMappings = () => (dispatch: Dispatch) => {
  dispatch(clearEquipmentCellBlockMappingsRequest());
};
