import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  FalconUIRenderingContext,
  Footer,
  Header,
  KeyVisual,
  SectionView,
  TopPage,
} from '@nutmeglabs/falcon-ui';

import { Box } from 'client/components/Box/Box';
import { bookingWidgetProductSummariesSelector } from 'client/reducers/products';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { getOrderedProductIds } from 'client/libraries/util/getOrderedProductIds';

import styles from './TemplateContent.module.css';
import { AddSectionButton } from './AddSectionButton';
import { RemoveSectionButton } from './RemoveSectionButton';
import { EditKeyVisualSection } from './EditKeyVisualSection';
import { EditHeader } from './EditHeader/EditHeader';
import { EditSection } from './EditSection';
import { EditFooter } from './EditFooter';
import { MoveSectionUpButton } from './MoveSectionUpButton/MoveSectionUpButton';
import { MoveSectionDownButton } from './MoveSectionDownButton/MoveSectionDownButton';

interface Props {
  editMode?: boolean;
  pageData: TopPage;
}

// t('Travel Agency Registration')
// t('Agreement')

export const TemplateContent = ({ editMode, pageData }: Props) => {
  const summaries = useSelector(bookingWidgetProductSummariesSelector);
  const org = useSelector(activeUserOrganizationSelector);
  const { language } = React.useContext(FalconUIRenderingContext);

  const products = getOrderedProductIds(org, summaries, language).map((id) =>
    summaries.find((s) => s.id === id)
  );

  const operatingHoursRules = org?.operating_hours_rules;
  let operatingHoursDescription = '';
  if (operatingHoursRules && operatingHoursRules.length > 0) {
    operatingHoursDescription = `${operatingHoursRules[0].start_time_local} - ${operatingHoursRules[0].end_time_local}`;
  }

  return (
    <div>
      <div className={styles['base-wrap']}>
        <Header data={pageData} />
        <main>
          {editMode && (
            <Box
              className={styles['opacity-hover']}
              zIndex={1000}
              width="100%"
              position="absolute"
            >
              <Box mt={2} display="flex" justifyContent="center">
                <EditHeader />
              </Box>
            </Box>
          )}
          {editMode && (
            <Box
              className={styles['opacity-hover']}
              top={64}
              zIndex={1000}
              width="100%"
              position="absolute"
            >
              <Box display="flex" justifyContent="center">
                <EditKeyVisualSection />
              </Box>
            </Box>
          )}
          <KeyVisual data={pageData} />

          {pageData.sections?.map((section, index) => {
            return (
              <>
                {editMode && (
                  <Box display="flex" justifyContent="center">
                    <Box
                      className={styles['opacity-hover']}
                      position="absolute"
                      zIndex={1000}
                    >
                      <AddSectionButton index={index} />
                    </Box>
                    <Box
                      mt={12}
                      position="absolute"
                      zIndex={1000}
                      display="flex"
                      alignItems="center"
                    >
                      {index > 0 && (
                        <Box className={styles['opacity-hover']} mr={2}>
                          <MoveSectionUpButton index={index} />
                        </Box>
                      )}
                      <div className={styles['opacity-hover']}>
                        <RemoveSectionButton index={index} />
                      </div>
                      <Box className={styles['opacity-hover']} ml={2}>
                        <EditSection name={`sections.${index}`} />
                      </Box>

                      {index < pageData.sections.length - 1 && (
                        <Box className={styles['opacity-hover']} ml={2}>
                          <MoveSectionDownButton index={index} />
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}
                <SectionView data={section} products={products as any} />
              </>
            );
          })}

          {editMode && (
            <Box
              className={styles['opacity-hover']}
              display="flex"
              justifyContent="center"
              position="relative"
            >
              <Box position="absolute" top={-36} zIndex={1000}>
                <AddSectionButton index={pageData.sections?.length ?? 0} />
              </Box>
            </Box>
          )}
        </main>
        {editMode && (
          <Box
            className={styles['opacity-hover']}
            zIndex={1000}
            width="100%"
            position="absolute"
          >
            <Box mt={2} display="flex" justifyContent="center">
              <EditFooter />
            </Box>
          </Box>
        )}
        <Footer
          data={pageData}
          orgInfo={{
            phone: org?.office_phone ?? '',
            email: org?.office_email ?? '',
            address: org?.office_mailing_address ?? '',
            operatingHoursDescription,
            hideFooterJapaneseTermsLink:
              org?.booking_widget_design_params
                ?.hide_footer_japanese_terms_link ?? false,
            snsIds: (org?.sns_ids ?? []) as { key: string; id: string }[],
            showTravelAgencyRegistrationForm:
              org?.reservation_payment_gateway_settings?.payment_gateway ===
              'GMO',
          }}
        />
      </div>
    </div>
  );
};
