import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';

import * as Swagger from 'shared/models/swagger';
import { formattedDuration } from 'client/libraries/util/formattedDuration';
import { ReduxState } from 'client/reducers';
import { Box } from 'client/components/Box/Box';

import styles from './CheckAvailabilityEvent.module.css';

interface Props {
  event: Exclude<
    Exclude<Swagger.AnalyticsCustomer['sessions'], undefined>[number]['events'],
    undefined
  >[number];
  durationInSeconds: number;
}

export const CheckAvailabilityEvent = ({ event, durationInSeconds }: Props) => {
  const { t } = useTranslation();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const allProducts = useSelector(
    (state: ReduxState) => state.products.summaries
  );

  const productId = event?.product_id;
  const product = productId
    ? allProducts.find((p) => p.id === productId)
    : null;
  const productThumbnailUrl = product?.media?.find(
    (m) => m.type === 'IMAGE'
  )?.url;

  return (
    <>
      <div className={styles['container']}>
        <div className={styles['timeline']}>
          <div className={styles['icon']}>
            <i className="c-icon-outline-communication-annotation-check"></i>
          </div>

          <div className={styles['duration']}>
            {formattedDuration(durationInSeconds, t)}
          </div>
        </div>
        <div className={styles['content']}>
          <div className={styles['header']}>
            <div className={styles['title']}>{t('Check Availability')}</div>
            <div className={styles['timestamp']}>
              {moment(event.event_date_time_utc).format('H:mm:ss')}
            </div>
          </div>
          <Box display="flex" gap={8}>
            <div className={styles['attr']}>
              <div className={styles['attr-name']}>{t('Start Time')}</div>
              <div className={styles['attr-value']}>
                {moment
                  .parseZone(event.availability_date_time_local)
                  .locale(locale)
                  .format('lll')}
              </div>
            </div>
            <div className={styles['attr']}>
              <div className={styles['attr-name']}>{t('Pax')}</div>
              <div className={styles['attr-value']}>
                {event.availability_guests
                  ?.filter((g) => (g.guest_count ?? 0) > 0)
                  ?.map((g) => `${g.guest_type} (x${g.guest_count})`)
                  .join(', ')}
              </div>
            </div>
          </Box>
          <div className={styles['body']}>
            <div className={styles['body-inner']}>
              {productThumbnailUrl && (
                <img
                  loading="lazy"
                  src={productThumbnailUrl}
                  className={styles['thumbnail']}
                />
              )}
              <div className={styles['description']}>
                <div className={styles['page-title']}>
                  {product?.product_name}
                </div>
                <a
                  className={styles['link']}
                  href={event.page_path}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className=""></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
