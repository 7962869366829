import * as React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import clsx from 'clsx';

import { Box } from 'client/components/Box/Box';
import { DragHandle } from 'client/components/Icons/DragHandle';
import { TextField } from 'client/components/v3/Form/TextField';

import styles from './SortableMenuItem.module.css';
import { CustomMenuItem } from './formValues';

interface Props {
  menuItem: CustomMenuItem;
  onDelete: () => void;
  onChange: (updates: Partial<CustomMenuItem>) => void;
}

export const SortableSubMenuItem = ({
  menuItem,
  onDelete,
  onChange,
}: Props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: menuItem.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <Box
      ref={setNodeRef}
      style={style}
      display="flex"
      alignItems="flex-start"
      gap={2}
      className={styles.subMenuItem}
    >
      <div
        {...attributes}
        {...listeners}
        style={{
          cursor: 'grab',
          display: 'flex',
          alignItems: 'center',
          paddingTop: '24px',
        }}
      >
        <DragHandle />
      </div>

      <div className={styles.fields}>
        <TextField
          label="Label"
          value={menuItem.label}
          onChange={(value) => onChange({ label: value })}
        />
        <TextField
          label="URL"
          value={menuItem.url}
          onChange={(value) => onChange({ url: value })}
        />
      </div>

      <a
        className={clsx(styles.button, styles.delete)}
        onClick={(e) => {
          e.preventDefault();
          onDelete();
        }}
      >
        <i className="c-icon-outline-general-trash-03" />
      </a>
    </Box>
  );
};
