import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  CREATE_EQUIPMENT_FAILURE,
  CREATE_EQUIPMENT_REQUEST,
  CREATE_EQUIPMENT_SUCCESS,
  DELETE_EQUIPMENT_FAILURE,
  DELETE_EQUIPMENT_REQUEST,
  DELETE_EQUIPMENT_SUCCESS,
  UPDATE_EQUIPMENT_FAILURE,
  UPDATE_EQUIPMENT_REQUEST,
  UPDATE_EQUIPMENT_SUCCESS,
  CALCULATE_EQUIPMENT_CELL_BLOCK_MAPPINGS_SUCCESS,
  CALCULATE_EQUIPMENT_CELL_BLOCK_MAPPINGS_FAILURE,
  CALCULATE_EQUIPMENT_CELL_BLOCK_MAPPINGS_REQUEST,
  CLEAR_EQUIPMENT_CELL_BLOCK_MAPPINGS_REQUEST,
  FETCH_EQUIPMENTS_FAILURE,
  FETCH_EQUIPMENTS_REQUEST,
  FETCH_EQUIPMENTS_SUCCESS,
  CLEAR_EQUIPMENTS_REQUEST,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type {
  Equipment,
  ListEquipmentsResponse,
  CreateEquipmentResponse,
  UpdateEquipmentResponse,
  CalculateEquipmentCellBlockMappingsResponse,
} from 'shared/models/swagger';

export const equipmentsSelector = (state: ReduxState) => state.equipments.all;

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_EQUIPMENTS_FAILURE:
    case CREATE_EQUIPMENT_FAILURE:
    case UPDATE_EQUIPMENT_FAILURE:
    case DELETE_EQUIPMENT_FAILURE:
    case CALCULATE_EQUIPMENT_CELL_BLOCK_MAPPINGS_FAILURE:
      return action.error;

    case FETCH_EQUIPMENTS_SUCCESS:
    case CREATE_EQUIPMENT_SUCCESS:
    case UPDATE_EQUIPMENT_SUCCESS:
    case DELETE_EQUIPMENT_SUCCESS:
    case CALCULATE_EQUIPMENT_CELL_BLOCK_MAPPINGS_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_EQUIPMENTS_REQUEST:
    case CREATE_EQUIPMENT_REQUEST:
    case UPDATE_EQUIPMENT_REQUEST:
    case DELETE_EQUIPMENT_REQUEST:
    case CALCULATE_EQUIPMENT_CELL_BLOCK_MAPPINGS_REQUEST:
      return true;

    case FETCH_EQUIPMENTS_FAILURE:
    case CREATE_EQUIPMENT_FAILURE:
    case UPDATE_EQUIPMENT_FAILURE:
    case DELETE_EQUIPMENT_FAILURE:
    case CALCULATE_EQUIPMENT_CELL_BLOCK_MAPPINGS_FAILURE:
    case FETCH_EQUIPMENTS_SUCCESS:
    case CREATE_EQUIPMENT_SUCCESS:
    case UPDATE_EQUIPMENT_SUCCESS:
    case DELETE_EQUIPMENT_SUCCESS:
    case CALCULATE_EQUIPMENT_CELL_BLOCK_MAPPINGS_SUCCESS:
      return false;

    default:
      return state;
  }
};

const all = (state: Equipment[] = [], action: any): Equipment[] => {
  switch (action.type) {
    case FETCH_EQUIPMENTS_SUCCESS: {
      const response = action.response as ListEquipmentsResponse;
      return [...(response.equipments ?? [])];
    }
    case CREATE_EQUIPMENT_SUCCESS: {
      const response = action.response as CreateEquipmentResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case UPDATE_EQUIPMENT_SUCCESS: {
      const response = action.response as UpdateEquipmentResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case DELETE_EQUIPMENT_SUCCESS: {
      return state.filter((i) => i.id !== action.id);
    }

    case CLEAR_EQUIPMENTS_REQUEST: {
      return [];
    }
    default:
      return state;
  }
};

const lastCalculatedEquipment = (
  state: Equipment | null = null,
  action: any
): Equipment | null => {
  switch (action.type) {
    case CALCULATE_EQUIPMENT_CELL_BLOCK_MAPPINGS_SUCCESS: {
      const response =
        action.response as CalculateEquipmentCellBlockMappingsResponse;
      return response;
    }
    case CLEAR_EQUIPMENT_CELL_BLOCK_MAPPINGS_REQUEST: {
      return null;
    }
    default:
      return state;
  }
};

export const equipments = combineReducers({
  error,
  loading,
  all,
  lastCalculatedEquipment,
});
