import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { ToggleableDndList } from 'client/components/ToggleableDndList/ToggleableDndList';
import { setSortSubEquipments } from 'client/actions/seatAssignmentControls';
import { equipmentsSelector } from 'client/reducers/equipments';
import type { ReduxState } from 'client/reducers';
import { Modal } from 'client/components/Modal/Modal';
import { EquipmentInstance } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';
import pageStyles from 'client/pages/pages.module.css';

type Props = {
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  equipmentInstance: EquipmentInstance | undefined;
};

export const SubEquipmentSortModal = ({
  onClose,
  equipmentInstance,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const sortedSubEquipments = useSelector(
    (state: ReduxState) => state.seatAssignmentControls.sortedSubEquipments
  );
  const equipments = useSelector(equipmentsSelector);

  if ((equipments || []).length === 0) {
    return null;
  }

  return (
    <Modal
      title={t('Show columns')}
      open={true}
      onClose={() => onClose(false)}
      insertRoot={true}
    >
      <div className={clsx(pageStyles['page-reservations__modal'])}>
        <div className={clsx(pageStyles['page-reservations__modal__box'])}>
          <div className={clsx(baseStyles['base-form-box'])}>
            <div className={clsx(baseStyles['base-form-box__body'])}>
              <ToggleableDndList
                selectedItems={sortedSubEquipments
                  .filter((id) => id != equipmentInstance?.equipment_id)
                  .map((e) => {
                    const target = equipments.find((e2) => e === e2.id);
                    return {
                      key: target?.id || '',
                      text: target?.title || '',
                    };
                  })}
                candidateItems={equipments
                  .filter(
                    (option) => option?.id != equipmentInstance?.equipment_id
                  )
                  .map((e) => ({
                    key: e.id || '',
                    text: e.title || '',
                  }))}
                setSelectedItems={(items) =>
                  dispatch(setSortSubEquipments(items.map((i) => i.key)))
                }
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
