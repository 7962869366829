import * as React from 'react';
import { useSelector } from 'react-redux';

import { WaitTime } from 'shared/models/swagger';
import { ReduxState } from 'client/reducers';

import LiveInfo from './LiveInfo';
import Chart from './Chart';
import styles from './PopularTimes.module.css';

const PopularTimes = ({ waitTime }: { waitTime: WaitTime }) => {
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        {locale === 'ja' ? (
          <p className={styles.subtitle}>
            通常、ここで<span className={styles.bold}>1～1.5時間</span>
            過ごします。
          </p>
        ) : (
          <p className={styles.subtitle}>
            People usually spend{' '}
            <span className={styles.bold}>1-1.5 hours</span> here.
          </p>
        )}
      </header>
      <div className={styles.timeChart}>
        <Chart />
        <LiveInfo waitTime={waitTime} />
      </div>
    </div>
  );
};

export default PopularTimes;
