import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import {
  PointerSensor,
  useSensor,
  DndContext,
  useSensors,
  KeyboardSensor,
  closestCenter,
  DragEndEvent,
} from '@dnd-kit/core';
import { useForm } from 'react-final-form';

import { FieldWrapper } from 'client/components/Form/FieldWrapper';
import { Box } from 'client/components/Box/Box';
import { Button } from 'client/components/v3/Common/Button';

import { CustomMenuItem, FormValues } from './formValues';
import { SortableMenuItem } from './SortableMenuItem';
import { MenuItemEditModal } from './MenuItemEditModal';
import styles from './MenuItemsEditor.module.css';

export const MenuItemsEditor = () => {
  const { t } = useTranslation();
  const form = useForm<FormValues>();
  const { values } = form.getState();
  const [editingMenuItemIdx, setEditingMenuItemIdx] = React.useState<
    number | null
  >(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8, // 8px movement required before drag starts
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleEditItem = (menuItem: CustomMenuItem) => {
    const idx = values.customMenuItems.findIndex(
      (item) => item.id === menuItem.id
    );
    setEditingMenuItemIdx(idx);
  };

  return (
    <>
      <Box mt={2} mb={2}>
        <FieldWrapper label={t('Menu Items')} />
        <Box display="flex" flexDirection="column" gap={2}>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={({ active, over }: DragEndEvent) => {
              if (over && active.id !== over.id) {
                const oldIndex = values.customMenuItems.findIndex(
                  (item) => item.id === active.id
                );
                const newIndex = values.customMenuItems.findIndex(
                  (item) => item.id === over.id
                );

                if (oldIndex !== -1 && newIndex !== -1) {
                  const newMenuItems = arrayMove(
                    values.customMenuItems,
                    oldIndex,
                    newIndex
                  );
                  form.change('customMenuItems', newMenuItems);
                }
              }
            }}
          >
            <SortableContext
              items={values.customMenuItems}
              strategy={verticalListSortingStrategy}
            >
              {values.customMenuItems.map((menuItem) => (
                <div key={menuItem.id} className={styles.menuItem}>
                  <SortableMenuItem
                    key={menuItem.id}
                    menuItem={menuItem}
                    onDelete={() => {
                      const newMenuItems = values.customMenuItems.filter(
                        (item) => item.id !== menuItem.id
                      );
                      form.change('customMenuItems', newMenuItems);
                    }}
                    onEdit={() => handleEditItem(menuItem)}
                  />
                </div>
              ))}
            </SortableContext>
          </DndContext>
        </Box>
        <Box mt={2}>
          <Button
            type="button"
            color="secondary"
            text={t('Add Menu Item')}
            onClick={() => {
              form.change('customMenuItems', [
                ...values.customMenuItems,
                {
                  id: uuidv4(),
                  label: '',
                  url: '',
                  itemType: 'CUSTOM_LINK',
                  subMenuItems: [],
                },
              ]);

              setEditingMenuItemIdx(values.customMenuItems.length);
            }}
            iconBeforeText={
              <i className="c-icon-outline-general-plus-circle" />
            }
          />
        </Box>
      </Box>
      {editingMenuItemIdx !== null && (
        <MenuItemEditModal
          menuItem={values.customMenuItems[editingMenuItemIdx]}
          onSave={(menuItem) => {
            const newMenuItems = [...values.customMenuItems];
            newMenuItems[editingMenuItemIdx] = menuItem;
            form.change('customMenuItems', newMenuItems);
            setEditingMenuItemIdx(null);
          }}
        />
      )}
    </>
  );
};
