import {
  SET_SEAT_ASSIGNMENT_DATE,
  SET_SEAT_ASSIGNMENT_PRODUCT_ID,
  SET_SEAT_ASSIGNMENT_PRODUCT_INSTANCE_ID,
  SET_SEAT_ASSIGNMENT_EQUIPMENT_ID,
  SET_SEAT_ASSIGNMENT_START_TIME_KEY,
  SET_SORT_EQUIPMENTS,
  SET_SORT_SUB_EQUIPMENTS,
  SET_CANDIDATE_EQUIPMENTS,
  SET_CANDIDATE_SUB_EQUIPMENTS,
} from 'client/constants/ActionTypes';

export const setSeatAssignmentDate = (date: string) => ({
  type: SET_SEAT_ASSIGNMENT_DATE,
  date,
});

export const setSeatAssignmentProductId = (productId: string) => ({
  type: SET_SEAT_ASSIGNMENT_PRODUCT_ID,
  productId,
});

export const setSeatAssignmentProductInstanceId = (
  productInstanceId: string
) => ({
  type: SET_SEAT_ASSIGNMENT_PRODUCT_INSTANCE_ID,
  productInstanceId,
});

export const setSeatAssignmentEquipmentId = (equipmentId: string) => ({
  type: SET_SEAT_ASSIGNMENT_EQUIPMENT_ID,
  equipmentId,
});

export const setSeatAssignmentStartTimeKey = (startTimeKey: string) => ({
  type: SET_SEAT_ASSIGNMENT_START_TIME_KEY,
  startTimeKey,
});

export const setSortEquipments = (equipments: string[]) => ({
  type: SET_SORT_EQUIPMENTS,
  equipments,
});

export const setSortSubEquipments = (equipments: string[]) => ({
  type: SET_SORT_SUB_EQUIPMENTS,
  equipments,
});

export const setCandidateEquipments = (
  equipments: {
    value: string;
    text: string;
  }[]
) => ({
  type: SET_CANDIDATE_EQUIPMENTS,
  equipments,
});

export const setCandidateSubEquipments = (
  equipments: {
    value: string;
    text: string;
  }[]
) => ({
  type: SET_CANDIDATE_SUB_EQUIPMENTS,
  equipments,
});
