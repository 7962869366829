import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { ToggleableDndList } from 'client/components/ToggleableDndList/ToggleableDndList';
import { setSortEquipments } from 'client/actions/seatAssignmentControls';
import type { ReduxState } from 'client/reducers';
import { Modal } from 'client/components/Modal/Modal';
import baseStyles from 'client/base.module.css';
import pageStyles from 'client/pages/pages.module.css';
import { equipmentsSelector } from 'client/reducers/equipments';

type Props = {
  trigger: React.ReactElement<any>;
};

export const EquipmentSortModal = ({ trigger }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const sortedEquipments = useSelector(
    (state: ReduxState) => state.seatAssignmentControls.sortedEquipments
  );

  const equipments = useSelector(equipmentsSelector);

  const [showModal, setShowModal] = React.useState<boolean>(false);

  if ((equipments || []).length === 0) {
    return null;
  }

  return (
    <Modal
      title={t('Show columns')}
      trigger={trigger}
      open={showModal}
      onOpen={() => setShowModal(true)}
      onClose={() => setShowModal(false)}
    >
      <div className={clsx(pageStyles['page-reservations__modal'])}>
        <div className={clsx(pageStyles['page-reservations__modal__box'])}>
          <div className={clsx(baseStyles['base-form-box'])}>
            <div className={clsx(baseStyles['base-form-box__body'])}>
              <ToggleableDndList
                selectedItems={sortedEquipments.map((e) => {
                  const target = equipments.find((e2) => e === e2.id);
                  if (!target) {
                    alert('hoge');
                  }
                  return {
                    key: target?.id || 'aa',
                    text: target?.title || 'hoge 1',
                  };
                })}
                candidateItems={equipments.map((e) => ({
                  key: e.id || '',
                  text: e.title || 'hoge hoge',
                }))}
                setSelectedItems={(items) =>
                  dispatch(setSortEquipments(items.map((i) => i.key)))
                }
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
