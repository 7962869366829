import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { InstantWin } from 'shared/models/swagger';
import { getInstantWinStats } from 'client/actions/instantWins';
import type { ReduxState } from 'client/reducers';

import { InstantWinEventListModal } from './InstantWinEventListModal';

interface Props {
  instantWin: InstantWin;
  activeTabIndex: number | null;
}

export const TiersStats = ({ instantWin, activeTabIndex }: Props) => {
  const dispatch = useDispatch();
  const [tierKey, setTierKey] = React.useState<string>('');

  const instantWinAllStats = useSelector(
    (state: ReduxState) => state.instantWins.allStats
  );

  const instantWinStats = instantWinAllStats[instantWin.id ?? ''] || null;

  React.useEffect(() => {
    if (!instantWin) {
      return;
    }
    dispatch(getInstantWinStats(instantWin.id ?? ''));
  }, [activeTabIndex, instantWin]);

  if (!instantWinStats) {
    return null;
  }

  return (
    <>
      {(instantWin?.tiers || []).map((tier) => {
        const tierStats = (instantWinStats?.tiers || []).find(
          (t) => t.tier_key === tier.key
        );
        return (
          <div key={tier.key}>
            <a onClick={() => setTierKey(tier.key ?? '')}>
              {tier.title}:{tierStats?.drawn || '0'} /{' '}
              {tierStats?.pending || '0'} / {tierStats?.total || '0'}
            </a>
          </div>
        );
      })}
      {tierKey !== '' && (
        <InstantWinEventListModal
          instantWin={instantWin}
          tierKey={tierKey}
          open={tierKey !== ''}
          onClose={() => setTierKey('')}
        />
      )}
    </>
  );
};
