import * as React from 'react';
import { Field, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { Box } from 'client/components/Box/Box';
import { Button, FieldWrapper } from 'client/components/Form';
import { EditDeleteButtonDndList } from 'client/components/EditDeleteButtonDndList/EditDeleteButtonDndList';
import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';

import { EditInstantWinTierModal } from './EditInstantWinTierModal';
import { InstantWinTierFormValue } from './formValues';

export const InstantWinTierListEditor = () => {
  const { t } = useTranslation();

  const [deletingIdx, setDeletingIdx] = React.useState<number | null>(null);
  const [editingIdx, setEditingIdx] = React.useState<number | null>(null);

  const getTitle = (tier: InstantWinTierFormValue) => {
    return tier.title ?? '';
  };

  const formState = useFormState();

  return (
    <>
      <Box mt={2}>
        <FieldWrapper label={t('Tier')}>
          <Field name="tiers">
            {({ input }) => (
              <>
                <Button
                  size="middle"
                  style="green"
                  onClick={() => setEditingIdx(input.value.length)}
                >
                  {t('Create New Instant Win Tier')}
                </Button>
                <EditDeleteButtonDndList
                  items={input.value?.map((tier: InstantWinTierFormValue) => ({
                    key: tier.key,
                    text: getTitle(tier),
                  }))}
                  setItems={(tier) => {
                    input.onChange(
                      tier.map((tier) =>
                        input.value?.find(
                          (trigger: InstantWinTierFormValue) =>
                            trigger.key === tier.key
                        )
                      )
                    );
                  }}
                  onRemove={(idx: number) => {
                    setDeletingIdx(idx);
                  }}
                  onEdit={(idx: number) => {
                    setEditingIdx(idx);
                  }}
                />

                {deletingIdx != null && (
                  <DeleteConfirmModal
                    header={t('Delete tier')}
                    content={t('Are you sure you want to delete tier')}
                    onConfirm={async () => {
                      input.onChange(
                        input.value?.filter(
                          (_: any, idx: number) => idx !== deletingIdx
                        )
                      );
                    }}
                    onClose={() => setDeletingIdx(null)}
                    open={deletingIdx != null}
                    insertRoot
                  />
                )}
                {editingIdx != null && (
                  <EditInstantWinTierModal
                    existingInstantWinTier={
                      editingIdx >= (input.value ?? []).length
                        ? undefined
                        : input.value[editingIdx]
                    }
                    onSave={(tier: InstantWinTierFormValue) => {
                      if (editingIdx >= (input.value ?? []).length) {
                        input.onChange([...input.value, tier]);
                      } else {
                        input.onChange(
                          input.value.map(
                            (existingInstantWinTier: any, idx: number) =>
                              idx === editingIdx ? tier : existingInstantWinTier
                          )
                        );
                      }
                    }}
                    open={editingIdx != null}
                    onClose={() => setEditingIdx(null)}
                    distributionType={formState.values.distributionType}
                  />
                )}
              </>
            )}
          </Field>
        </FieldWrapper>
      </Box>
    </>
  );
};
