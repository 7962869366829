import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useWindowDimensions } from 'client/hooks/useWindowDimensions';
import { Pagination } from 'client/components/v3/Pagination/Pagination';
import { Loading } from 'client/components/v3/Common/Loading';
import defaultColumnSortIcon from 'client/images/ui-common/ic-table-sort-default.svg';
import ascColumnSortIcon from 'client/images/ui-common/ic-table-sort-asc.svg';
import descColumnSortIcon from 'client/images/ui-common/ic-table-sort-desc.svg';

import styles from './GenericTable.module.css';

type ColumnSortState = 'ASC' | 'DESC' | 'DEFAULT';
export interface ColumnType<T> {
  Header: string;
  id: string;
  accessor: (arg: T) => any;
  th?: boolean;
  sub?: boolean;
  width?: number;
  icon?: string;
  loading?: boolean;
  headerSubtext?: string;
  // Use onColumnSort to pass the function used to sort the column, and sortOrder to denote the order (both are needed)
  onColumnSort?: (...args: any[]) => any;
  sortOrder?: ColumnSortState;
  noOverflow?: boolean; // Make overflow:visible & scrollable for the column
}

interface Props<T> {
  items: T[];
  columns: ColumnType<T>[];
  rowCount: number;
  currentPage: number;
  onRowCountChange: (rowCount: number) => void;
  onCurrentPageChange: (page: number) => void;
  totalCount: number;
  hideScrollButtons?: boolean;
  // When using hidePagination, rowCount & onRowCountChange will not be used
  // So make sure to set rowCount to number of items you wish to display without pagination
  hidePagination?: boolean;
  headerBar?: React.ReactNode;
  loading?: boolean;
}

export const GenericTable = <T,>({
  items,
  columns,
  rowCount,
  currentPage,
  onRowCountChange,
  onCurrentPageChange,
  totalCount,
  hideScrollButtons,
  hidePagination,
  headerBar,
  loading,
}: Props<T>) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const tableHeaderRef = React.useRef<HTMLDivElement | null>(null);
  const tableBodyRef = React.useRef<HTMLDivElement | null>(null);
  const [sortOrders, setSortOrders] = React.useState<{
    [key: string]: ColumnSortState;
  }>({});

  const isMobileView = width < 786;

  const pageCount =
    Math.floor(totalCount / rowCount) + (totalCount % rowCount ? 1 : 0);

  const scrollHandler = () => {
    if (
      tableHeaderRef?.current?.scrollLeft != null &&
      tableBodyRef?.current?.scrollLeft != null
    ) {
      tableHeaderRef.current.scrollLeft = tableBodyRef.current.scrollLeft;
    }
  };

  const scrollButtonClickHandler = (direction: any) => {
    if (
      tableHeaderRef?.current?.scrollLeft != null &&
      tableBodyRef?.current?.scrollLeft != null
    ) {
      if (direction === 'next') {
        tableHeaderRef.current.scrollLeft =
          tableBodyRef.current.scrollLeft += 100;
      } else {
        tableHeaderRef.current.scrollLeft =
          tableBodyRef.current.scrollLeft -= 100;
      }
    }
  };

  const pageForwardClickHandler = () => {
    if (currentPage < pageCount) {
      onCurrentPageChange(currentPage + 1);
    }
  };

  const pageBackClickHandler = () => {
    if (1 < currentPage) {
      onCurrentPageChange(currentPage - 1);
    }
  };

  if (loading) {
    return (
      <section className={styles['g-section']}>
        <div className={styles['p-reservations']}>
          <div className={styles['p-reservations__header']}>{headerBar}</div>
          <div className={styles['c-loading']}>
            <Loading size="md" />
          </div>
        </div>
      </section>
    );
  }

  if (!totalCount) {
    return (
      <section className={styles['g-section']}>
        <div className={styles['p-reservations']}>
          <div className={styles['p-reservations__header']}>{headerBar}</div>
          <div className={styles['p-reservations__body']}>
            <p className={styles['p-reservations__empty']}>
              {t(
                'There are no items that match the specified search conditions'
              )}
            </p>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className={styles['g-section']}>
      <div className={clsx(styles['p-reservations'])}>
        {headerBar && (
          <div className={styles['p-reservations__header']}>{headerBar}</div>
        )}
        <div className={styles['p-reservations__body']}>
          <div className={clsx(styles['c-tableFrame'], styles['arrow'])}>
            {/* Table header */}
            <div
              className={clsx(
                styles['c-table'],
                styles['thead'],
                styles['sticky-top']
              )}
              style={{
                borderTopLeftRadius: headerBar ? '0' : '16px',
                borderTopRightRadius: headerBar ? '0' : '16px',
              }}
              ref={tableHeaderRef}
            >
              <table className={styles['c-table__body']}>
                <thead>
                  <tr>
                    {columns.map((column: ColumnType<T>, idx) => {
                      const width = column.width || '';
                      const sortOrder = sortOrders[column.id] || 'DEFAULT';
                      return (
                        <th
                          className={clsx(
                            styles['thead__th'],
                            hideScrollButtons && styles['c-noScrollButtons'],
                            column.onColumnSort && styles['clickable']
                          )}
                          style={
                            isMobileView || !width
                              ? {
                                  borderTop: headerBar ? undefined : 'none',
                                }
                              : {
                                  width: `${width}px`,
                                  maxWidth: 'max-content',
                                  borderTop: headerBar ? undefined : 'none',
                                }
                          }
                          key={idx}
                          onClick={() => {
                            let newSortOrder: ColumnSortState;

                            if (sortOrder === 'ASC') {
                              newSortOrder = 'DESC';
                            } else {
                              newSortOrder = 'ASC';
                            }

                            setSortOrders((prev) => ({
                              ...prev,
                              [column.id]: newSortOrder as ColumnSortState,
                            }));
                            column.onColumnSort?.(newSortOrder);
                          }}
                        >
                          <div>
                            <div>
                              <p
                                className={
                                  column.onColumnSort && sortOrder !== 'DEFAULT'
                                    ? styles['sorted']
                                    : undefined
                                }
                              >
                                {column.Header}
                              </p>
                              {column.headerSubtext ? (
                                <p className={styles['thead_subtext']}>
                                  {column.headerSubtext}
                                </p>
                              ) : null}
                            </div>
                            {column.onColumnSort ? (
                              <div>
                                {sortOrder === 'DEFAULT' ? (
                                  <img src={defaultColumnSortIcon} />
                                ) : sortOrder === 'ASC' ? (
                                  <img src={ascColumnSortIcon} />
                                ) : (
                                  <img src={descColumnSortIcon} />
                                )}
                              </div>
                            ) : null}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
              </table>
            </div>

            {/* Table body */}
            <div
              className={clsx(styles['c-table'], styles['tbody'])}
              onScroll={scrollHandler}
              ref={tableBodyRef}
              style={{
                borderBottomLeftRadius: headerBar ? '0' : '16px',
                borderBottomRightRadius: headerBar ? '0' : '16px',
              }}
            >
              <table className={styles['c-table__body']}>
                <tbody>
                  {items.map((item, ridx) => {
                    return (
                      <tr key={ridx}>
                        {columns.map((column, idx) => {
                          const width = column.width || '';
                          const displayValue = column.accessor(item);

                          if (column.th) {
                            return (
                              <th
                                key={idx}
                                style={
                                  isMobileView
                                    ? {}
                                    : {
                                        width: `${width}px`,
                                        maxWidth: 'max-content',
                                      }
                                }
                                className={clsx(
                                  styles['tbody__td'],
                                  styles['sticky-left'],
                                  styles['c-table__spHeader'],
                                  hideScrollButtons &&
                                    styles['c-noScrollButtons']
                                )}
                              >
                                {displayValue}
                              </th>
                            );
                          } else {
                            return (
                              <td
                                key={idx}
                                style={
                                  isMobileView
                                    ? {}
                                    : {
                                        width: `${width}px`,
                                        maxWidth: 'max-content',
                                      }
                                }
                                className={clsx(
                                  styles['tbody__td'],
                                  hideScrollButtons &&
                                    styles['c-noScrollButtons'],
                                  column.noOverflow && styles['c-noOverflow']
                                )}
                                data-text={column.Header}
                              >
                                {displayValue}
                              </td>
                            );
                          }
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            {!hideScrollButtons && (
              <>
                <div
                  className={clsx(styles['c-tableBtn'], styles['left'])}
                  onClick={() => {
                    scrollButtonClickHandler('prev');
                  }}
                >
                  <a>
                    <i className="c-icon-solid-arrows-chevron-left"></i>
                  </a>
                </div>
                <div
                  className={clsx(styles['c-tableBtn'], styles['right'])}
                  onClick={() => {
                    scrollButtonClickHandler('next');
                  }}
                >
                  <a>
                    <i className="c-icon-solid-arrows-chevron-left"></i>
                  </a>
                </div>
              </>
            )}
          </div>
        </div>

        {!hidePagination && (
          <div className={styles['p-reservations__bottom']}>
            <Pagination
              totalItems={totalCount}
              currentPage={currentPage}
              pageCount={pageCount}
              selectedNumberOfLinesToDisplay={rowCount}
              onChangeNumberOfLinesToDisplay={onRowCountChange}
              onClickPrevButton={pageBackClickHandler}
              onClickNextButton={pageForwardClickHandler}
            />
          </div>
        )}
      </div>
    </section>
  );
};
