import moment from 'moment-timezone';
import { combineReducers } from 'redux';

import {
  LOGOUT_SUCCESS,
  SET_IMPERSONATED_USER_ID,
  SET_SEAT_ASSIGNMENT_DATE,
  SET_SEAT_ASSIGNMENT_PRODUCT_ID,
  SET_SEAT_ASSIGNMENT_PRODUCT_INSTANCE_ID,
  SET_SEAT_ASSIGNMENT_EQUIPMENT_ID,
  SET_SEAT_ASSIGNMENT_START_TIME_KEY,
  SET_SORT_EQUIPMENTS,
  SET_SORT_SUB_EQUIPMENTS,
  SET_CANDIDATE_EQUIPMENTS,
  SET_CANDIDATE_SUB_EQUIPMENTS,
} from 'client/constants/ActionTypes';

const date = (state = moment().format('YYYY-MM-DD'), action: any) => {
  switch (action.type) {
    case SET_SEAT_ASSIGNMENT_DATE:
      return action.date;
    default:
      return state;
  }
};

const productId = (state = '', action: any) => {
  switch (action.type) {
    case SET_SEAT_ASSIGNMENT_PRODUCT_ID:
      return action.productId;
    default:
      return state;
  }
};

const productInstanceId = (state = '', action: any) => {
  switch (action.type) {
    case SET_SEAT_ASSIGNMENT_PRODUCT_INSTANCE_ID:
      return action.productInstanceId;
    default:
      return state;
  }
};

const equipmentId = (state = '', action: any) => {
  switch (action.type) {
    case SET_SEAT_ASSIGNMENT_EQUIPMENT_ID:
      return action.equipmentId;
    default:
      return state;
  }
};

const startTimeKey = (state = '', action: any) => {
  switch (action.type) {
    case SET_SEAT_ASSIGNMENT_START_TIME_KEY:
      return action.startTimeKey;
    default:
      return state;
  }
};

type options = {
  value: string;
  text: string;
}[];

const sortedEquipments = (state: string[] = [], action: any): string[] => {
  switch (action.type) {
    case SET_SORT_EQUIPMENTS: {
      const equipments = action.equipments as string[];
      return equipments;
    }
    default:
      return state;
  }
};
const sortedSubEquipments = (state: string[] = [], action: any): string[] => {
  switch (action.type) {
    case SET_SORT_SUB_EQUIPMENTS: {
      const equipments = action.equipments as string[];
      return equipments;
    }
    default:
      return state;
  }
};
const candidateEquipments = (state: options = [], action: any): options => {
  switch (action.type) {
    case SET_CANDIDATE_EQUIPMENTS: {
      const equipments = action.equipments as options;
      return equipments;
    }
    default:
      return state;
  }
};
const candidateSubEquipments = (state: options = [], action: any): options => {
  switch (action.type) {
    case SET_CANDIDATE_SUB_EQUIPMENTS: {
      const equipments = action.equipments as options;
      return equipments;
    }
    default:
      return state;
  }
};

type State = {
  date: string;
  productId: string;
  productInstanceId: string;
  equipmentId: string;
  startTimeKey: string;
  sortedEquipments: string[];
  sortedSubEquipments: string[];
  candidateEquipments: options;
  candidateSubEquipments: options;
};

const reducer = combineReducers<State>({
  date,
  productId,
  productInstanceId,
  equipmentId,
  startTimeKey,
  sortedEquipments,
  sortedSubEquipments,
  candidateEquipments,
  candidateSubEquipments,
});

export const seatAssignmentControls = (state: State, action: any) => {
  if (
    action.type === SET_IMPERSONATED_USER_ID ||
    action.type === LOGOUT_SUCCESS
  ) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
