import {
  operationAllowed,
  availabilityTableEnabledForAccount,
  dashEnabledForAccount,
  homeEnabledForAccount,
  manifestEnabledForAccount,
  promotionsEnabledForAccount,
  commissionsTableEnabledForAccount,
  waiverEnabledForAccount,
  crmEnabledForAccount,
  bookingWidgetSettingsViewEnabledForAccount,
  newReservationButtonEnabledForAccount,
} from 'shared/models/access';
import type { TranslateFuncType } from 'client/components/Translate';
import {
  hasSubscription,
  isSubscriptionCancelled,
} from 'client/libraries/util/subscriptions';
import {
  hasCustomUserRoleReadPermissions,
  hasCustomUserRoleWritePermissions,
} from 'client/libraries/util/customUserPermissions';
import { config } from 'client/config';
import type { Account, Organization } from 'shared/models/swagger';
import {
  isPartnershipAgent,
  isPartnershipSupplier,
} from 'client/libraries/util/partnership';
import { isOnSiteJourneyAnalyticsSupplier } from 'client/libraries/util/onSiteJourneyAnalytics';

import billingNavIcon from './images/ic_billing_white.svg';
import manifestNavIcon from './images/ic_manifests_white.svg';
import homeNavIcon from './images/ic_home_white.svg';
import productNavIcon from './images/ic_products_white.svg';
import reservationNavIcon from './images/ic_reservations_white.svg';
import manifestNavIconMobile from './images/ic_manifests_gray.svg';
import homeNavIconMobile from './images/ic_home_gray.svg';
import productNavIconMobile from './images/ic_products_gray.svg';
import reservationNavIconMobile from './images/ic_reservations_gray.svg';
import billingHeaderIcon from './images/ic_billing_black.svg';
import manifestHeaderIcon from './images/ic_manifests_black.svg';
import newsletterHeaderIcon from './images/ic_newsletter_black.svg';
import homeHeaderIcon from './images/ic_home_black.svg';
import productHeaderIcon from './images/ic_products_black.svg';
import reservationHeaderIcon from './images/ic_reservations_black.svg';
import tutorialHeaderIcon from './images/ic_tutorial_black.svg';
import newReservationNavIconMobile from './images/ic_new_reservation_green.svg';
import reviewsIcon from './images/ic_reviews.svg';
import bookingWidgetIcon from './images/ic_bookingwidget.svg';
import waiverIcon from './images/ic_waiver.svg';
import shiftManagementIcon from './images/ic_shift_management.svg';
import mediaDownloadNavIcon from './images/ic_image_download.svg';
import availabilityNavIcon from './images/ic_availability_white.svg';
import availabilityNavIconMobile from './images/ic_availability_gray.svg';
import availabilityHeaderIcon from './images/ic_availability_black.svg';
import guidanceNavIcon from './images/ic_guidance.svg';
import inquiryNavIcon from './images/ic_inquiry.svg';
import eTicketNavIcon from './images/ic_ticket.svg';
import surveyIcon from './images/ic_survey.svg';
import accountIcon from './images/ic_account.svg';
import newsletterIcon from './images/ic_newsletter_white.svg';
import customerIcon from './images/ic_customer_white.svg';
import seatIcon from './images/ic_seat.svg';
import channelManagerIcon from './images/ic_channelmanager.svg';
import marketingAutomationIcon from './images/ic_marketing_automation.svg';
import journeyAnalyticsIcon from './images/ic_journey_analytics.svg';
import mapIcon from './images/ic_map.svg';
import siteHackerIcon from './images/ic_site_hacker.svg';

type TopLevelPageCategory =
  | 'HOME'
  | 'RESERVATION'
  | 'MANIFEST'
  | 'DISPATCH'
  | 'PRODUCT'
  | 'AVAILABILITY'
  | 'BILLING'
  | 'REVIEW'
  | 'NEW_RESERVATIONS'
  | 'MANAGE'
  | 'SETTINGS'
  | 'BOOKING_WIDGET_SETTINGS'
  | 'PMP_SETTINGS'
  | 'HP_SETTINGS'
  | 'WAIVER'
  | 'GUIDE_ACCOUNT'
  | 'SCHEDULE'
  | 'MEDIA_DOWNLOAD'
  | 'DIGITAL_GUIDANCE'
  | 'CHECKIN_RECORD'
  | 'INQUIRY'
  | 'SYSTEM_FEE'
  | 'SURVEY'
  | 'CUSTOMER_LEDGER'
  | 'NEWSLETTER'
  | 'ACCOUNTS'
  | 'SEAT_MANAGEMENT'
  | 'CROSS_SELL_UP_SELL'
  | 'INSTANT_WIN'
  | 'CHANNEL_MANAGER'
  | 'NOTIFICATIONS'
  | 'ANNUAL_PASS'
  | 'GROUP_BOOKING'
  | 'CUSTOM_MAP'
  | 'JOURNEY_ANALYTICS'
  | 'MARKETING_AUTOMATION'
  | 'PARTNERSHIP'
  | 'MOBILE_ORDER'
  | 'SITE_CONTROL_WIDGET'
  | 'ON_SITE_JOURNEY_ANALYTICS';

type PageCategory = {
  category: TopLevelPageCategory;
  title: string;
  basePath: string;
  navIcon: string;
  navIconMobile: string;
};
type Route = {
  route: string;
  title: string;
  navigationTitle?: string;
  category: TopLevelPageCategory | null;
  headerIcon?: string;
};
export const getRoutes = (
  t: TranslateFuncType,
  activeUser: Account | null,
  activeOrganization: Organization | null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  loggedInAccountIsNutmegAdmin: boolean
): Route[] => {
  const routes: Route[] = [
    {
      route: '/settings',
      title: t('Settings'),
      navigationTitle: t('Settings'),
      category: 'SETTINGS',
    },
  ];
  if (config.enableUIRevamp) {
    routes.push({
      route: '/settings-v3',
      title: t('Settings'),
      navigationTitle: `${t('Settings')} V3`,
      category: 'SETTINGS',
    });
  }

  if (operationAllowed(activeUser, 'write', 'productContents')) {
    if (hasCustomUserRoleWritePermissions(activeUser, 'BOOKING_SITE')) {
      routes.push(
        ...([
          {
            route: '/pmp/general',
            title: t('Private Marketplace - General'),
            navigationTitle: t('General'),
            category: 'PMP_SETTINGS',
          },
          {
            route: '/pmp/ranking',
            title: t('Private Marketplace - Ranking'),
            navigationTitle: t('Ranking'),
            category: 'PMP_SETTINGS',
          },
          {
            route: '/pmp/recommended',
            title: t('Private Marketplace - Recommended'),
            navigationTitle: t('Recommended'),
            category: 'PMP_SETTINGS',
          },
          {
            route: '/pmp/categories',
            title: t('Private Marketplace - Categories'),
            navigationTitle: t('Categories'),
            category: 'PMP_SETTINGS',
          },
          {
            route: '/pmp/featured',
            title: t('Private Marketplace - Featured'),
            navigationTitle: t('Featured'),
            category: 'PMP_SETTINGS',
          },
          {
            route: '/pmp/ma',
            title: t('Private Marketplace - Marketing Automation'),
            navigationTitle: t('MA'),
            category: 'PMP_SETTINGS',
          },
        ] as Route[])
      );
    }
    if (
      hasCustomUserRoleReadPermissions(
        activeUser,
        'BOOKING_SITE.ACCESS_REPORTS'
      )
    ) {
      routes.push({
        route: '/bookingWidget/reports',
        title: t('Access Reports'),
        navigationTitle: t('Access Reports'),
        category: 'BOOKING_WIDGET_SETTINGS',
      });
      if (config.enableUIRevamp) {
        routes.push({
          route: '/bookingWidget/reports-v3',
          title: t('Access Reports'),
          navigationTitle: `${t('Access Reports')} V3`,
          category: 'BOOKING_WIDGET_SETTINGS',
        });
      }
    }
    if (
      hasCustomUserRoleWritePermissions(activeUser, 'BOOKING_SITE.SETTINGS')
    ) {
      routes.push({
        route: '/bookingWidget/general',
        title: t('Settings'),
        navigationTitle: t('Settings'),
        category: 'BOOKING_WIDGET_SETTINGS',
      });
      if (config.enableUIRevamp) {
        routes.push({
          route: '/bookingWidget/general-v3',
          title: t('Settings'),
          navigationTitle: `${t('Settings')} V3`,
          category: 'BOOKING_WIDGET_SETTINGS',
        });
      }
    }
    if (
      hasCustomUserRoleWritePermissions(activeUser, 'BOOKING_SITE.CUSTOMIZE')
    ) {
      routes.push({
        route: '/bookingWidget/customize',
        title: t('Customize'),
        navigationTitle: t('Customize'),
        category: 'BOOKING_WIDGET_SETTINGS',
      });
      if (config.enableUIRevamp) {
        routes.push({
          route: '/bookingWidget/customize-v3',
          title: t('Customize'),
          navigationTitle: `${t('Customize')} V3`,
          category: 'BOOKING_WIDGET_SETTINGS',
        });
      }
    }
    if (
      hasCustomUserRoleWritePermissions(activeUser, 'BOOKING_SITE.TOP_PAGE')
    ) {
      routes.push({
        route: '/bookingWidget/topPage',
        title: t('TOP page'),
        navigationTitle: t('TOP page'),
        category: 'BOOKING_WIDGET_SETTINGS',
      });
      if (config.enableUIRevamp) {
        routes.push({
          route: '/bookingWidget/topPage-v3',
          title: t('TOP page'),
          navigationTitle: `${t('TOP page')} V3`,
          category: 'BOOKING_WIDGET_SETTINGS',
        });
      }
    }
    if (
      hasCustomUserRoleWritePermissions(activeUser, 'BOOKING_SITE.TOP_PAGE') &&
      hasSubscription(activeOrganization, 'feature-custom-top-page') &&
      !isSubscriptionCancelled(activeOrganization, 'feature-custom-top-page')
    ) {
      routes.push({
        route: '/bookingWidget/customTopPage',
        title: t('Custom TOP'),
        navigationTitle: t('Custom TOP'),
        category: 'BOOKING_WIDGET_SETTINGS',
      });
    }
    if (
      hasCustomUserRoleWritePermissions(activeUser, 'BOOKING_SITE.HOMEPAGES')
    ) {
      routes.push(
        {
          route: '/bookingWidget/homepages',
          title: t('Homepages'),
          navigationTitle: t('Homepages'),
          category: 'BOOKING_WIDGET_SETTINGS',
        },
        {
          route: '/bookingWidget/homepages/editcustompage',
          title: t('Homepages'),
          category: 'BOOKING_WIDGET_SETTINGS',
        }
      );
      if (config.enableUIRevamp) {
        routes.push(
          {
            route: '/bookingWidget/homepages-v3',
            title: t('Homepages'),
            navigationTitle: `${t('Homepages')} V3`,
            category: 'BOOKING_WIDGET_SETTINGS',
          },
          {
            route: '/bookingWidget/homepages-v3/editcustompage',
            title: t('Homepages'),
            category: 'BOOKING_WIDGET_SETTINGS',
          }
        );
      }
    }
  }

  if (
    hasSubscription(activeOrganization, 'feature-email-customization') &&
    !isSubscriptionCancelled(
      activeOrganization,
      'feature-email-customization'
    ) &&
    hasCustomUserRoleWritePermissions(activeUser, 'BOOKING_SITE.EMAILS')
  ) {
    routes.push({
      route: '/bookingWidget/emails',
      title: t('Emails'),
      navigationTitle: t('Emails'),
      category: 'BOOKING_WIDGET_SETTINGS',
    });
  }

  if (
    hasCustomUserRoleWritePermissions(
      activeUser,
      'BOOKING_SITE.WEBSITEINTEGRATION'
    )
  ) {
    routes.push({
      route: '/bookingWidget/websiteintegration',
      title: t('Website Integration'),
      navigationTitle: t('Website Integration'),
      category: 'BOOKING_WIDGET_SETTINGS',
    });
    if (config.enableUIRevamp) {
      routes.push({
        route: '/bookingWidget/websiteintegration-v3',
        title: t('Website Integration'),
        navigationTitle: `${t('Website Integration')} V3`,
        category: 'BOOKING_WIDGET_SETTINGS',
      });
    }
  }

  if (
    config.enableGoogleMyBusinessFeature &&
    hasCustomUserRoleWritePermissions(
      activeUser,
      'BOOKING_SITE.GOOGLE_MY_BUSINESS'
    )
  ) {
    routes.push({
      route: '/bookingWidget/gmb',
      title: t('Google My Business'),
      navigationTitle: t('Google My Business'),
      category: 'BOOKING_WIDGET_SETTINGS',
    });
  }

  if (homeEnabledForAccount(activeUser)) {
    if (
      hasCustomUserRoleReadPermissions(activeUser, 'HOME') &&
      (config.enableUIRevamp ||
        config.enableUIRevampForDemo ||
        config.enableUIRevampForRelease)
    ) {
      routes.push({
        route: '/home',
        title: t('Home'),
        navigationTitle: t('Home'),
        category: 'HOME',
        headerIcon: homeHeaderIcon,
      });
    }
    routes.push(
      {
        title: t('Tutorial - Product Registration'),
        route: '/home/tutorial/product',
        category: 'HOME',
        headerIcon: tutorialHeaderIcon,
      },
      {
        title: t('Tutorial - Site Settings'),
        route: '/home/tutorial/settings',
        category: 'HOME',
        headerIcon: tutorialHeaderIcon,
      },
      {
        title: t('Tutorial - Create Website'),
        route: '/home/tutorial/sitetemplate',
        category: 'HOME',
        headerIcon: tutorialHeaderIcon,
      }
    );
  }

  if (hasCustomUserRoleReadPermissions(activeUser, 'RESERVATION.LIST')) {
    routes.push({
      route: '/reservations',
      title: t('Reservations'),
      navigationTitle: t('List'),
      category: 'RESERVATION',
      headerIcon: reservationHeaderIcon,
    });
    if (config.enableUIRevamp) {
      routes.push({
        route: '/reservations-v3',
        title: t('Reservations'),
        navigationTitle: `${t('List')} V3`,
        category: 'RESERVATION',
        headerIcon: reservationHeaderIcon,
      });
    }
  }

  if (
    dashEnabledForAccount(activeUser) &&
    hasCustomUserRoleReadPermissions(activeUser, 'RESERVATION.DASHBOARD')
  ) {
    routes.push({
      route: '/reservations/dash',
      title: t('Dash'),
      navigationTitle: t('Dash'),
      category: 'RESERVATION',
      headerIcon: reservationHeaderIcon,
    });
    if (config.enableUIRevamp) {
      routes.push({
        route: '/reservations/dash-v3',
        title: t('Dash'),
        navigationTitle: `${t('Dash')} V3`,
        category: 'RESERVATION',
        headerIcon: reservationHeaderIcon,
      });
    }
  }

  if (
    operationAllowed(activeUser, 'write', 'productContents') &&
    hasCustomUserRoleReadPermissions(activeUser, 'RESERVATION.REPORTS')
  ) {
    routes.push({
      route: '/reservations/reports',
      title: t('Reports'),
      navigationTitle: t('Reports'),
      category: 'RESERVATION',
      headerIcon: reservationHeaderIcon,
    });
    if (config.enableUIRevamp) {
      routes.push({
        route: '/reservations/reports-v3',
        title: t('Reports'),
        navigationTitle: `${t('Reports')} V3`,
        category: 'RESERVATION',
        headerIcon: reservationHeaderIcon,
      });
    }
  }

  if (
    hasCustomUserRoleReadPermissions(activeUser, 'RESERVATION.DATA_DOWNLOAD')
  ) {
    routes.push({
      route: '/reservations/download',
      title: t('Reservations - Data Download'),
      navigationTitle: t('Data Download'),
      category: 'RESERVATION',
      headerIcon: reservationHeaderIcon,
    });
    if (config.enableUIRevamp) {
      routes.push({
        route: '/reservations/download-v3',
        title: t('Reservations - Data Download'),
        navigationTitle: `${t('Data Download')} V3`,
        category: 'RESERVATION',
        headerIcon: reservationHeaderIcon,
      });
    }
  }

  if (
    activeOrganization?.actim_api_key &&
    hasCustomUserRoleReadPermissions(
      activeUser,
      'RESERVATION.ACTIM_RESERVATIONS'
    )
  ) {
    routes.push({
      route: '/reservations/actimReservations',
      title: t('ActimReservations'),
      navigationTitle: t('ActimReservations'),
      category: 'RESERVATION',
      headerIcon: reservationHeaderIcon,
    });
  }

  if (hasCustomUserRoleReadPermissions(activeUser, 'RESERVATION.LIST')) {
    routes.push({
      route: '/reservations/:id',
      title: t('Details'),
      navigationTitle: '',
      category: 'RESERVATION',
      headerIcon: reservationHeaderIcon,
    });
  }

  if (
    manifestEnabledForAccount(activeUser) &&
    hasCustomUserRoleReadPermissions(activeUser, 'MANIFEST.DAILY')
  ) {
    routes.push({
      route: '/manifests',
      title: t('Daily manifest'),
      navigationTitle: t('Daily manifest'),
      category: 'MANIFEST',
      headerIcon: manifestHeaderIcon,
    });
    if (config.enableUIRevamp) {
      routes.push({
        route: '/manifests-v3',
        title: t('Daily manifest'),
        navigationTitle: `${t('Daily manifest')} V3`,
        category: 'MANIFEST',
        headerIcon: manifestHeaderIcon,
      });
    }
  }

  if (manifestEnabledForAccount(activeUser)) {
    if (
      hasCustomUserRoleReadPermissions(
        activeUser,
        'MANIFEST.RESOURCE_ASSIGNMENT'
      )
    ) {
      routes.push({
        route: '/manifests/assignments',
        title: t('Resource Assignments'),
        navigationTitle: t('Resource Assignments'),
        category: 'MANIFEST',
        headerIcon: manifestHeaderIcon,
      });
      if (config.enableUIRevamp) {
        routes.push({
          route: '/manifests/assignments-v3',
          title: t('Resource Assignments'),
          navigationTitle: `${t('Resource Assignments')} V3`,
          category: 'MANIFEST',
          headerIcon: manifestHeaderIcon,
        });
      }
    }
    if (hasCustomUserRoleWritePermissions(activeUser, 'MANIFEST.CUSTOMIZE')) {
      routes.push({
        route: '/manifests/customize',
        title: t('Customize'),
        navigationTitle: t('Customize'),
        category: 'MANIFEST',
        headerIcon: manifestHeaderIcon,
      });
      if (config.enableUIRevamp) {
        routes.push({
          route: '/manifests/customize-v3',
          title: t('Customize'),
          navigationTitle: `${t('Customize')} V3`,
          category: 'MANIFEST',
          headerIcon: manifestHeaderIcon,
        });
      }
    }
    if (
      hasCustomUserRoleWritePermissions(activeUser, 'MANIFEST.EDIT_RESOURCES')
    ) {
      routes.push({
        route: '/manifests/resources',
        title: t('Edit Resources'),
        navigationTitle: t('Edit Resources'),
        category: 'MANIFEST',
        headerIcon: manifestHeaderIcon,
      });
      if (config.enableUIRevamp) {
        routes.push({
          route: '/manifests/resources-v3',
          title: t('Edit Resources'),
          navigationTitle: `${t('Edit Resources')} V3`,
          category: 'MANIFEST',
          headerIcon: manifestHeaderIcon,
        });
      }
    }

    if (
      (hasSubscription(activeOrganization, 'feature-resource-management') ||
        hasSubscription(
          activeOrganization,
          'feature-vehicle-crew-resource-management'
        )) &&
      hasCustomUserRoleReadPermissions(
        activeUser,
        'MANIFEST.RESOURCE_AVAILABILITY'
      )
    ) {
      routes.push({
        route: '/manifests/resourceAvailability',
        title: t('Resource Availability'),
        navigationTitle: t('Resource Availability'),
        category: 'MANIFEST',
        headerIcon: manifestHeaderIcon,
      });
    }
  }

  if (hasCustomUserRoleReadPermissions(activeUser, 'PRODUCT.LIST')) {
    routes.push({
      route: '/products',
      title: t('Products'),
      navigationTitle: t('List'),
      category: 'PRODUCT',
      headerIcon: productHeaderIcon,
    });
    if (config.enableUIRevamp) {
      routes.push({
        route: '/products-v3',
        title: t('Products - List'),
        navigationTitle: `${t('List')} V3`,
        category: 'PRODUCT',
        headerIcon: productHeaderIcon,
      });
    }
  }

  if (
    operationAllowed(activeUser, 'write', 'products') &&
    hasCustomUserRoleWritePermissions(activeUser, 'PRODUCT.LIST')
  ) {
    routes.push({
      route: '/products/new',
      title: t('New Product'),
      category: 'PRODUCT',
      headerIcon: productHeaderIcon,
    });
  }

  if (
    promotionsEnabledForAccount(activeUser) &&
    hasCustomUserRoleWritePermissions(activeUser, 'PRODUCT.PROMOTIONS')
  ) {
    routes.push({
      route: '/products/promotions',
      title: t('Promotions'),
      navigationTitle: t('Promotions'),
      category: 'PRODUCT',
      headerIcon: productHeaderIcon,
    });
    if (config.enableUIRevamp) {
      routes.push({
        route: '/products/promotions-v3',
        title: t('Promotions'),
        navigationTitle: `${t('Promotions')} V3`,
        category: 'PRODUCT',
        headerIcon: productHeaderIcon,
      });
    }
  }

  if (
    !config.enableChannelManager &&
    commissionsTableEnabledForAccount(activeUser) &&
    hasCustomUserRoleWritePermissions(activeUser, 'PRODUCT.COMMISSIONS')
  ) {
    routes.push({
      route: '/products/commissions',
      title: t('Commissions'),
      navigationTitle: t('Commissions'),
      category: 'PRODUCT',
      headerIcon: productHeaderIcon,
    });
  }

  if (hasCustomUserRoleReadPermissions(activeUser, 'PRODUCT.LIST')) {
    routes.push({
      route: '/products/:id',
      title: t('Products - Details'),
      category: 'PRODUCT',
      headerIcon: productHeaderIcon,
    });
  }
  if (hasCustomUserRoleWritePermissions(activeUser, 'PRODUCT.LIST')) {
    routes.push({
      route: '/products/:id/edit',
      title: t('Products - Edit'),
      category: 'PRODUCT',
      headerIcon: productHeaderIcon,
    });
  }

  if (availabilityTableEnabledForAccount(activeUser)) {
    if (hasCustomUserRoleReadPermissions(activeUser, 'AVAILABILITY.WEEKLY')) {
      routes.push({
        route: '/availability/weekly',
        title: t('Weekly Availability'),
        navigationTitle: t('Weekly'),
        category: 'AVAILABILITY',
        headerIcon: availabilityHeaderIcon,
      });
      if (config.enableUIRevamp) {
        routes.push({
          route: '/availability/weekly-v3',
          title: t('Weekly Availability'),
          navigationTitle: `${t('Weekly')} V3`,
          category: 'AVAILABILITY',
          headerIcon: availabilityHeaderIcon,
        });
      }
    }
    if (hasCustomUserRoleReadPermissions(activeUser, 'AVAILABILITY.MONTHLY')) {
      routes.push({
        route: '/availability/monthly',
        title: t('Monthly Availability'),
        navigationTitle: t('Monthly'),
        category: 'AVAILABILITY',
        headerIcon: availabilityHeaderIcon,
      });
      if (config.enableUIRevamp) {
        routes.push({
          route: '/availability/monthly-v3',
          title: t('Monthly Availability'),
          navigationTitle: `${t('Monthly')} V3`,
          category: 'AVAILABILITY',
          headerIcon: availabilityHeaderIcon,
        });
      }
    }
    if (hasCustomUserRoleReadPermissions(activeUser, 'AVAILABILITY.CUSTOM')) {
      routes.push({
        route: '/availability/custom',
        title: t('Custom Availability'),
        navigationTitle: t('Custom'),
        category: 'AVAILABILITY',
        headerIcon: availabilityHeaderIcon,
      });
    }
  }

  if (
    !config.enableChannelManager &&
    operationAllowed(activeUser, 'write', 'transactions')
  ) {
    if (hasCustomUserRoleReadPermissions(activeUser, 'BILLING.INVOICES')) {
      routes.push({
        route: '/billing',
        title: t('Invoices'),
        navigationTitle: t('Invoices'),
        category: 'BILLING',
        headerIcon: billingHeaderIcon,
      });
    }
    if (hasCustomUserRoleWritePermissions(activeUser, 'BILLING.PERIODS')) {
      routes.push({
        route: '/billing/period',
        title: t('Billing Period'),
        navigationTitle: t('Billing Period'),
        category: 'BILLING',
        headerIcon: billingHeaderIcon,
      });
    }
    if (hasCustomUserRoleReadPermissions(activeUser, 'BILLING.INVOICES')) {
      routes.push({
        route: '/billing/:id',
        title: t('Invoice Details'),
        category: 'BILLING',
        headerIcon: billingHeaderIcon,
      });
    }
  }

  if (
    operationAllowed(activeUser, 'write', 'accounts') ||
    operationAllowed(activeUser, 'write', 'organizations')
  ) {
    routes.push({
      route: '/manage',
      title: t('Manage'),
      navigationTitle: t('Manage'),
      category: 'MANAGE',
      headerIcon: '',
    });
  }

  if (
    hasSubscription(activeOrganization, 'feature-review') &&
    crmEnabledForAccount(activeUser) &&
    hasCustomUserRoleReadPermissions(activeUser, 'REVIEW.REVIEWS')
  ) {
    routes.push({
      route: '/crm',
      title: t('Reviews'),
      navigationTitle: t('Reviews'),
      category: 'REVIEW',
      headerIcon: '',
    });
  }

  if (
    hasSubscription(activeOrganization, 'feature-review') &&
    crmEnabledForAccount(activeUser) &&
    hasCustomUserRoleReadPermissions(activeUser, 'REVIEW.EMBED_CODE')
  ) {
    routes.push({
      route: '/crm/reviewEmbed',
      title: t('Review Embed Code'),
      navigationTitle: t('Review Embed Code'),
      category: 'REVIEW',
      headerIcon: '',
    });
  }

  if (
    hasSubscription(activeOrganization, 'feature-review') &&
    crmEnabledForAccount(activeUser) &&
    hasCustomUserRoleReadPermissions(activeUser, 'REVIEW.SETTINGS')
  ) {
    routes.push({
      route: '/crm/settings',
      title: t('Review Settings'),
      navigationTitle: t('Review Settings'),
      category: 'REVIEW',
      headerIcon: '',
    });
  }

  if (
    newReservationButtonEnabledForAccount(activeUser) &&
    hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST')
  ) {
    routes.push({
      route: '#',
      title: t('Create new reservation'),
      navigationTitle: t('Create new reservation'),
      category: 'NEW_RESERVATIONS',
      headerIcon: '',
    });
  }

  if (
    hasSubscription(activeOrganization, 'feature-digital-waiver') &&
    waiverEnabledForAccount(activeUser)
  ) {
    if (hasCustomUserRoleWritePermissions(activeUser, 'WAIVER.SETTINGS')) {
      routes.push({
        route: '/waivers/settings',
        title: t('Waiver Settings'),
        navigationTitle: t('Waiver Settings'),
        category: 'WAIVER',
        headerIcon: '',
      });
    }
    if (hasCustomUserRoleReadPermissions(activeUser, 'WAIVER.TEMPLATES')) {
      routes.push(
        {
          route: '/waivers/templates',
          title: t('Waiver Templates'),
          navigationTitle: t('Waiver Templates'),
          category: 'WAIVER',
          headerIcon: '',
        },
        {
          route: '/waivers/templates/:id/edit',
          title: t('Waiver Template - Edit'),
          category: 'WAIVER',
          headerIcon: '',
        },
        {
          route: '/waivers/templates/new',
          title: t('Waiver Template - New'),
          category: 'WAIVER',
          headerIcon: '',
        }
      );
    }
    if (hasCustomUserRoleReadPermissions(activeUser, 'WAIVER.WAIVERS')) {
      routes.push({
        route: '/waivers',
        title: t('Submitted Waivers'),
        navigationTitle: t('Submitted Waivers'),
        category: 'WAIVER',
        headerIcon: '',
      });
    }
  }

  if (
    operationAllowed(activeUser, 'write', 'productContents') &&
    hasCustomUserRoleWritePermissions(activeUser, 'BOOKING_SITE.HOMEPAGES')
  ) {
    routes.push(
      {
        route: '/homepages/about',
        title: t('Homepages - About Us'),
        navigationTitle: t('About Us'),
        category: 'HP_SETTINGS',
      },
      {
        route: '/homepages/faq',
        title: t('Homepages - FAQ'),
        navigationTitle: t('FAQ'),
        category: 'HP_SETTINGS',
      },
      {
        route: '/homepages/covid19',
        title: t('Homepages - COVID-19 Guidelines'),
        navigationTitle: t('COVID-19 Guidelines'),
        category: 'HP_SETTINGS',
      },
      {
        route: '/homepages/customPage',
        title: t('Homepages - Custom Page'),
        navigationTitle: t('Custom Page'),
        category: 'HP_SETTINGS',
      }
    );
  }

  if (hasSubscription(activeOrganization, 'feature-guide-account')) {
    if (
      hasCustomUserRoleReadPermissions(activeUser, 'SHIFT_MANAGEMENT.STAFF')
    ) {
      routes.push({
        route: '/guides',
        title: t('Staff'),
        navigationTitle: t('Staff'),
        category: 'GUIDE_ACCOUNT',
        headerIcon: '',
      });
    }
    if (
      hasCustomUserRoleReadPermissions(activeUser, 'SHIFT_MANAGEMENT.SCHEDULE')
    ) {
      routes.push({
        route: '/guides/schedules',
        title: t('Schedule'),
        navigationTitle: t('Schedule'),
        category: 'GUIDE_ACCOUNT',
        headerIcon: '',
      });
      routes.push({
        route: '/schedules',
        title: t('Schedule'),
        navigationTitle: t('Schedule'),
        category: 'SCHEDULE',
        headerIcon: '',
      });
    }
  }

  if (hasSubscription(activeOrganization, 'feature-qr-checkin')) {
    routes.push({
      route: '/checkin/:id',
      title: t('Checkin'),
      navigationTitle: '',
      category: null,
      headerIcon: manifestHeaderIcon,
    });
  }

  if (
    hasSubscription(activeOrganization, 'feature-image-download') &&
    crmEnabledForAccount(activeUser)
  ) {
    if (
      hasCustomUserRoleWritePermissions(activeUser, 'MEDIA_DOWNLOAD.ORDERS')
    ) {
      routes.push({
        route: '/mediadownload/orders',
        title: t('Orders'),
        navigationTitle: t('Orders'),
        category: 'MEDIA_DOWNLOAD',
        headerIcon: '',
      });
    }
    if (
      hasCustomUserRoleWritePermissions(activeUser, 'MEDIA_DOWNLOAD.PRICES')
    ) {
      routes.push({
        route: '/mediadownload/prices',
        title: t('Prices'),
        navigationTitle: t('Prices'),
        category: 'MEDIA_DOWNLOAD',
        headerIcon: '',
      });
    }
    if (
      hasCustomUserRoleWritePermissions(activeUser, 'MEDIA_DOWNLOAD.UPLOAD')
    ) {
      routes.push({
        route: '/mediadownload/salespages',
        title: t('Upload'),
        navigationTitle: t('Upload'),
        category: 'MEDIA_DOWNLOAD',
        headerIcon: '',
      });
    }
    if (
      hasCustomUserRoleWritePermissions(activeUser, 'MEDIA_DOWNLOAD.SETTINGS')
    ) {
      routes.push({
        route: '/mediadownload/settings',
        title: t('Settings'),
        navigationTitle: t('Settings'),
        category: 'MEDIA_DOWNLOAD',
        headerIcon: '',
      });
    }
  }

  if (
    hasSubscription(activeOrganization, 'feature-digital-guidance') &&
    crmEnabledForAccount(activeUser)
  ) {
    if (
      hasCustomUserRoleReadPermissions(activeUser, 'DIGITAL_GUIDANCE.PAGES')
    ) {
      routes.push({
        route: '/digitalguidance/pages',
        title: t('Pages'),
        navigationTitle: t('Pages'),
        category: 'DIGITAL_GUIDANCE',
        headerIcon: '',
      });
    }
    if (
      hasCustomUserRoleReadPermissions(activeUser, 'DIGITAL_GUIDANCE.COUPONS')
    ) {
      routes.push({
        route: '/digitalguidance/coupons',
        title: t('Coupons'),
        navigationTitle: t('Coupons'),
        category: 'DIGITAL_GUIDANCE',
        headerIcon: '',
      });
    }
    if (
      hasCustomUserRoleReadPermissions(
        activeUser,
        'DIGITAL_GUIDANCE.STAMP_RALLIES'
      )
    ) {
      routes.push({
        route: '/digitalguidance/stamprallies',
        title: t('Stamp Rallies'),
        navigationTitle: t('Stamp Rallies'),
        category: 'DIGITAL_GUIDANCE',
        headerIcon: '',
      });
    }
    if (
      hasCustomUserRoleWritePermissions(activeUser, 'DIGITAL_GUIDANCE.SETTINGS')
    ) {
      routes.push({
        route: '/digitalguidance/settings',
        title: t('Settings'),
        navigationTitle: t('Settings'),
        category: 'DIGITAL_GUIDANCE',
        headerIcon: '',
      });
    }
    if (
      hasCustomUserRoleReadPermissions(
        activeUser,
        'DIGITAL_GUIDANCE.REDEEMED_RECORDS'
      )
    ) {
      routes.push({
        route: '/digitalguidance/records',
        title: t('Redeemed Records'),
        navigationTitle: t('Redeemed Records'),
        category: 'DIGITAL_GUIDANCE',
        headerIcon: '',
      });
    }
  }

  if (
    hasSubscription(activeOrganization, 'feature-e-ticket') &&
    crmEnabledForAccount(activeUser)
  ) {
    if (hasCustomUserRoleReadPermissions(activeUser, 'E_TICKET.REDEMPTIONS')) {
      routes.push({
        route: '/eticket/checkinrecords',
        title: t('E-ticket redemptions'),
        navigationTitle: t('E-ticket redemptions'),
        category: 'CHECKIN_RECORD',
      });
    }
    if (hasCustomUserRoleReadPermissions(activeUser, 'E_TICKET.LOCATIONS')) {
      routes.push({
        route: '/eticket/locations',
        title: t('E-ticket location'),
        navigationTitle: t('E-ticket location'),
        category: 'CHECKIN_RECORD',
      });
    }
    if (hasCustomUserRoleReadPermissions(activeUser, 'E_TICKET.VIEW_HISTORY')) {
      routes.push({
        route: '/eticket/footprints',
        title: t('E-ticket View history'),
        navigationTitle: t('E-ticket View history'),
        category: 'CHECKIN_RECORD',
      });
    }
    if (
      config.enableETicketOptionQRCode &&
      hasCustomUserRoleReadPermissions(activeUser, 'E_TICKET.QR_CODES')
    ) {
      routes.push({
        route: '/eticket/qrcodes',
        title: t('E-ticket QR Code'),
        navigationTitle: t('E-ticket QR Code'),
        category: 'CHECKIN_RECORD',
      });
    }
    if (hasCustomUserRoleReadPermissions(activeUser, 'E_TICKET.DAILY_USES')) {
      routes.push({
        route: '/eticket/dailyuses',
        title: t('Daily Uses'),
        navigationTitle: t('Daily Uses'),
        category: 'CHECKIN_RECORD',
      });
      if (config.enableETicketPresetRedemptionCount) {
        routes.push({
          route: '/eticket/redemptioncountdailyuses',
          title: t('Daily use of tickets'),
          navigationTitle: t('Daily use of tickets'),
          category: 'CHECKIN_RECORD',
        });
      }
    }
  }

  if (
    hasSubscription(activeOrganization, 'feature-inquiry') &&
    crmEnabledForAccount(activeUser)
  ) {
    if (hasCustomUserRoleReadPermissions(activeUser, 'INQUIRY.LIST')) {
      routes.push({
        route: '/inquiries',
        title: t('Inquiries'),
        navigationTitle: t('List'),
        category: 'INQUIRY',
        headerIcon: '',
      });
    }
    if (hasCustomUserRoleWritePermissions(activeUser, 'INQUIRY.BULK_EMAIL')) {
      routes.push({
        route: '/inquiries/bulkemail',
        title: t('Bulk Email Notifications'),
        navigationTitle: t('Bulk Email Notifications'),
        category: 'INQUIRY',
        headerIcon: '',
      });
    }
    if (hasCustomUserRoleWritePermissions(activeUser, 'INQUIRY.BULK_SMS')) {
      routes.push({
        route: '/inquiries/bulksms',
        title: t('Bulk SMS Notifications'),
        navigationTitle: t('Bulk SMS Notifications'),
        category: 'INQUIRY',
        headerIcon: '',
      });
    }
    if (hasCustomUserRoleReadPermissions(activeUser, 'INQUIRY.SMS_USAGE')) {
      routes.push({
        route: '/inquiries/smsbilling',
        title: t('SMS Usage'),
        navigationTitle: t('SMS Usage'),
        category: 'INQUIRY',
        headerIcon: '',
      });
    }
    if (hasCustomUserRoleWritePermissions(activeUser, 'INQUIRY.SETTINGS')) {
      routes.push({
        route: '/inquiries/settings',
        title: t('Settings'),
        navigationTitle: t('Settings'),
        category: 'INQUIRY',
        headerIcon: '',
      });
    }
    if (hasCustomUserRoleReadPermissions(activeUser, 'INQUIRY.LIST')) {
      routes.push({
        route: '/inquiries/:id',
        title: t('Inquiries - Details'),
        category: 'INQUIRY',
        headerIcon: '',
      });
    }
  }

  if (
    hasSubscription(activeOrganization, 'feature-survey') &&
    crmEnabledForAccount(activeUser)
  ) {
    if (
      config.enableNpsSurvey &&
      hasCustomUserRoleReadPermissions(activeUser, 'SURVEY.SUBMISSIONS')
    ) {
      routes.push({
        route: '/surveys/dashboard',
        title: t('NPS Dashboard'),
        navigationTitle: t('Dashboard'),
        category: 'SURVEY',
        headerIcon: '',
      });
    }
    if (hasCustomUserRoleReadPermissions(activeUser, 'SURVEY.SUBMISSIONS')) {
      routes.push({
        route: '/surveys/submitted',
        title: t('Submitted Surveys'),
        navigationTitle: t('Submitted Surveys'),
        category: 'SURVEY',
        headerIcon: '',
      });
    }
    if (hasCustomUserRoleWritePermissions(activeUser, 'SURVEY.SURVEYS')) {
      routes.push({
        route: '/surveys/templates/:id/edit',
        title: t('Survey - Edit'),
        category: 'SURVEY',
        headerIcon: '',
      });
    }
    if (hasCustomUserRoleReadPermissions(activeUser, 'SURVEY.SURVEYS')) {
      routes.push({
        route: '/surveys/templates/:id/results',
        title: t('Survey - Results'),
        category: 'SURVEY',
        headerIcon: '',
      });
    }
    if (hasCustomUserRoleWritePermissions(activeUser, 'SURVEY.SURVEYS')) {
      routes.push({
        route: '/surveys/templates/new',
        title: t('Survey - New'),
        category: 'SURVEY',
        headerIcon: '',
      });
    }
    if (hasCustomUserRoleReadPermissions(activeUser, 'SURVEY.SURVEYS')) {
      routes.push({
        route: '/surveys/templates',
        title: t('Surveys'),
        navigationTitle: t('Surveys'),
        category: 'SURVEY',
        headerIcon: '',
      });
    }
    if (hasCustomUserRoleWritePermissions(activeUser, 'SURVEY.SETTINGS')) {
      routes.push({
        route: '/surveys/settings',
        title: t('Settings'),
        navigationTitle: t('Settings'),
        category: 'SURVEY',
        headerIcon: '',
      });
    }
  }

  if (crmEnabledForAccount(activeUser)) {
    if (
      hasCustomUserRoleReadPermissions(activeUser, 'SYSTEM_FEE.WEB_BOOKINGS')
    ) {
      routes.push({
        route: '/systemfee/reservations',
        title: t('Web Bookings'),
        navigationTitle: t('Web Bookings'),
        category: 'SYSTEM_FEE',
        headerIcon: '',
      });
      if (config.enableUIRevamp) {
        routes.push({
          route: '/systemfee/reservations-v3',
          title: t('Web Bookings'),
          navigationTitle: `${t('Web Bookings')} V3`,
          category: 'SYSTEM_FEE',
        });
      }
    }
    if (
      hasCustomUserRoleReadPermissions(activeUser, 'SYSTEM_FEE.WEB_BOOKINGS')
    ) {
      routes.push({
        route: '/systemfee/reservations/:id',
        title: t('Web Bookings'),
        category: 'SYSTEM_FEE',
        headerIcon: '',
      });
      if (config.enableUIRevamp) {
        routes.push({
          route: '/systemfee/reservations-v3/:id',
          title: t('Web Bookings'),
          category: 'SYSTEM_FEE',
        });
      }
    }
    if (
      hasCustomUserRoleReadPermissions(activeUser, 'SYSTEM_FEE.SUBSCRIPTIONS')
    ) {
      routes.push({
        route: '/systemfee/subscriptions',
        title: t('Subscriptions'),
        navigationTitle: t('Subscriptions'),
        category: 'SYSTEM_FEE',
        headerIcon: '',
      });
      if (config.enableUIRevamp) {
        routes.push({
          route: '/systemfee/subscriptions-v3',
          title: t('Subscriptions'),
          navigationTitle: `${t('Subscriptions')} V3`,
          category: 'SYSTEM_FEE',
        });
      }
    }
    if (hasCustomUserRoleWritePermissions(activeUser, 'SYSTEM_FEE.SETTINGS')) {
      routes.push({
        route: '/systemfee/settings',
        title: t('Settings'),
        navigationTitle: t('Settings'),
        category: 'SYSTEM_FEE',
        headerIcon: '',
      });
      if (config.enableUIRevamp) {
        routes.push({
          route: '/systemfee/settings-v3',
          title: t('Settings'),
          navigationTitle: `${t('Settings')} V3`,
          category: 'SYSTEM_FEE',
        });
      }
    }
  }

  if (
    hasSubscription(activeOrganization, 'feature-customer-ledger') &&
    crmEnabledForAccount(activeUser)
  ) {
    if (hasCustomUserRoleReadPermissions(activeUser, 'CUSTOMER_LEDGER.LIST')) {
      routes.push({
        route: '/customers/dashboard',
        title: t('CRM Dashboard'),
        navigationTitle: t('Dashboard'),
        category: 'CUSTOMER_LEDGER',
        headerIcon: '',
      });
      routes.push({
        route: '/customers',
        title: t('Members'),
        navigationTitle: t('List'),
        category: 'CUSTOMER_LEDGER',
        headerIcon: '',
      });
    }
    if (
      hasCustomUserRoleReadPermissions(activeUser, 'CUSTOMER_LEDGER.SETTINGS')
    ) {
      routes.push({
        route: '/customers/settings',
        title: t('Settings'),
        navigationTitle: t('Settings'),
        category: 'CUSTOMER_LEDGER',
        headerIcon: '',
      });
      if (config.enableUIRevamp) {
        routes.push({
          route: '/customers/settings-v3',
          title: t('Settings'),
          navigationTitle: `${t('Settings')} V3`,
          category: 'CUSTOMER_LEDGER',
          headerIcon: '',
        });
      }
    }
  }

  if (
    config.enableSeatManagement &&
    crmEnabledForAccount(activeUser) &&
    hasSubscription(activeOrganization, 'feature-seat-management')
  ) {
    if (
      hasCustomUserRoleReadPermissions(
        activeUser,
        'SEAT_MANAGEMENT.ASSIGNMENTS'
      )
    ) {
      routes.push({
        route: '/seats',
        title: t('Seat Management'),
        navigationTitle: t('Assignments'),
        category: 'SEAT_MANAGEMENT',
        headerIcon: '',
      });
    }
    if (
      hasCustomUserRoleReadPermissions(
        activeUser,
        'SEAT_MANAGEMENT.WEEKLY_AVAILABILITY'
      )
    ) {
      routes.push({
        route: '/seats/weekly',
        title: t('Weekly Availability'),
        navigationTitle: t('Weekly Availability'),
        category: 'SEAT_MANAGEMENT',
        headerIcon: availabilityHeaderIcon,
      });
    }
    if (
      hasCustomUserRoleReadPermissions(
        activeUser,
        'SEAT_MANAGEMENT.MONTHLY_AVAILABILITY'
      )
    ) {
      routes.push({
        route: '/seats/monthly',
        title: t('Monthly Availability'),
        navigationTitle: t('Monthly Availability'),
        category: 'SEAT_MANAGEMENT',
        headerIcon: availabilityHeaderIcon,
      });
    }
    if (
      hasCustomUserRoleReadPermissions(activeUser, 'SEAT_MANAGEMENT.SETTINGS')
    ) {
      routes.push({
        route: '/seats/settings',
        title: t('Settings'),
        navigationTitle: t('Settings'),
        category: 'SEAT_MANAGEMENT',
        headerIcon: availabilityHeaderIcon,
      });
    }
    if (
      loggedInAccountIsNutmegAdmin ||
      /localhost:3000/.test(window.location.origin)
    ) {
      routes.push({
        route: '/seats/resources',
        title: t('Product Mapping'),
        navigationTitle: t('Product Mapping'),
        category: 'SEAT_MANAGEMENT',
        headerIcon: '',
      });
      routes.push({
        route: '/seats/editor',
        title: t('Seat Editor'),
        navigationTitle: t('Seat Editor'),
        category: 'SEAT_MANAGEMENT',
        headerIcon: '',
      });
      routes.push({
        route: '/seats/bookingsourcegroups',
        title: t('Booking Source Group'),
        navigationTitle: t('Booking Source Group'),
        category: 'SEAT_MANAGEMENT',
        headerIcon: '',
      });
      routes.push({
        route: '/seats/pricing',
        title: t('Price'),
        navigationTitle: t('Price'),
        category: 'SEAT_MANAGEMENT',
        headerIcon: '',
      });
    }
  }

  if (config.enableInstantWin && crmEnabledForAccount(activeUser)) {
    if (
      hasCustomUserRoleReadPermissions(
        activeUser,
        'INSTANT_WIN.INSTANT_WIN_SETTINGS'
      )
    ) {
      routes.push({
        route: '/instantwins',
        title: t('Instant Win Settings'),
        navigationTitle: t('Instant Win Settings'),
        category: 'INSTANT_WIN',
        headerIcon: '',
      });
    }
    if (
      hasCustomUserRoleReadPermissions(
        activeUser,
        'INSTANT_WIN.INSTANT_WIN_EVENTS'
      )
    ) {
      routes.push({
        route: '/instantwins/events',
        title: t('Instant Win Events'),
        navigationTitle: t('Instant Win Events'),
        category: 'INSTANT_WIN',
        headerIcon: '',
      });
    }
    if (hasCustomUserRoleReadPermissions(activeUser, 'INSTANT_WIN.SETTINGS')) {
      routes.push({
        route: '/instantwins/settings',
        title: t('Settings'),
        navigationTitle: t('Settings'),
        category: 'INSTANT_WIN',
        headerIcon: '',
      });
    }
  }

  if (
    config.enableChannelManager &&
    hasSubscription(activeOrganization, 'feature-channel-manager')
  ) {
    if (
      commissionsTableEnabledForAccount(activeUser) &&
      hasCustomUserRoleWritePermissions(
        activeUser,
        'CHANNEL_MANAGER.COMMISSIONS'
      )
    ) {
      routes.push({
        route: '/channelmanager',
        title: t('Commissions'),
        navigationTitle: t('Commissions'),
        category: 'CHANNEL_MANAGER',
        headerIcon: productHeaderIcon,
      });
    }
    if (operationAllowed(activeUser, 'write', 'transactions')) {
      if (
        hasCustomUserRoleReadPermissions(activeUser, 'CHANNEL_MANAGER.INVOICES')
      ) {
        routes.push({
          route: '/channelmanager/billing',
          title: t('Invoices'),
          navigationTitle: t('Invoices'),
          category: 'CHANNEL_MANAGER',
          headerIcon: billingHeaderIcon,
        });
      }
      if (
        hasCustomUserRoleWritePermissions(activeUser, 'CHANNEL_MANAGER.PERIODS')
      ) {
        routes.push({
          route: '/channelmanager/billing/period',
          title: t('Billing Period'),
          navigationTitle: t('Billing Period'),
          category: 'CHANNEL_MANAGER',
          headerIcon: billingHeaderIcon,
        });
      }
      if (
        hasCustomUserRoleReadPermissions(activeUser, 'CHANNEL_MANAGER.INVOICES')
      ) {
        routes.push({
          route: '/channelmanager/billing/:id',
          title: t('Invoice Details'),
          category: 'CHANNEL_MANAGER',
          headerIcon: billingHeaderIcon,
        });
      }
    }
    if (
      hasCustomUserRoleReadPermissions(
        activeUser,
        'CHANNEL_MANAGER.JARAN_IMPORT'
      )
    ) {
      routes.push({
        route: '/channelmanager/jaranimport',
        title: t('Jalan Import'),
        navigationTitle: t('Jalan Import'),
        category: 'CHANNEL_MANAGER',
        headerIcon: reservationHeaderIcon,
      });
    }
  }

  if (hasSubscription(activeOrganization, 'feature-annual-pass')) {
    if (hasCustomUserRoleReadPermissions(activeUser, 'RESERVATION.LIST')) {
      if (config.enableAnnualPassDashboard) {
        routes.push({
          route: '/annualpasses/dashboard',
          title: t('Annual Pass Dashboard'),
          navigationTitle: t('Dashboard'),
          category: 'ANNUAL_PASS',
          headerIcon: reservationHeaderIcon,
        });
      }
      routes.push({
        route: '/annualpasses',
        title: t('Annual Pass'),
        navigationTitle: t('List'),
        category: 'ANNUAL_PASS',
        headerIcon: reservationHeaderIcon,
      });
      routes.push({
        route: '/annualpasses/reservations',
        title: t('Daily E-ticket Usage'),
        navigationTitle: t('Daily E-ticket Usage'),
        category: 'ANNUAL_PASS',
        headerIcon: reservationHeaderIcon,
      });
      routes.push({
        route: '/annualpasses/reservations/:id',
        title: t('Daily E-ticket Usage'),
        category: 'ANNUAL_PASS',
        headerIcon: '',
      });
      routes.push({
        route: '/annualpasses/offers',
        title: t('Annual Pass Sales Offers'),
        navigationTitle: t('Annual Pass Sales Offers'),
        category: 'ANNUAL_PASS',
        headerIcon: '',
      });
      routes.push({
        route: '/annualpasses/offers/:id/edit',
        title: t('Annual Pass Sales Offers - Edit'),
        category: 'ANNUAL_PASS',
        headerIcon: '',
      });
      routes.push({
        route: '/annualpasses/offers/new',
        title: t('Annual Pass Sales Offers - New'),
        category: 'ANNUAL_PASS',
        headerIcon: '',
      });
    }
  }

  if (hasSubscription(activeOrganization, 'feature-customer-ledger')) {
    routes.push({
      route: '/newsletters/new',
      title: t('Send Newsletter'),
      category: 'NEWSLETTER',
      headerIcon: newsletterHeaderIcon,
    });
    routes.push({
      route: '/newsletters/:id/edit',
      title: t('Send Newsletter'),
      category: 'NEWSLETTER',
      headerIcon: newsletterHeaderIcon,
    });
    routes.push({
      route: '/newsletters/:id/report',
      title: t('Report'),
      category: 'NEWSLETTER',
      headerIcon: newsletterHeaderIcon,
    });
    routes.push({
      route: '/newsletters/:id/email',
      title: t('Newsletter Email'),
      category: 'NEWSLETTER',
      headerIcon: newsletterHeaderIcon,
    });

    if (hasCustomUserRoleReadPermissions(activeUser, 'NEWSLETTER.LIST')) {
      routes.push({
        route: '/newsletters',
        title: t('Newsletters'),
        navigationTitle: t('Newsletters'),
        category: 'NEWSLETTER',
        headerIcon: newsletterHeaderIcon,
      });
    }
    if (hasCustomUserRoleReadPermissions(activeUser, 'NEWSLETTER.SEGMENTS')) {
      routes.push({
        route: '/newsletters/segments',
        title: t('Segments'),
        navigationTitle: t('Segments'),
        category: 'NEWSLETTER',
        headerIcon: newsletterHeaderIcon,
      });
    }
    routes.push({
      route: '/newsletters/segments/new',
      title: t('Segments - New'),
      category: 'NEWSLETTER',
      headerIcon: newsletterHeaderIcon,
    });
    routes.push({
      route: '/newsletters/segments/:id/edit',
      title: t('Segments - Edit'),
      category: 'NEWSLETTER',
      headerIcon: newsletterHeaderIcon,
    });
  }

  if (config.enableCrossSellUpSell) {
    routes.push({
      route: '/offers',
      title: t('Sales Offers'),
      navigationTitle: t('Sales Offers'),
      category: 'CROSS_SELL_UP_SELL',
      headerIcon: '',
    });
    routes.push({
      route: '/offers/stats',
      title: t('Stats'),
      navigationTitle: t('Stats'),
      category: 'CROSS_SELL_UP_SELL',
      headerIcon: '',
    });
    routes.push({
      route: '/offers/:id/edit',
      title: t('Sales Offers - Edit'),
      category: 'CROSS_SELL_UP_SELL',
      headerIcon: '',
    });
    routes.push({
      route: '/offers/new',
      title: t('Sales Offers - New'),
      category: 'CROSS_SELL_UP_SELL',
      headerIcon: '',
    });
  }
  if (config.enableNotifications) {
    routes.push({
      route: '/notifications/templates',
      title: t('Notification Templates'),
      navigationTitle: t('Notification Templates'),
      category: 'NOTIFICATIONS',
      headerIcon: '',
    });
    routes.push({
      route: '/notifications/settings',
      category: 'NOTIFICATIONS' as TopLevelPageCategory,
      title: t('Notification Settings'),
      navigationTitle: t('Notification Settings'),
      headerIcon: '',
    });
    routes.push({
      route: '/notifications/templates/:id/edit',
      title: t('Notification Template - Edit'),
      category: 'NOTIFICATIONS',
      headerIcon: '',
    });
    routes.push({
      route: '/notifications/templates/new',
      title: t('Notification Template - New'),
      category: 'NOTIFICATIONS',
      headerIcon: '',
    });
  }

  if (hasSubscription(activeOrganization, 'feature-group-ticketing')) {
    if (hasCustomUserRoleReadPermissions(activeUser, 'GROUPS')) {
      routes.push({
        route: '/groups',
        title: t('Groups - List'),
        navigationTitle: t('List'),
        category: 'GROUP_BOOKING',
        headerIcon: '',
      });
    }
    routes.push({
      route: '/groups/:id/reservations',
      title: t('Reservations'),
      navigationTitle: '',
      category: 'GROUP_BOOKING',
      headerIcon: '',
    });
  }

  if (hasSubscription(activeOrganization, 'feature-digital-map')) {
    if (hasCustomUserRoleReadPermissions(activeUser, 'MAPS.LIST')) {
      routes.push({
        route: '/maps',
        title: t('Maps - List'),
        navigationTitle: t('List'),
        category: 'CUSTOM_MAP',
        headerIcon: '',
      });
    }
    if (
      (loggedInAccountIsNutmegAdmin || config.enableDigitalMapGuestJourneys) &&
      hasCustomUserRoleReadPermissions(activeUser, 'MAPS.DASHBOARD')
    ) {
      routes.push({
        route: '/maps/dashboard',
        title: t('Map Dashboard'),
        navigationTitle: t('Map Dashboard'),
        category: 'CUSTOM_MAP',
        headerIcon: '',
      });
    }
    if (
      (loggedInAccountIsNutmegAdmin || config.enableDigitalMapGuestJourneys) &&
      hasCustomUserRoleReadPermissions(activeUser, 'MAPS.GUEST_JOURNEYS')
    ) {
      routes.push({
        route: '/maps/guestjourneys',
        title: t('Guest Journeys'),
        navigationTitle: t('Guest Journeys'),
        category: 'CUSTOM_MAP',
        headerIcon: '',
      });
    }
    if (hasCustomUserRoleReadPermissions(activeUser, 'MAPS.ACCESS_REPORTS')) {
      routes.push({
        route: '/maps/reports',
        title: t('Access Reports'),
        navigationTitle: t('Access Reports'),
        category: 'CUSTOM_MAP',
        headerIcon: '',
      });
    }
    if (
      config.enableWaitTimePages &&
      hasCustomUserRoleReadPermissions(activeUser, 'MAPS.WAIT_TIMES')
    ) {
      routes.push({
        route: '/maps/waittimes',
        title: t('Wait Times'),
        navigationTitle: t('Wait Times'),
        category: 'CUSTOM_MAP',
        headerIcon: '',
      });
    }
    if (
      hasCustomUserRoleReadPermissions(activeUser, 'DIGITAL_GUIDANCE.COUPONS')
    ) {
      routes.push({
        route: '/maps/coupons',
        title: t('Coupons'),
        navigationTitle: t('Coupons'),
        category: 'CUSTOM_MAP',
        headerIcon: '',
      });
    }
    if (
      hasCustomUserRoleReadPermissions(
        activeUser,
        'DIGITAL_GUIDANCE.STAMP_RALLIES'
      )
    ) {
      routes.push({
        route: '/maps/stamprallies',
        title: t('Stamp Rallies'),
        navigationTitle: t('Stamp Rallies'),
        category: 'CUSTOM_MAP',
        headerIcon: '',
      });
    }
  }
  if (
    config.enableMapCustomDomain &&
    hasCustomUserRoleReadPermissions(activeUser, 'MAPS.SETTINGS')
  ) {
    routes.push({
      route: '/maps/settings',
      title: t('Map Settings'),
      navigationTitle: t('Settings'),
      category: 'CUSTOM_MAP',
      headerIcon: '',
    });
  }

  if (hasSubscription(activeOrganization, 'feature-journey-analytics')) {
    if (
      hasCustomUserRoleReadPermissions(
        activeUser,
        'JOURNEY_ANALYTICS.DASHBOARD'
      )
    ) {
      routes.push({
        route: '/journeyanalytics/dashboard',
        title: t('Dashboard'),
        navigationTitle: t('Dashboard'),
        category: 'JOURNEY_ANALYTICS',
        headerIcon: '',
      });
    }
    if (
      hasCustomUserRoleReadPermissions(
        activeUser,
        'JOURNEY_ANALYTICS.CUSTOMER_LIST'
      )
    ) {
      routes.push({
        route: '/journeyanalytics/customers',
        title: t('Customer List'),
        navigationTitle: t('Customer List'),
        category: 'JOURNEY_ANALYTICS',
        headerIcon: '',
      });
    }
    routes.push({
      route: '/journeyanalytics/customers/:id',
      title: t('Customer Details'),
      navigationTitle: '',
      category: 'JOURNEY_ANALYTICS',
      headerIcon: '',
    });
    if (
      config.enableConversionPath &&
      hasCustomUserRoleReadPermissions(
        activeUser,
        'JOURNEY_ANALYTICS.CONVERSION_PATH'
      )
    ) {
      routes.push({
        route: '/journeyanalytics/conversionpaths',
        title: t('Conversion Path'),
        navigationTitle: t('Conversion Path'),
        category: 'JOURNEY_ANALYTICS',
        headerIcon: '',
      });
    }
    if (
      hasCustomUserRoleReadPermissions(activeUser, 'JOURNEY_ANALYTICS.SETTINGS')
    ) {
      routes.push({
        route: '/journeyanalytics/settings',
        title: t('Settings'),
        navigationTitle: t('Settings'),
        category: 'JOURNEY_ANALYTICS',
        headerIcon: '',
      });
    }
    if (
      hasCustomUserRoleReadPermissions(
        activeUser,
        'JOURNEY_ANALYTICS.EMBED_CODE'
      )
    ) {
      routes.push({
        route: '/journeyanalytics/embed',
        title: t('Embed Code'),
        navigationTitle: t('Embed Code'),
        category: 'JOURNEY_ANALYTICS',
        headerIcon: '',
      });
    }
  }

  if (
    config.enableOnSiteJourneyAnalytics &&
    isOnSiteJourneyAnalyticsSupplier(
      activeOrganization,
      loggedInAccountIsNutmegAdmin
    )
  ) {
    if (
      hasCustomUserRoleReadPermissions(
        activeUser,
        'ONSITE_JOURNEY_ANALYTICS.DASHBOARD'
      )
    ) {
      routes.push({
        route: '/onsitejourneyanalytics/dashboard',
        title: t('Dashboard'),
        navigationTitle: t('Dashboard'),
        category: 'ON_SITE_JOURNEY_ANALYTICS',
        headerIcon: '',
      });
    }
    if (
      hasCustomUserRoleReadPermissions(
        activeUser,
        'ONSITE_JOURNEY_ANALYTICS.CUSTOMER_LIST'
      )
    ) {
      routes.push({
        route: '/onsitejourneyanalytics/customers',
        title: t('Customer List'),
        navigationTitle: t('Customer List'),
        category: 'ON_SITE_JOURNEY_ANALYTICS',
        headerIcon: '',
      });
    }
    routes.push({
      route: '/onsitejourneyanalytics/customers/:id',
      title: t('Customer Details'),
      navigationTitle: '',
      category: 'ON_SITE_JOURNEY_ANALYTICS',
      headerIcon: '',
    });
    if (
      hasCustomUserRoleReadPermissions(
        activeUser,
        'ONSITE_JOURNEY_ANALYTICS.SETTINGS'
      )
    ) {
      routes.push({
        route: '/onsitejourneyanalytics/settings',
        title: t('Settings'),
        navigationTitle: t('Settings'),
        category: 'ON_SITE_JOURNEY_ANALYTICS',
        headerIcon: '',
      });
    }
  }

  if (hasSubscription(activeOrganization, 'feature-marketing-automation')) {
    if (
      hasCustomUserRoleReadPermissions(
        activeUser,
        'MARKETING_AUTOMATION.DASHBOARD'
      )
    ) {
      routes.push({
        route: '/marketingautomation/dashboard',
        title: t('Dashboard'),
        navigationTitle: t('Dashboard'),
        category: 'MARKETING_AUTOMATION',
        headerIcon: '',
      });
    }
    if (
      hasCustomUserRoleReadPermissions(
        activeUser,
        'MARKETING_AUTOMATION.SEGMENTS'
      )
    ) {
      routes.push({
        route: '/marketingautomation/segments',
        title: t('Segments'),
        navigationTitle: t('Segments'),
        category: 'MARKETING_AUTOMATION',
        headerIcon: '',
      });
    }
    if (
      hasCustomUserRoleReadPermissions(
        activeUser,
        'MARKETING_AUTOMATION.TRIGGERS'
      )
    ) {
      routes.push({
        route: '/marketingautomation/triggers',
        title: t('Triggers and Conditions'),
        navigationTitle: t('Triggers and Conditions'),
        category: 'MARKETING_AUTOMATION',
        headerIcon: '',
      });
    }
    if (
      hasCustomUserRoleReadPermissions(
        activeUser,
        'MARKETING_AUTOMATION.CONTENTS'
      )
    ) {
      routes.push({
        route: '/marketingautomation/contents',
        title: t('Contents'),
        navigationTitle: t('Contents'),
        category: 'MARKETING_AUTOMATION',
        headerIcon: '',
      });
    }
    routes.push(
      {
        route: '/marketingautomation/contents/:id/edit',
        title: t('Contents - Edit'),
        navigationTitle: '',
        category: 'MARKETING_AUTOMATION',
        headerIcon: '',
      },
      {
        route: '/marketingautomation/contents/new',
        title: t('Contents - New'),
        navigationTitle: '',
        category: 'MARKETING_AUTOMATION',
        headerIcon: '',
      }
    );
    if (
      hasCustomUserRoleReadPermissions(
        activeUser,
        'MARKETING_AUTOMATION.CAMPAIGNS'
      )
    ) {
      routes.push({
        route: '/marketingautomation/campaigns',
        title: t('Campaigns'),
        navigationTitle: t('Campaigns'),
        category: 'MARKETING_AUTOMATION',
        headerIcon: '',
      });
    }
    routes.push(
      {
        route: '/marketingautomation/campaigns/:id/report',
        title: t('Report'),
        category: 'MARKETING_AUTOMATION',
        headerIcon: '',
      },
      {
        route: '/marketingautomation/campaigns/:id/email',
        title: t('Email'),
        category: 'MARKETING_AUTOMATION',
        headerIcon: '',
      }
    );
  }

  if (config.enablePartnership && isPartnershipAgent(activeOrganization)) {
    routes.push({
      route: '/partnership/reservations',
      title: t('Partnership Reservations'),
      navigationTitle: t('Partnership Reservations'),
      category: 'PARTNERSHIP',
      headerIcon: '',
    });
    routes.push({
      route: '/partnership/reservations/:id',
      title: t('Details'),
      navigationTitle: '',
      category: 'PARTNERSHIP',
      headerIcon: reservationHeaderIcon,
    });
    routes.push({
      route: '/partnership/supplierproducts',
      title: t('Partnership Products'),
      navigationTitle: t('Partnership Products'),
      category: 'PARTNERSHIP',
      headerIcon: '',
    });
    routes.push({
      route: '/partnership/agentsystemfeeinvoices',
      title: t('Fee'),
      navigationTitle: t('Fee'),
      category: 'PARTNERSHIP',
      headerIcon: '',
    });
    routes.push({
      route: '/partnership/agentsystemfeelineitems',
      title: t('Fee'),
      category: 'PARTNERSHIP',
      headerIcon: '',
    });
    routes.push({
      route: '/partnership/checkinrecords',
      title: t('E-ticket redemptions'),
      navigationTitle: t('E-ticket redemptions'),
      category: 'PARTNERSHIP',
      headerIcon: '',
    });
  }

  if (config.enablePartnership && isPartnershipSupplier(activeOrganization)) {
    routes.push({
      route: '/partnership/packageproducts',
      title: t('Partnership Package Products'),
      navigationTitle: t('Partnership Package Products'),
      category: 'PARTNERSHIP',
      headerIcon: '',
    });
    routes.push({
      route: '/partnership/suppliersystemfeeinvoices',
      title: t('Fee'),
      navigationTitle: t('Fee'),
      category: 'PARTNERSHIP',
      headerIcon: '',
    });
    routes.push({
      route: '/partnership/suppliersystemfeeinvoices/:id',
      title: t('Fee'),
      category: 'PARTNERSHIP',
      headerIcon: '',
    });
  }

  if (config.enableMobileOrder) {
    if (
      hasCustomUserRoleReadPermissions(activeUser, 'MOBILE_ORDER.DASHBOARD')
    ) {
      routes.push({
        route: '/mobileorders/dashboard',
        title: t('Dashboard'),
        navigationTitle: t('Dashboard'),
        category: 'MOBILE_ORDER',
        headerIcon: '',
      });
    }
    if (hasCustomUserRoleReadPermissions(activeUser, 'MOBILE_ORDER.ORDERS')) {
      routes.push({
        route: '/mobileorders/orders',
        title: t('Orders'),
        navigationTitle: t('Orders'),
        category: 'MOBILE_ORDER',
        headerIcon: '',
      });
    }
    if (
      hasCustomUserRoleReadPermissions(activeUser, 'MOBILE_ORDER.RESTAURANTS')
    ) {
      routes.push({
        route: '/mobileorders/restaurants',
        title: t('Restaurants'),
        navigationTitle: t('Restaurants'),
        category: 'MOBILE_ORDER',
        headerIcon: '',
      });
    }
    if (hasCustomUserRoleReadPermissions(activeUser, 'MOBILE_ORDER.KITCHEN')) {
      routes.push({
        route: '/mobileorders/kitchen',
        title: t('Kitchen'),
        navigationTitle: t('Kitchen'),
        category: 'MOBILE_ORDER',
        headerIcon: '',
      });
    }
  }

  if (hasSubscription(activeOrganization, 'feature-site-hacker')) {
    if (
      hasCustomUserRoleReadPermissions(
        activeUser,
        'SITE_CONTROL_WIDGET.DASHBOARD'
      )
    ) {
      routes.push({
        route: '/sitecontrolwidgets/dashboard',
        title: t('Site Hacker Dashboard'),
        navigationTitle: t('Dashboard'),
        category: 'SITE_CONTROL_WIDGET',
        headerIcon: '',
      });
    }
    if (
      hasCustomUserRoleReadPermissions(activeUser, 'SITE_CONTROL_WIDGET.LIST')
    ) {
      routes.push({
        route: '/sitecontrolwidgets',
        title: t('Site Hacker List'),
        navigationTitle: t('List'),
        category: 'SITE_CONTROL_WIDGET',
        headerIcon: '',
      });
    }
  }

  if (
    hasCustomUserRoleReadPermissions(activeUser, 'ACCOUNT.LIST') &&
    crmEnabledForAccount(activeUser)
  ) {
    routes.push({
      route: '/accounts',
      title: t('Accounts'),
      navigationTitle: t('List'),
      category: 'ACCOUNTS',
      headerIcon: '',
    });
  }

  return routes;
};

export const getPageCategories = (
  t: TranslateFuncType,
  activeUser: Account | null,
  activeOrganization: Organization | null
): PageCategory[] => {
  const tutorialCompleted =
    activeOrganization?.tutorial_stage === 'COMPLETED' ||
    activeOrganization?.tutorial_stage === undefined;

  return [
    ...(homeEnabledForAccount(activeUser)
      ? [
          {
            category: 'HOME' as TopLevelPageCategory,
            title: t('Home'),
            basePath: '/home',
            navIcon: homeNavIcon,
            navIconMobile: homeNavIconMobile,
          },
        ]
      : []),
    ...(operationAllowed(activeUser, 'write', 'accounts') ||
    operationAllowed(activeUser, 'write', 'organizations')
      ? [
          {
            category: 'MANAGE' as TopLevelPageCategory,
            title: t('Manage'),
            basePath: '/manage',
            navIcon: '',
            navIconMobile: '',
          },
        ]
      : []),
    ...(tutorialCompleted
      ? [
          ...(operationAllowed(activeUser, 'read', 'reservations') &&
          hasCustomUserRoleReadPermissions(activeUser, 'RESERVATION')
            ? [
                {
                  category: 'RESERVATION' as TopLevelPageCategory,
                  title: t('Reservations'),
                  basePath: '/reservations',
                  navIcon: reservationNavIcon,
                  navIconMobile: reservationNavIconMobile,
                },
              ]
            : []),
          ...(manifestEnabledForAccount(activeUser) &&
          hasCustomUserRoleReadPermissions(activeUser, 'MANIFEST')
            ? [
                {
                  category: 'MANIFEST' as TopLevelPageCategory,
                  title: t('Manifest/Dispatch'),
                  basePath: '/manifests',
                  navIcon: manifestNavIcon,
                  navIconMobile: manifestNavIconMobile,
                },
              ]
            : []),
          ...(operationAllowed(activeUser, 'read', 'products') &&
          hasCustomUserRoleReadPermissions(activeUser, 'PRODUCT')
            ? [
                {
                  category: 'PRODUCT' as TopLevelPageCategory,
                  title: t('Products'),
                  basePath: '/products',
                  navIcon: productNavIcon,
                  navIconMobile: productNavIconMobile,
                },
              ]
            : []),
          ...(operationAllowed(
            activeUser,
            'write',
            'reservationConfirmation'
          ) && hasCustomUserRoleReadPermissions(activeUser, 'AVAILABILITY')
            ? [
                {
                  category: 'AVAILABILITY' as TopLevelPageCategory,
                  title: t('Availability'),
                  basePath: '/availability',
                  navIcon: availabilityNavIcon,
                  navIconMobile: availabilityNavIconMobile,
                },
              ]
            : []),
          ...(!config.enableChannelManager &&
          operationAllowed(activeUser, 'write', 'transactions') &&
          hasCustomUserRoleReadPermissions(activeUser, 'BILLING')
            ? [
                {
                  category: 'BILLING' as TopLevelPageCategory,
                  title: t('Billing'),
                  basePath: '/billing',
                  navIcon: billingNavIcon,
                  navIconMobile: '',
                },
              ]
            : []),
          ...(bookingWidgetSettingsViewEnabledForAccount(activeUser) &&
          hasCustomUserRoleReadPermissions(activeUser, 'BOOKING_SITE')
            ? [
                {
                  category: 'BOOKING_WIDGET_SETTINGS' as TopLevelPageCategory,
                  title: t('Booking Site'),
                  basePath: '/bookingWidget',
                  navIcon: bookingWidgetIcon,
                  navIconMobile: '',
                },
              ]
            : []),
          ...(crmEnabledForAccount(activeUser) &&
          Boolean(activeOrganization?.booking_widget_api_key) &&
          hasCustomUserRoleReadPermissions(activeUser, 'SYSTEM_FEE')
            ? [
                {
                  category: 'SYSTEM_FEE' as TopLevelPageCategory,
                  title: t('System Fee'),
                  basePath: '/systemfee',
                  navIcon: billingNavIcon,
                  navIconMobile: '',
                },
              ]
            : []),
          ...(hasSubscription(activeOrganization, 'feature-review') &&
          crmEnabledForAccount(activeUser) &&
          hasCustomUserRoleReadPermissions(activeUser, 'REVIEW')
            ? [
                {
                  category: 'REVIEW' as TopLevelPageCategory,
                  title: t('Reviews'),
                  basePath: '/crm',
                  navIcon: reviewsIcon,
                  navIconMobile: '',
                },
              ]
            : []),
          ...(hasSubscription(activeOrganization, 'feature-digital-waiver') &&
          waiverEnabledForAccount(activeUser) &&
          hasCustomUserRoleReadPermissions(activeUser, 'WAIVER')
            ? [
                {
                  category: 'WAIVER' as TopLevelPageCategory,
                  title: t('Waiver'),
                  basePath: '/waivers',
                  navIcon: waiverIcon,
                  navIconMobile: '',
                },
              ]
            : []),
          ...(hasSubscription(activeOrganization, 'feature-guide-account') &&
          operationAllowed(activeUser, 'write', 'guideAccounts') &&
          hasCustomUserRoleReadPermissions(activeUser, 'SHIFT_MANAGEMENT')
            ? [
                {
                  category: 'GUIDE_ACCOUNT' as TopLevelPageCategory,
                  title: t('Shift Management'),
                  basePath: '/guides',
                  navIcon: shiftManagementIcon,
                  navIconMobile: '',
                },
              ]
            : []),
          ...(hasSubscription(activeOrganization, 'feature-guide-account') &&
          operationAllowed(activeUser, 'write', 'schedules')
            ? [
                {
                  category: 'SCHEDULE' as TopLevelPageCategory,
                  title: t('Schedule'),
                  basePath: '/schedules',
                  navIcon: '',
                  navIconMobile: '',
                },
                {
                  category: 'SETTINGS' as TopLevelPageCategory,
                  title: t('Settings'),
                  basePath: '/settings',
                  navIcon: '',
                  navIconMobile: '',
                },
              ]
            : []),
          ...(hasSubscription(activeOrganization, 'feature-image-download') &&
          crmEnabledForAccount(activeUser) &&
          hasCustomUserRoleReadPermissions(activeUser, 'MEDIA_DOWNLOAD')
            ? [
                {
                  category: 'MEDIA_DOWNLOAD' as TopLevelPageCategory,
                  title: t('Media Download'),
                  basePath: '/mediadownload',
                  navIcon: mediaDownloadNavIcon,
                  navIconMobile: '',
                },
              ]
            : []),
          ...(hasSubscription(activeOrganization, 'feature-digital-guidance') &&
          crmEnabledForAccount(activeUser) &&
          hasCustomUserRoleReadPermissions(activeUser, 'DIGITAL_GUIDANCE')
            ? [
                {
                  category: 'DIGITAL_GUIDANCE' as TopLevelPageCategory,
                  title: t('Digital Guidance'),
                  basePath: '/digitalguidance',
                  navIcon: guidanceNavIcon,
                  navIconMobile: '',
                },
              ]
            : []),
          ...(hasSubscription(activeOrganization, 'feature-e-ticket') &&
          hasCustomUserRoleReadPermissions(activeUser, 'E_TICKET')
            ? [
                {
                  category: 'CHECKIN_RECORD' as TopLevelPageCategory,
                  title: t('E-ticket'),
                  basePath: '/eticket',
                  navIcon: eTicketNavIcon,
                  navIconMobile: '',
                },
              ]
            : []),
          ...(hasSubscription(activeOrganization, 'feature-inquiry') &&
          crmEnabledForAccount(activeUser) &&
          hasCustomUserRoleReadPermissions(activeUser, 'INQUIRY')
            ? [
                {
                  category: 'INQUIRY' as TopLevelPageCategory,
                  title: t('Inquiry'),
                  basePath: '/inquiries',
                  navIcon: inquiryNavIcon,
                  navIconMobile: '',
                },
              ]
            : []),
          ...(hasSubscription(activeOrganization, 'feature-customer-ledger') &&
          crmEnabledForAccount(activeUser) &&
          hasCustomUserRoleReadPermissions(activeUser, 'CUSTOMER_LEDGER')
            ? [
                {
                  category: 'CUSTOMER_LEDGER' as TopLevelPageCategory,
                  title: t('Members'),
                  basePath: '/customers',
                  navIcon: customerIcon,
                  navIconMobile: '',
                },
              ]
            : []),
          ...(hasSubscription(activeOrganization, 'feature-survey') &&
          crmEnabledForAccount(activeUser) &&
          hasCustomUserRoleReadPermissions(activeUser, 'SURVEY')
            ? [
                {
                  category: 'SURVEY' as TopLevelPageCategory,
                  title: t('Survey'),
                  basePath: '/surveys',
                  navIcon: surveyIcon,
                  navIconMobile: '',
                },
              ]
            : []),
          {
            category: 'NEWSLETTER' as TopLevelPageCategory,
            title: t('Newsletter'),
            basePath: '/newsletters',
            navIcon: newsletterIcon,
            navIconMobile: '',
          },
          ...(config.enableSeatManagement
            ? [
                {
                  category: 'SEAT_MANAGEMENT' as TopLevelPageCategory,
                  title: t('Seat Management'),
                  basePath: '/seats',
                  navIcon: seatIcon,
                  navIconMobile: '',
                },
              ]
            : []),
          ...(hasSubscription(activeOrganization, 'feature-annual-pass')
            ? [
                {
                  category: 'ANNUAL_PASS' as TopLevelPageCategory,
                  title: t('Annual Pass'),
                  basePath: '/annualpasses',
                  navIcon: reservationNavIcon,
                  navIconMobile: '',
                },
              ]
            : []),
          ...(config.enableCrossSellUpSell
            ? [
                {
                  category: 'CROSS_SELL_UP_SELL' as TopLevelPageCategory,
                  title: t('Cross-Sell/Upsell'),
                  basePath: '/offers',
                  navIcon: '',
                  navIconMobile: '',
                },
              ]
            : []),
          ...(config.enableNotifications
            ? [
                {
                  category: 'NOTIFICATIONS' as TopLevelPageCategory,
                  title: t('Notifications'),
                  basePath: '/notifications',
                  navIcon: '',
                  navIconMobile: '',
                },
              ]
            : []),
          ...(config.enableInstantWin
            ? [
                {
                  category: 'INSTANT_WIN' as TopLevelPageCategory,
                  title: t('Instant Win'),
                  basePath: '/instantwins',
                  navIcon: '',
                  navIconMobile: '',
                },
              ]
            : []),
          ...(config.enableChannelManager &&
          hasSubscription(activeOrganization, 'feature-channel-manager')
            ? [
                {
                  category: 'CHANNEL_MANAGER' as TopLevelPageCategory,
                  title: t('Channel Manager'),
                  basePath: '/channelmanager',
                  navIcon: channelManagerIcon,
                  navIconMobile: '',
                },
              ]
            : []),
          ...(hasSubscription(activeOrganization, 'feature-group-ticketing')
            ? [
                {
                  category: 'GROUP_BOOKING' as TopLevelPageCategory,
                  title: t('Groups'),
                  basePath: '/groups',
                  navIcon: '',
                  navIconMobile: '',
                },
              ]
            : []),
          ...(hasSubscription(activeOrganization, 'feature-digital-map')
            ? [
                {
                  category: 'CUSTOM_MAP' as TopLevelPageCategory,
                  title: t('Maps'),
                  basePath: '/maps',
                  navIcon: mapIcon,
                  navIconMobile: '',
                },
              ]
            : []),
          ...(hasSubscription(activeOrganization, 'feature-journey-analytics')
            ? [
                {
                  category: 'JOURNEY_ANALYTICS' as TopLevelPageCategory,
                  title: t('Journey Analytics'),
                  basePath: '/journeyanalytics',
                  navIcon: journeyAnalyticsIcon,
                  navIconMobile: '',
                },
              ]
            : []),
          ...(config.enableOnSiteJourneyAnalytics
            ? [
                {
                  category: 'ON_SITE_JOURNEY_ANALYTICS' as TopLevelPageCategory,
                  title: t('On Site Journey Analytics'),
                  basePath: '/onsitejourneyanalytics',
                  navIcon: journeyAnalyticsIcon,
                  navIconMobile: '',
                },
              ]
            : []),
          ...(hasSubscription(
            activeOrganization,
            'feature-marketing-automation'
          )
            ? [
                {
                  category: 'MARKETING_AUTOMATION' as TopLevelPageCategory,
                  title: t('Marketing Automation'),
                  basePath: '/marketingautomation',
                  navIcon: marketingAutomationIcon,
                  navIconMobile: '',
                },
              ]
            : []),
          ...(config.enablePartnership
            ? [
                {
                  category: 'PARTNERSHIP' as TopLevelPageCategory,
                  title: t('Partnership'),
                  basePath: '/partnership',
                  navIcon: '',
                  navIconMobile: '',
                },
              ]
            : []),
          ...(hasSubscription(activeOrganization, 'feature-site-hacker')
            ? [
                {
                  category: 'SITE_CONTROL_WIDGET' as TopLevelPageCategory,
                  title: t('Site Hacker'),
                  basePath: '/sitecontrolwidgets',
                  navIcon: siteHackerIcon,
                  navIconMobile: '',
                },
              ]
            : []),
          ...(activeUser?.organization_type !== 'AGENT' &&
          hasCustomUserRoleReadPermissions(activeUser, 'ACCOUNT')
            ? [
                {
                  category: 'ACCOUNTS' as TopLevelPageCategory,
                  title: t('Accounts'),
                  basePath: '/accounts',
                  navIcon: accountIcon,
                  navIconMobile: '',
                },
              ]
            : []),
          ...(config.enableMobileOrder &&
          hasSubscription(activeOrganization, 'feature-digital-map')
            ? [
                {
                  category: 'MOBILE_ORDER' as TopLevelPageCategory,
                  title: t('Mobile Orders'),
                  basePath: '/mobileorders',
                  navIcon: '',
                  navIconMobile: '',
                },
              ]
            : []),
          ...(newReservationButtonEnabledForAccount(activeUser) &&
          hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST')
            ? [
                {
                  category: 'NEW_RESERVATIONS' as TopLevelPageCategory,
                  title: t('Create new reservation'),
                  basePath: '#',
                  navIcon: '',
                  navIconMobile: newReservationNavIconMobile,
                },
              ]
            : []),
        ]
      : []),
  ];
};
