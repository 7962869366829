import * as React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Box } from 'client/components/Box/Box';
import { DragHandle } from 'client/components/Icons/DragHandle';
import { TranslateFuncType } from 'client/components/Translate';

import styles from './SortableMenuItem.module.css';
import { CustomMenuItem } from './formValues';

const getMenuItemLabel = (
  itemType: CustomMenuItem['itemType'],
  t: TranslateFuncType
) => {
  switch (itemType) {
    case 'CUSTOM_LINK':
      return t('Custom: Link');
    case 'CUSTOM_SUBMENU':
      return t('Custom: Submenu');
    case 'MAP':
      return t('Map');
    case 'MY_ACCOUNT':
      return t('My Account');
    case 'TICKETS':
      return t('Tickets');
    case 'SCHEDULE':
      return t('Schedule');
    case 'STAMP_RALLY':
      return t('Stamp Rally');
    case 'ITINERARIES':
      return t('Itineraries');
  }
};

interface Props {
  menuItem: CustomMenuItem;
  onDelete: () => void;
  onEdit: () => void;
}

export const SortableMenuItem = ({ menuItem, onDelete, onEdit }: Props) => {
  const { t } = useTranslation();
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: menuItem.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <Box
      ref={setNodeRef}
      style={style}
      display="flex"
      alignItems="center"
      gap={2}
      cursor="default"
    >
      <div
        {...attributes}
        {...listeners}
        style={{ cursor: 'grab', display: 'flex', alignItems: 'center' }}
      >
        <DragHandle />
      </div>
      <div>{menuItem.label || getMenuItemLabel(menuItem.itemType, t)}</div>

      <Box display="flex" gap={1}>
        <a
          className={clsx(styles['button'], styles['edit'])}
          onClick={(e) => {
            e.preventDefault();
            onEdit();
          }}
        >
          <i className="c-icon-outline-general-edit-05" />
        </a>
        <a
          className={clsx(styles['button'], styles['delete'])}
          onClick={(e) => {
            e.preventDefault();
            onDelete();
          }}
        >
          <i className="c-icon-outline-general-trash-03" />
        </a>
      </Box>
    </Box>
  );
};
