import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { Button } from 'client/components/v3/Common/Button';
import { Modal } from 'client/components/v3/Form/Modal';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { TextField } from 'client/components/v3/Form/TextField';
import { config } from 'client/config';

import styles from './MenuItemEditModal.module.css';
import { CustomMenuItem, MenuItem } from './formValues';
import { SortableSubMenuItem } from './SortableSubMenuItem';

export const MenuItemEditModal = ({
  menuItem,
  onSave,
}: {
  menuItem: CustomMenuItem;
  onSave: (menuItem: CustomMenuItem) => void;
}) => {
  const { t } = useTranslation();
  const menuItemOptions = [
    { value: 'MAP', text: t('Map') },
    { value: 'MY_ACCOUNT', text: t('My Account') },
    { value: 'TICKETS', text: t('Tickets') },
    { value: 'SCHEDULE', text: t('Schedule') },
    { value: 'STAMP_RALLY', text: t('Stamp Rally') },
    ...(config.enableItineraryMaker
      ? [{ value: 'ITINERARIES', text: t('Itineraries') }]
      : []),
    { value: 'CUSTOM_LINK', text: t('Custom: Link') },
    { value: 'CUSTOM_SUBMENU', text: t('Custom: Submenu') },
  ];

  const [editedMenuItem, setEditedMenuItem] =
    React.useState<CustomMenuItem>(menuItem);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleAddSubMenuItem = () => {
    setEditedMenuItem({
      ...editedMenuItem,
      subMenuItems: [
        ...editedMenuItem.subMenuItems,
        {
          id: crypto.randomUUID(),
          label: '',
          url: '',
          itemType: 'CUSTOM_LINK',
          subMenuItems: [],
        },
      ],
    });
  };

  const handleSubMenuItemChange = (
    index: number,
    subMenuItem: CustomMenuItem
  ) => {
    const newSubMenuItems = [...editedMenuItem.subMenuItems];
    newSubMenuItems[index] = subMenuItem;
    setEditedMenuItem({
      ...editedMenuItem,
      subMenuItems: newSubMenuItems,
    });
  };

  const handleRemoveSubMenuItem = (index: number) => {
    setEditedMenuItem({
      ...editedMenuItem,
      subMenuItems: editedMenuItem.subMenuItems.filter((_, i) => i !== index),
    });
  };

  return (
    <Modal
      title={t('Edit Menu Item')}
      open
      onClose={() => onSave(editedMenuItem)}
    >
      <div className={styles.container}>
        <SingleDropdown
          label={t('Menu Item Type')}
          selectedOption={editedMenuItem.itemType}
          options={menuItemOptions}
          onChange={(value) =>
            setEditedMenuItem({
              ...editedMenuItem,
              itemType: value as MenuItem | 'CUSTOM_LINK',
            })
          }
        />

        {(editedMenuItem.itemType === 'CUSTOM_LINK' ||
          editedMenuItem.itemType === 'CUSTOM_SUBMENU') && (
          <>
            <TextField
              label={t('Label')}
              value={editedMenuItem.label}
              onChange={(value) =>
                setEditedMenuItem({
                  ...editedMenuItem,
                  label: value,
                })
              }
            />

            {editedMenuItem.itemType === 'CUSTOM_LINK' && (
              <TextField
                label={t('URL')}
                value={editedMenuItem.url}
                onChange={(value) =>
                  setEditedMenuItem({
                    ...editedMenuItem,
                    url: value,
                  })
                }
              />
            )}

            {editedMenuItem.itemType === 'CUSTOM_SUBMENU' && (
              <div className={styles.subMenuContainer}>
                <div className={styles.subMenuHeader}>
                  <h3 className={styles.subMenuTitle}>{t('Sub Menu Items')}</h3>
                </div>

                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={({ active, over }: DragEndEvent) => {
                    if (over && active.id !== over.id) {
                      const oldIndex = editedMenuItem.subMenuItems.findIndex(
                        (item) => item.id === active.id
                      );
                      const newIndex = editedMenuItem.subMenuItems.findIndex(
                        (item) => item.id === over.id
                      );

                      if (oldIndex !== -1 && newIndex !== -1) {
                        const newSubMenuItems = arrayMove(
                          editedMenuItem.subMenuItems,
                          oldIndex,
                          newIndex
                        );
                        setEditedMenuItem({
                          ...editedMenuItem,
                          subMenuItems: newSubMenuItems,
                        });
                      }
                    }
                  }}
                >
                  <SortableContext
                    items={editedMenuItem.subMenuItems}
                    strategy={verticalListSortingStrategy}
                  >
                    {editedMenuItem.subMenuItems.map((subMenuItem, index) => (
                      <SortableSubMenuItem
                        key={subMenuItem.id}
                        menuItem={subMenuItem}
                        onDelete={() => handleRemoveSubMenuItem(index)}
                        onChange={(updates) =>
                          handleSubMenuItemChange(index, {
                            ...subMenuItem,
                            ...updates,
                          })
                        }
                      />
                    ))}
                  </SortableContext>
                </DndContext>

                <Button
                  type="button"
                  color="secondary"
                  text={t('Add Sub Menu Item')}
                  onClick={handleAddSubMenuItem}
                  iconBeforeText={
                    <i className="c-icon-outline-general-plus-circle" />
                  }
                />
              </div>
            )}
          </>
        )}

        <div className={styles.actionButtons}>
          <Button onClick={() => onSave(editedMenuItem)} text={t('OK')} />
        </div>
      </div>
    </Modal>
  );
};
