import { TranslateFuncType } from 'client/components/Translate';

export const getAbTestOptions = (t: TranslateFuncType) => [
  { text: t('No Test'), value: 'NO_TEST' },
  { text: t('Test 1'), value: 'TEST_1' },
  { text: t('Test 2'), value: 'TEST_2' },
  { text: t('Test 3'), value: 'TEST_3' },
  { text: t('Test 4'), value: 'TEST_4' },
  { text: t('Test 5'), value: 'TEST_5' },
];

export const getAbTestOptionText = (t: TranslateFuncType, value: string) => {
  const options = getAbTestOptions(t);
  const option = options.find((option) => option.value === value);
  return option ? option.text : '';
};
