import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { config } from 'client/config';
import type { ReduxState } from 'client/reducers';
import type { TranslateFuncType } from 'client/components/Translate';
import { ReservationSummaryShape } from 'client/libraries/util/reservationSummaryShape';
import { Button } from 'client/components/Form';
import { equipmentAssignmentsSelector } from 'client/reducers/equipmentAssignments';
import {
  deleteEquipmentAssignment,
  deleteEquipmentAssignmentIgnoringFailure,
  bulkAssignEquipmentAssignments,
} from 'client/actions/equipmentAssignments';
import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';
import { equipmentInstancesSelector } from 'client/reducers/equipmentInstances';
import { Reservation } from 'shared/models/swagger';
import { printETicketByEpsonThermalPrinter } from 'client/libraries/util/printETicketByEpsonThermalPrinter';
import {
  markReservationEquipmentTicketPrinted,
  undoCheckinReservation,
  undoEquipmentTicketPrint,
} from 'client/actions/reservations';
import baseStyles from 'client/base.module.css';
import { useTicketNetworkPrinterIpAddress } from 'client/hooks/useTicketNetworkPrinterIpAddress';
import rqIcon from 'client/images/ic_rq.svg';
import sbIcon from 'client/images/ic_sb.svg';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { getLogoBase64 } from 'client/libraries/util/getLogoBase64';
import { equipmentsSelector } from 'client/reducers/equipments';

import {
  getCurrentReservationGuestCount,
  getReservationEquipmentBlockReferences,
} from './utils';
import { PrintETicket } from './PrintETicket';
import { ManageReservationModal } from './ManageReservationModal';
import { AssignedSeatHoverPopupWindow } from './AssignedSeatHoverPopupWindow';
import styles from './SeatAssignmentReservations.module.css';
import seatAssignmentStyles from './SeatAssignment.module.css';
import {
  SeatAssignmentReservationTable,
  ColumnType,
} from './SeatAssignmentReservationTable';

interface Props {
  filteredReservations: Reservation[];
  selectedReservationId: string;
  selectedEquipmentInstanceId?: string;
  onSelectedReservationIdChange: (reservationId: string) => void;
  filteredReservationShapes: ReservationSummaryShape[];
  onReservationDragStart?: (reservationId: string) => void;
  hoveredReservationId?: string;
  reservationNumber?: { [key: string]: string } | null;
  onHoveredReservationIdChange?: (reservationId: string) => void;
  setMemoReservationId: (reservationId: string) => void;
}

export const SeatAssignmentReservations = ({
  filteredReservations,
  selectedReservationId,
  selectedEquipmentInstanceId,
  onSelectedReservationIdChange,
  filteredReservationShapes,
  onReservationDragStart,
  hoveredReservationId,
  reservationNumber,
  onHoveredReservationIdChange,
  setMemoReservationId,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [rowCount, setRowCount] = React.useState(50);
  const [printETicketReservationId, setPrintETicketReservationId] =
    React.useState<string>('');
  const equipmentAssignments = useSelector(equipmentAssignmentsSelector);
  const equipmentInstances = useSelector(equipmentInstancesSelector);
  const equipments = useSelector(equipmentsSelector);
  const [clearTargetReservationId, setClearTargetReservationId] =
    React.useState<string>('');
  const [manageReservationId, setManageReservationId] =
    React.useState<string>('');
  const [mousePosition, setMousePosition] = React.useState<{
    top: number;
    left: number;
  } | null>(null);
  const [onList, setOnList] = React.useState<boolean>(false);
  const [deleteAllAssignments, setDeleteAllAssignments] =
    React.useState<boolean>(false);
  const { networkPrinterIpAddress } = useTicketNetworkPrinterIpAddress();

  const reservationLoading = useSelector(
    (state: ReduxState) => state.reservations.loading
  );

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  React.useEffect(() => {
    const mouseMoveHandler = (event: MouseEvent) => {
      setMousePosition({
        top: event.clientY - 32,
        left: event.clientX,
      });
    };
    document.addEventListener('mousemove', mouseMoveHandler);
    return () => {
      document.removeEventListener('mousemove', mouseMoveHandler);
    };
  }, []);

  const printETicketReservationEquipmentBlockReference = React.useMemo(
    () =>
      equipmentAssignments
        ?.filter((e) => e.reservation_id === printETicketReservationId)
        ?.map((e) => e.equipment_block_reference)
        ?.sort()
        ?.join('/') ?? '',
    [printETicketReservationId, equipmentAssignments]
  );

  const manageReservationEquipmentBlockReference =
    getReservationEquipmentBlockReferences(
      manageReservationId,
      equipments,
      equipmentInstances,
      equipmentAssignments,
      activeUserOrganization
    ).join('/');

  const manageReservationEquipmentInstanceMemo = React.useMemo(() => {
    const targetEquipmentAssignments = equipmentAssignments?.filter(
      (e) => e.reservation_id === manageReservationId
    );
    if (targetEquipmentAssignments?.length) {
      return (
        equipmentInstances?.find(
          (e) => e.id === targetEquipmentAssignments[0].equipment_instance_id
        )?.memo?.value ?? ''
      );
    } else {
      return '';
    }
  }, [manageReservationId, equipmentAssignments, equipmentInstances]);

  const selectedEquipmentInstance = React.useMemo(() => {
    return equipmentInstances.find(
      (equipmentInstance) =>
        equipmentInstance.id === selectedEquipmentInstanceId
    );
  }, [equipmentInstances, selectedEquipmentInstanceId]);

  const resetAssignmentHandler = (reservationId: string) => {
    equipmentAssignments
      .filter((assignment) => {
        return assignment.reservation_id === reservationId;
      })
      .forEach((assignment) => {
        dispatch(deleteEquipmentAssignment(assignment.id ?? ''));
      });
  };

  const resetAllAssignmentsHandler = () => {
    const targetReservationIds = filteredReservationShapes.map(
      (reservation) => reservation.id
    );
    equipmentAssignments.forEach((assignment) => {
      if (targetReservationIds.includes(assignment.reservation_id ?? '')) {
        dispatch(deleteEquipmentAssignmentIgnoringFailure(assignment.id ?? ''));
      }
    });
  };

  const useThermalPrinter = true;

  const getColumns = (
    t: TranslateFuncType
  ): ColumnType<ReservationSummaryShape>[] => {
    return [
      {
        Header: t('Seat'),
        id: 'agent_reference',
        accessor: (item: ReservationSummaryShape) => {
          let status = 'UNSELECTED';

          if (
            getCurrentReservationGuestCount(item.id, equipmentAssignments) ===
            item.guest_count
          ) {
            status = 'COMPLETED';
          } else {
            if (selectedReservationId === item.id) {
              status = 'SELECTED';
            } else {
              status = 'UNSELECTED';
            }
          }

          let text = '';
          let buttonStyle = '';
          let backgroundColor = '';

          switch (status) {
            case 'UNSELECTED':
              text = t('Select');
              buttonStyle = styles['normal'];
              break;
            case 'SELECTED':
              text = t('Selected');
              buttonStyle = styles['normal'];
              break;
            case 'COMPLETED':
              text = t('Assigned');
              buttonStyle = styles['decided'];
              backgroundColor = '#e6f8ff';
              break;
          }

          return (
            <div>
              <div
                className={clsx(styles['reservationsAction__body__action'])}
                style={{ backgroundColor: backgroundColor }}
              >
                <a
                  className={clsx(
                    styles['reservationsAction__body__action__mode']
                  )}
                  onClick={() => {
                    if (selectedReservationId === item.id) {
                      onSelectedReservationIdChange('');
                    } else {
                      onSelectedReservationIdChange(item.id);
                    }
                  }}
                >
                  <span className={clsx(styles['number'])}>
                    {reservationNumber?.[item.id] ?? ''}
                  </span>
                  <span className={clsx(styles['text'], buttonStyle)}>
                    {text}
                  </span>
                </a>
                <a
                  className={clsx(
                    styles['reservationsAction__body__action__another']
                  )}
                  onClick={() => {
                    setManageReservationId(item.id);
                  }}
                >
                  <i></i>
                </a>
                {item.supplier_internal_notes_for_dispatch && (
                  <a
                    className={clsx(
                      styles['reservationsAction__body__action__memo']
                    )}
                    onClick={() => {
                      setMemoReservationId(item.id);
                    }}
                  >
                    <i
                      className={baseStyles['base-info__ic']}
                      style={{ background: '#008ec9' }}
                    >
                      i
                    </i>
                  </a>
                )}
              </div>
            </div>
          );
        },
        width: 140,
      },
      {
        Header: `${t('Application Number')}・${t('Customer')}`,
        id: 'agent_reference_customer',
        accessor: (item: ReservationSummaryShape) => {
          return (
            <>
              <div className={baseStyles['inline-block']}>
                {item.status === 'REQUESTED' && (
                  <img style={{ width: '16px', height: '16px' }} src={rqIcon} />
                )}
                {item.status === 'STANDBY' && (
                  <img style={{ width: '16px', height: '16px' }} src={sbIcon} />
                )}
                <Link to={`/reservations/${item.id}`} target="_blank">
                  <span className={clsx(baseStyles['base-link'])}>
                    {item.agent_reference}
                  </span>
                </Link>
              </div>
              {item.guest_display_name}
            </>
          );
        },
        width: 120,
      },
      {
        Header: `${t('Product')}・${t('Units')}`,
        id: 'guest_description',
        accessor: (item: ReservationSummaryShape) => {
          return (
            <>
              {item.product_name}
              <br />
              {item.guest_description}
            </>
          );
        },
        width: 230,
      },
    ];
  };

  const autoAssignHander = async () => {
    const unassignedReservations = filteredReservationShapes.filter(
      (reservation) => {
        return (
          getCurrentReservationGuestCount(
            reservation.id,
            equipmentAssignments
          ) === 0
        );
      }
    );

    const targetEquipmentInstaneIds = [];
    if (
      !selectedEquipmentInstance?.additional_equipment_settings
        ?.close_original_equipment
    ) {
      targetEquipmentInstaneIds.push(selectedEquipmentInstance?.id ?? '');
    }

    selectedEquipmentInstance?.additional_equipment_settings?.additional_equipment_ids?.forEach(
      (additionalEquipmentId) => {
        const additionalEquipmentInstance = equipmentInstances.find(
          (equipmentInstance) =>
            equipmentInstance.equipment_id === additionalEquipmentId &&
            equipmentInstance.original_equipment_instance_id ===
              selectedEquipmentInstance.id
        );
        targetEquipmentInstaneIds.push(additionalEquipmentInstance?.id ?? '');
      }
    );

    try {
      await dispatch(
        bulkAssignEquipmentAssignments({
          reservation_ids: unassignedReservations.map(
            (reservation) => reservation.id
          ),
          to_equipment_instance_identifiers: targetEquipmentInstaneIds.map(
            (equipmentInstanceId) => {
              return {
                equipment_instance_id: equipmentInstanceId,
              };
            }
          ),
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const totalCount = filteredReservationShapes?.length ?? 0;

  const pageCount =
    Math.floor(totalCount / rowCount) + (totalCount % rowCount ? 1 : 0);

  const pageForwardClickHandler = () => {
    if (currentPage < pageCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const pageBackClickHandler = () => {
    if (1 < currentPage) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      <div className={clsx(styles['reservationsAction'])}>
        <div className={clsx(styles['reservationsAction__header'])}>
          <Button
            style="blue"
            size="tiny"
            onClick={() => {
              autoAssignHander();
            }}
          >
            {t('Auto Assign')}
          </Button>

          <a
            className={clsx(styles['reservationsAction__header__clear'])}
            onClick={() => {
              setDeleteAllAssignments(true);
            }}
          >
            {t('Clear all seats')}
          </a>
        </div>

        <div className={styles['reservationsAction__body']}>
          <SeatAssignmentReservationTable
            columns={getColumns(t)}
            items={(filteredReservationShapes ?? []).filter((_, index) => {
              const start = (currentPage - 1) * rowCount;
              const end = start + rowCount;
              return index >= start && index < end;
            })}
            draggable={true}
            onDragStart={(item: ReservationSummaryShape) => {
              if (
                getCurrentReservationGuestCount(
                  item.id,
                  equipmentAssignments
                ) === item.guest_count
              ) {
                return;
              }
              onReservationDragStart?.(item.id);
            }}
            highlightRow={(item: ReservationSummaryShape) => {
              if (selectedReservationId) {
                return item.id === selectedReservationId;
              } else {
                if (!hoveredReservationId) {
                  return true;
                }
                return item.id === hoveredReservationId;
              }
            }}
            onTrMouseEnter={(item: ReservationSummaryShape) => {
              setOnList(true);
              onHoveredReservationIdChange?.(item.id);
            }}
            onTableMouseLeave={() => {
              setOnList(false);
              onHoveredReservationIdChange?.('');
            }}
          />
        </div>

        <div className={clsx(styles['reservationsAction__pagination'])}>
          <div className={clsx(styles['c-pagination'])}>
            <div className={clsx(styles['c-pagination__size'])}>
              <p>{t('Number of lines')}:</p>
              <label>
                <select
                  value={rowCount}
                  onChange={(event: any) => {
                    const count = parseInt(event.target.value, 10);
                    setRowCount(count);
                  }}
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                </select>
              </label>
            </div>
            <div className={clsx(styles['c-pagination__jump'])}>
              <p>{t('Page')}:</p>
              <p>
                {currentPage} of {pageCount}
              </p>
            </div>
            <div className={clsx(styles['c-pagination__btns'])}>
              <a
                className={clsx(
                  styles['c-pagination__btn'],
                  1 < currentPage ? '' : styles['disable']
                )}
                onClick={pageBackClickHandler}
              >
                {t('Previous')}
              </a>
              <a
                className={clsx(
                  styles['c-pagination__btn'],
                  currentPage < pageCount ? '' : styles['disable']
                )}
                onClick={pageForwardClickHandler}
              >
                {t('Next')}
              </a>
            </div>
          </div>
        </div>

        <div className={styles['seatsAction__cell']}>
          <DeleteConfirmModal
            header={t('Clear Assignment')}
            content={'本当に解除しますか？'}
            onConfirm={() => {
              if (deleteAllAssignments) {
                resetAllAssignmentsHandler();
                return;
              }
              resetAssignmentHandler(clearTargetReservationId);
              setClearTargetReservationId('');
            }}
            onClose={() => {
              if (deleteAllAssignments) {
                setDeleteAllAssignments(false);
                return;
              }
              setClearTargetReservationId('');
            }}
            open={deleteAllAssignments || clearTargetReservationId !== ''}
            insertRoot={true}
          />
          {printETicketReservationId !== '' && (
            <PrintETicket
              reservation={filteredReservations.find(
                (reservation) => reservation.id === printETicketReservationId
              )}
              equipmentBlockReference={
                printETicketReservationEquipmentBlockReference
              }
              onClose={() => {
                setPrintETicketReservationId('');
              }}
            />
          )}
          {manageReservationId !== '' && (
            <ManageReservationModal
              reservationSummaryShape={filteredReservationShapes.find(
                (reservation) => reservation.id === manageReservationId
              )}
              open={manageReservationId !== ''}
              onClose={() => {
                setManageReservationId('');
              }}
              onPrintETicketClick={() => {
                if (!manageReservationId) {
                  return;
                }
                if (useThermalPrinter) {
                  const reservationShape = filteredReservationShapes.find(
                    (reservation) => reservation.id === manageReservationId
                  );
                  if (!reservationShape) {
                    return;
                  }
                  printETicketByEpsonThermalPrinter(
                    reservationShape,
                    networkPrinterIpAddress,
                    activeUserOrganization?.booking_widget_api_key ?? '',
                    getLogoBase64(activeUserOrganization?.id ?? ''),
                    manageReservationEquipmentBlockReference,
                    manageReservationEquipmentInstanceMemo
                  );
                  dispatch(
                    markReservationEquipmentTicketPrinted(manageReservationId)
                  );
                } else {
                  setPrintETicketReservationId(manageReservationId);
                }
              }}
              onDeleteRedemptionRecordsClick={async () => {
                await dispatch(undoCheckinReservation(manageReservationId, {}));
                if (config.enableUndoEqauipmentTicketPrint) {
                  await dispatch(undoEquipmentTicketPrint(manageReservationId));
                }
              }}
              onClearAssignmentClick={() => {
                if (!manageReservationId) {
                  return;
                }
                setClearTargetReservationId(manageReservationId);
              }}
              equipmentTicketPrinted={
                filteredReservationShapes.find(
                  (reservation) => reservation.id === manageReservationId
                )?.equipment_ticket_printed ?? false
              }
              reservationLoading={reservationLoading}
            />
          )}
        </div>
      </div>
      {hoveredReservationId !== '' && onList && (
        <div className={seatAssignmentStyles['seatsAction__cell']}>
          <AssignedSeatHoverPopupWindow
            open={hoveredReservationId !== ''}
            reservation={filteredReservations.find(
              (reservation) => reservation.id === hoveredReservationId
            )}
            position={mousePosition}
          />
        </div>
      )}
    </>
  );
};
