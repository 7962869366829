import * as React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SiteControlWidget } from '@nutmeglabs/nutmeg-sitecontrol-ui';
import { useState } from 'react';

import * as Swagger from 'shared/models/swagger';
import { currency } from 'shared/libraries/currency';
import {
  CustomTableColumn,
  TableSmall,
} from 'client/components/v3/Table/TableSmall';
import { ReduxState } from 'client/reducers';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Button } from 'client/components/v3/Common/Button';
import { Modal } from 'client/components/v3/Form/Modal';
import { updateSiteControlWidget } from 'client/actions/siteControlWidgets';
import { Loading } from 'client/components/v3/Common/Loading';

import { getAbTestOptionText } from '../SiteControlWidgetList/util';

import styles from './AbTestGadget.module.css';

interface WidgetSummary {
  name: string;
  id: string;
  abTestPair: string;
  base: {
    sales: number;
    conversions: number;
    clicks: number;
    closes: number;
    conversionRate: number;
    impressions: number;
    clickRate: number;
    targets: number;
  };
  comparison?: {
    sales: number;
    conversions: number;
    clicks: number;
    closes: number;
    conversionRate: number;
    impressions: number;
    clickRate: number;
    targets: number;
  };
}

type Props = {
  name: string;
  widgets: Swagger.SiteControlWidget[];
  abTestData: WidgetSummary[];
};

export const StopButton = ({ name, widgets, abTestData }: Props) => {
  const [showStopModal, setShowStopModal] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const bestPerformingWidgetData = abTestData.reduce((max, current) => {
    return current.base.clicks > max.base.clicks ? current : max;
  }, abTestData[0]);
  if (!bestPerformingWidgetData) {
    return <></>;
  }

  const bestPerformingWidget = widgets.find(
    (widget) => widget.id === bestPerformingWidgetData.id
  );

  const closeModal = () => {
    setShowStopModal(false);
  };

  return (
    <>
      <Button
        text={t('Stop')}
        onClick={() => {
          setShowStopModal(true);
        }}
        iconBeforeText={<i className="c-icon-outline-general-x-circle"></i>}
        color="white"
      />
      {showStopModal && (
        <Modal
          onClose={closeModal}
          insertAtRoot
          title={`${t('Stop AB Test')} "${name}"`}
          open={true}
          rightActionChildren={
            <>
              <Button text={t('Cancel')} color="white" onClick={closeModal} />
              <Button
                text={t('Stop')}
                onClick={async () => {
                  try {
                    await setShowStopModal(false);

                    // Guard: if there's only one widget just remove AB test value
                    if (widgets.length === 1) {
                      const widget = widgets[0];
                      const widgetToKeepAsActive = {
                        ...widget,
                        ab_test_pair: { value: 'NO_TEST' },
                      };
                      await dispatch(
                        updateSiteControlWidget(
                          widgetToKeepAsActive.id ?? '',
                          widgetToKeepAsActive
                        )
                      );
                      return;
                    }

                    // Change widgets in AB test to draft except for best performing widget and remove AB test values
                    const widgetsToDraft = widgets.filter(
                      (widget) => widget.id !== bestPerformingWidget?.id
                    );
                    if (widgetsToDraft.length > 0) {
                      for (const widget of widgetsToDraft) {
                        const widgetToChangeIntoDraft = {
                          ...widget,
                          ab_test_pair: { value: 'NO_TEST' },
                          status: 'DRAFT' as any,
                        };
                        await dispatch(
                          updateSiteControlWidget(
                            widgetToChangeIntoDraft.id ?? '',
                            widgetToChangeIntoDraft
                          )
                        );
                      }
                    }
                    // For best performing widget just remove AB test value
                    const widgetToKeepAsActive = {
                      ...bestPerformingWidget,
                      ab_test_pair: { value: 'NO_TEST' },
                    };
                    await dispatch(
                      updateSiteControlWidget(
                        widgetToKeepAsActive.id ?? '',
                        widgetToKeepAsActive
                      )
                    );
                  } catch (error) {
                    console.log(error);
                  }
                }}
              />
            </>
          }
          style={{ maxWidth: '600px' }}
        >
          <div>
            {t(
              'Change status of widget with low click rate to draft and stop AB testing "{{abTestPair}}". Are you sure you want to proceed?',
              {
                abTestPair: name,
              }
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

export const AbTestGadget = () => {
  const { t } = useTranslation();

  const widgets = useSelector(
    (state: ReduxState) => state.siteControlWidgets.all
  );
  const abTestPairs = React.useMemo(() => {
    const abTestPairs = new Set<number>();
    const groupedWidgets: Record<number, SiteControlWidget[]> = {};

    widgets.forEach((widget) => {
      const abTestValue = widget.ab_test_pair?.value;
      if (abTestValue) {
        const numericValue = parseInt(abTestValue.split('_')[1], 10);
        abTestPairs.add(numericValue);
        if (!groupedWidgets[numericValue]) {
          groupedWidgets[numericValue] = [];
        }
        groupedWidgets[numericValue].push(widget);
      }
    });

    return {
      groupedWidgets,
    };
  }, [widgets]);

  const org = useSelector(activeUserOrganizationSelector);

  const reportData = useSelector(
    (state: ReduxState) => state.siteControlWidgets.reportData
  );
  const loading = useSelector(
    (state: ReduxState) => state.siteControlWidgets.loading
  );

  const data = React.useMemo<WidgetSummary[]>(() => {
    if (reportData.length === 0 || widgets.length === 0) {
      return [];
    }

    const baseItemsByCampaign = reportData?.length
      ? reportData[0].items_by_widget
      : [];
    const comparisonItemsByCampaign =
      reportData?.length > 1 ? reportData[1].items_by_widget : [];

    const data = _.orderBy(
      widgets.map((widget) => {
        const baseItem = baseItemsByCampaign?.find(
          (item) => item.widget_id === widget.id
        );
        const comparisonItem = comparisonItemsByCampaign?.find(
          (item) => item.widget_id === widget.id
        );

        const baseGrossSales = baseItem?.gross_sales
          ? currency(baseItem?.gross_sales)?.value
          : 0;
        const baseConversions = baseItem?.conversion_count ?? 0;
        const baseCloses = baseItem?.closed_count ?? 0;
        const baseClicks = baseItem?.clicked_count ?? 0;
        const baseImpressions = baseItem?.impression_count ?? 0;
        const baseTargets = baseItem?.targeted_count ?? 0;
        const baseConversionRate =
          baseImpressions > 0 ? (100 * baseConversions) / baseImpressions : 0;
        const baseClickRate =
          baseImpressions > 0 ? (100 * baseClicks) / baseImpressions : 0;

        const comparisonGrossSales = comparisonItem?.gross_sales
          ? currency(comparisonItem?.gross_sales)?.value
          : 0;
        const comparisonConversions = comparisonItem?.conversion_count ?? 0;
        const comparisonCloses = comparisonItem?.closed_count ?? 0;
        const comparisonClicks = comparisonItem?.clicked_count ?? 0;
        const comparisonImpressions = comparisonItem?.impression_count ?? 0;
        const comparisonTargets = comparisonItem?.targeted_count ?? 0;
        const comparisonConversionRate =
          comparisonImpressions > 0
            ? (100 * comparisonConversions) / comparisonImpressions
            : 0;
        const comparisonClickRate =
          comparisonImpressions > 0
            ? (100 * comparisonClicks) / comparisonImpressions
            : 0;

        return {
          name: widget.name ?? '',
          id: widget.id ?? '',
          abTestPair: widget.ab_test_pair?.value ?? '',
          base: {
            sales: baseGrossSales,
            conversions: baseConversions,
            clicks: baseClicks,
            closes: baseCloses,
            conversionRate: baseConversionRate,
            impressions: baseImpressions,
            clickRate: baseClickRate,
            targets: baseTargets,
          },
          comparison: comparisonItem
            ? {
                sales: comparisonGrossSales,
                conversions: comparisonConversions,
                clicks: comparisonClicks,
                closes: comparisonCloses,
                conversionRate: comparisonConversionRate,
                impressions: comparisonImpressions,
                clickRate: comparisonClickRate,
                targets: comparisonTargets,
              }
            : undefined,
        };
      }),
      ['base.sales'],
      ['desc']
    );
    return data;
  }, [widgets, reportData]);

  const columns: CustomTableColumn<WidgetSummary>[] = React.useMemo(() => {
    return [
      {
        Header: t('Widget'),
        accessor: (widget) => (
          <div style={{ whiteSpace: 'pre-line' }}>{widget.name}</div>
        ),
        width: '176',
      },
      {
        Header: t('Targets'),
        accessor: (widget) => (
          <div className={styles['table-cell']}>
            <>
              <div className={styles['cell-base']}>{widget.base.targets}</div>
              {widget.comparison && (
                <div className={styles['cell-comparison']}>
                  {widget.comparison.targets}
                </div>
              )}
            </>
          </div>
        ),
      },
      {
        Header: t('Impressions'),
        accessor: (widget) => (
          <div className={styles['table-cell']}>
            <div className={styles['cell-base']}>
              {widget.base.impressions.toLocaleString()}
            </div>
            {widget.comparison && (
              <div className={styles['cell-comparison']}>
                {widget.comparison.impressions.toLocaleString()}
              </div>
            )}
          </div>
        ),
      },
      {
        Header: t('Closes'),
        accessor: (widget) => (
          <div className={styles['table-cell']}>
            <div className={styles['cell-base']}>
              {widget.base.closes.toLocaleString()}
            </div>

            {widget.comparison && (
              <div className={styles['cell-comparison']}>
                {widget.comparison.closes.toLocaleString()}
              </div>
            )}
          </div>
        ),
      },
      {
        Header: t('Clicks'),
        accessor: (widget) => (
          <div className={styles['table-cell']}>
            <div className={styles['cell-base']}>
              {widget.base.clicks.toLocaleString()}
            </div>

            {widget.comparison && (
              <div className={styles['cell-comparison']}>
                {widget.comparison.clicks.toLocaleString()}
              </div>
            )}
          </div>
        ),
      },
      {
        Header: t('Click Rate'),
        accessor: (widget) => (
          <div className={styles['table-cell']}>
            <div className={styles['cell-base']}>
              {widget.base.clickRate.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
              %
            </div>
            {widget.comparison && (
              <div className={styles['cell-comparison']}>
                {widget.comparison.clickRate.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}
                %
              </div>
            )}
          </div>
        ),
      },
      {
        Header: t('Bookings'),
        accessor: (widget) => (
          <div className={styles['table-cell']}>
            <div className={styles['cell-base']}>
              {widget.base.conversions.toLocaleString()}
            </div>
            {widget.comparison && (
              <div className={styles['cell-comparison']}>
                {widget.comparison.conversions.toLocaleString()}
              </div>
            )}
          </div>
        ),
      },
      {
        Header: t('Conversion Rate'),
        accessor: (widget) => (
          <div className={styles['table-cell']}>
            <div className={styles['cell-base']}>
              {widget.base.conversionRate.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
              %
            </div>
            {widget.comparison && (
              <div className={styles['cell-comparison']}>
                {widget.comparison.conversionRate.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}
                %
              </div>
            )}
          </div>
        ),
      },
      {
        Header: t('Sales'),
        accessor: (widget) => (
          <div className={styles['table-cell']}>
            <div className={styles['cell-base']}>
              {currency(widget.base.sales, org?.default_currency)
                .format()
                .substring(3)}
            </div>
            {widget.comparison && (
              <div className={styles['cell-comparison']}>
                {currency(widget.comparison.sales).format().substring(3)}
              </div>
            )}
          </div>
        ),
      },
    ];
  }, [t]);

  const sortedGroupedWidgets: Record<number, SiteControlWidget[]> = _.fromPairs(
    _.sortBy(
      Object.entries(abTestPairs.groupedWidgets),
      ([key]) => -parseInt(key, 10) // Sorting in descending order
    )
  );

  if (loading) {
    return (
      <div className={styles['container']}>
        <div className={styles['container-inner']}>
          <div className={styles['header']}>
            <div className={styles['header-inner']}>
              <div>{t('AB Test')}</div>
            </div>
          </div>
          <div className={styles['loading']}>
            <Loading size="md" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles['container']}>
      <div className={styles['container-inner']}>
        <div className={styles['header']}>
          <div className={styles['header-inner']}>
            <div>{t('AB Test')}</div>
          </div>
        </div>
        <div className={styles['body']}>
          {Object.entries(sortedGroupedWidgets).map(([keyValue, widgets]) => {
            if (!widgets || widgets.length === 0) {
              return <></>;
            }

            const abTestKeyValue = `TEST_${keyValue}`;

            const abTestData = data.filter((widget) => {
              return widget.abTestPair === abTestKeyValue;
            });

            return (
              <div key={keyValue} className={styles['body-item']}>
                <div className={styles['body-item-header']}>
                  {getAbTestOptionText(t, abTestKeyValue)}
                </div>
                <TableSmall
                  items={data.filter(
                    (widget) => widget.abTestPair === abTestKeyValue
                  )}
                  columns={columns}
                  usePaging={false}
                />
                <div className={styles['body-item-button']}>
                  <StopButton
                    name={getAbTestOptionText(t, abTestKeyValue)}
                    widgets={widgets}
                    abTestData={abTestData}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
