import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { FORM_ERROR } from 'final-form';
import createDecorator from 'final-form-focus';

import { config } from 'client/config';
import baseStyles from 'client/base.module.css';
import type { ReduxState } from 'client/reducers';
import { Box } from 'client/components/Box/Box';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { Loading } from 'client/pages/Loading';
import { Message } from 'client/components/Message/Message';
import { ScrollToContext } from 'client/contexts/ScrollToContext';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { updateOrganization } from 'client/actions/organizations';
import { TextField } from 'client/components/v3/Form/TextField';
import { Button } from 'client/components/v3/Common/Button';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { fetchDigitalMaps } from 'client/actions/digitalMaps';

import styles from './DigitalMapSettings.module.css';
import {
  FormValues,
  getSettingsInitialValues,
  convertSettingsFormValuesToOrganizationPatch,
} from './FormValues';

const focusOnError: any = createDecorator();

export const DigitalMapSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const scrollTo = React.useContext(ScrollToContext);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const loading = useSelector(
    (state: ReduxState) => state.organizations.loading
  );
  const digitalMaps = useSelector((state: ReduxState) => state.digitalMaps.all);

  const [saveSucceeded, setSaveSucceeded] = React.useState<boolean>(false);

  const initialValues = React.useMemo(() => {
    return getSettingsInitialValues(activeUserOrganization);
  }, [activeUserOrganization]);

  React.useEffect(() => {
    dispatch(fetchDigitalMaps());
  }, [dispatch]);

  const mapOptions = digitalMaps.map((map) => ({
    text: map.name || '',
    value: map.id || '',
  }));

  return (
    <>
      <Form
        onSubmit={async (values: FormValues) => {
          const orgId = activeUserOrganization?.id || '';

          try {
            await dispatch(
              updateOrganization(
                orgId,
                'SUPPLIER',
                convertSettingsFormValuesToOrganizationPatch(values)
              )
            );
            scrollTo(0, 0);
            setSaveSucceeded(true);
          } catch (err) {
            return {
              [FORM_ERROR]: t('Save Failed'),
            };
          }
        }}
        decorators={[focusOnError]}
        initialValues={initialValues}
        mutators={{
          ...arrayMutators,
        }}
      >
        {({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <div className={baseStyles['base-main__body__box']}>
              <div className={baseStyles['base-main__body__box__body']}>
                {saveSucceeded && (
                  <Message success header={t('Settings Saved')} />
                )}
                <FormTableBox>
                  <table>
                    <tbody>
                      <tr>
                        <th>{t('Map Domain Settings')}</th>
                        <td>
                          <div className={styles['dns-instructions']}>
                            <div>
                              {t(
                                'Set up an A record with your DNS provider. If using a root domain:'
                              )}
                            </div>
                            <div>
                              <p>{`@    A    ${config.bookingWidgetIpAddress}`}</p>
                            </div>
                            <div>
                              <div>{t('If using a subdomain:')}</div>
                            </div>
                            <div>
                              <div>{`<subdomain>    A    ${config.bookingWidgetIpAddress}`}</div>
                            </div>
                            <div>
                              <a
                                className="c-button-link-sm-color"
                                href="https://moz.com/learn/seo/domain"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <p
                                  style={{ fontWeight: 'var(--text-semibold)' }}
                                >
                                  {t('What are subdomains and root domains?')}
                                </p>
                                <i className="c-icon-outline-general-link-external-02"></i>
                              </a>
                            </div>
                          </div>
                          <Field name="customDomain">
                            {({ input }) => (
                              <Box mt={2} display="flex" alignItems="center">
                                <TextField
                                  value={input.value}
                                  onChange={input.onChange}
                                  label={t('Custom Domain')}
                                />
                                {input.value && (
                                  <Box ml={2}>
                                    <a
                                      className="c-button-link-sm-color"
                                      href={`https://${input.value}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <i className="c-icon-outline-general-link-external-02" />
                                    </a>
                                  </Box>
                                )}
                              </Box>
                            )}
                          </Field>
                          <Box mt={4}>
                            <div className={styles['form-title']}>
                              {t('Custom Domain Paths')}
                            </div>
                            <FieldArray name="customDomainPaths">
                              {({ fields }) => (
                                <>
                                  {fields.map((name, index) => (
                                    <Box
                                      key={name}
                                      mt={2}
                                      display="flex"
                                      alignItems="center"
                                    >
                                      <Box>
                                        <Field name={`${name}.path`}>
                                          {({ input }) => (
                                            <TextField
                                              value={input.value}
                                              onChange={input.onChange}
                                              label={t('Path')}
                                            />
                                          )}
                                        </Field>
                                      </Box>
                                      <Box ml={2} width="240px">
                                        <Field name={`${name}.digitalMapId`}>
                                          {({ input }) => (
                                            <SingleDropdown
                                              label={t('Digital Map')}
                                              options={mapOptions}
                                              selectedOption={input.value}
                                              onChange={input.onChange}
                                            />
                                          )}
                                        </Field>
                                      </Box>
                                      {values.customDomain &&
                                        fields.value[index].path && (
                                          <Box ml={2}>
                                            <a
                                              className="c-button-link-sm-color"
                                              href={`https://${values.customDomain}/maps/${fields.value[index].path}`}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <i className="c-icon-outline-general-link-external-02" />
                                            </a>
                                          </Box>
                                        )}
                                      <Box>
                                        <a
                                          className={styles['delete-button']}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            fields.remove(index);
                                          }}
                                        >
                                          <i className="c-icon-outline-general-trash-03" />
                                        </a>
                                      </Box>
                                    </Box>
                                  ))}
                                  <Box mt={2}>
                                    <Button
                                      type="button"
                                      color="secondary"
                                      text={t('Add Path')}
                                      onClick={() =>
                                        fields.push({
                                          path: '',
                                          digitalMapId: '',
                                        })
                                      }
                                    />
                                  </Box>
                                </>
                              )}
                            </FieldArray>
                          </Box>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </FormTableBox>
                <div className={baseStyles['base-main__box__body__bottomBtns']}>
                  <Button
                    type="submit"
                    color="primary"
                    text={t('Save Settings')}
                    loading={submitting}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </Form>
      {loading && <Loading />}
    </>
  );
};
