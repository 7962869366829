import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './calendar-override.css';
import moment from 'moment-timezone';

import { ReduxState } from 'client/reducers';
import { LanguageISO } from 'shared/libraries/i18n';

import styles from './Calendar.module.css';

type Props = {
  label?: string;
  onChange: (startDate: string | null) => void;
  dateFrom: string;
  name?: string;
  locale?: LanguageISO;
  isClearable?: boolean;
};

export const SimpleDateInput = ({
  label,
  onChange,
  dateFrom,
  name,
  locale,
  isClearable,
}: Props) => {
  const localLocale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const fieldSetRef = useRef<HTMLFieldSetElement | null>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(
    dateFrom ? moment(dateFrom).toDate() : null
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      const isMouseClick = event.type === 'mousedown';
      const isTouchStart = event.type === 'touchstart';

      const target = isMouseClick
        ? (event as MouseEvent).target
        : (event as TouchEvent).target;

      if (isMouseClick || isTouchStart) {
        // Check if the target element is outside of the wrapper element
        if (
          fieldSetRef.current &&
          !fieldSetRef.current.contains(target as Node)
        ) {
          setIsClicked(false);
        }
      }
    };

    // Add event listeners to document for click outside
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      // Remove event listeners on cleanup
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  // Notify the calling component of changed date
  useEffect(() => {
    const formattedStartDate = startDate
      ? moment(startDate).format('YYYY-MM-DD')
      : null;
    onChange(formattedStartDate);
    // Close the calendar after date is changed
    setIsClicked(false);
  }, [startDate]);

  const dateFormatCalendar = locale === 'ja' ? 'yyyy年MM月' : undefined;

  const CustomInput = (
    <label>
      {label && (
        <legend className={styles['c-calendar__body__title']}>{label}</legend>
      )}
      <div className={styles['c-calendar__body__selected']}>
        <input type="text" value={dateFrom} readOnly></input>
      </div>
    </label>
  );

  return (
    <fieldset
      className={clsx(
        styles['c-calendar'],
        isHovered && styles['is-hover'],
        isClicked && styles['is-active'],
        (isClicked || dateFrom) && styles['is-input']
      )}
      ref={fieldSetRef}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => setIsClicked(true)}
    >
      <div
        className={clsx(
          styles['c-calendar__body'],
          'react-datepicker-override'
        )}
      >
        <DatePicker
          wrapperClassName={styles['c-calendar__wrapper']}
          customInput={CustomInput}
          selected={startDate}
          dateFormat="yyyy/MM/dd"
          onChange={(date) => setStartDate(date)}
          locale={locale ?? localLocale}
          dateFormatCalendar={dateFormatCalendar}
          open={isClicked} // Use this to open calendar if clicked outside of input field
          name={name}
          isClearable={isClearable}
        />
      </div>
    </fieldset>
  );
};
