import * as React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { fetchWaitTimes, updateWaitTime } from 'client/actions/waitTimes';
import { ReduxState } from 'client/reducers';
import { Loading } from 'client/components/v3/Common/Loading';

import styles from './WaitTimeManager.module.css';
import { WaitTimeNumericOption } from './WaitTimeNumericOption';
import { WaitTimeNonNumericOption } from './WaitTimeNonNumericOption';
import CustomWaitTime from './builder/CustomWaitTime/CustomWaitTime';

type WaitTimeOption = number | 'closed' | 'custom';

export const WaitTimeManager = () => {
  const [mode, setMode] = React.useState<'edit' | 'view'>('view');
  const [activeAttractionWaitTimeId, setActiveAttractionWaitTimeId] =
    React.useState<string | null>(null);
  const [selectedButton, setSelectedButton] =
    React.useState<WaitTimeOption | null>(null);
  const selectedWaitTimeRef = React.useRef<HTMLDivElement>(null);
  const [showCustomWaitTimeModal, setShowCustomWaitTimeModal] =
    React.useState<boolean>(false);
  const [customWaitTime, setCustomWaitTime] = React.useState<number | null>(
    null
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const waitTimes = useSelector((state: ReduxState) => state.waitTimes.all);
  const activeAttractionWaitTime = waitTimes.find(
    (waitTime) => waitTime.id === activeAttractionWaitTimeId
  );

  const waitTimeOptions: {
    value: WaitTimeOption;
    label: string;
  }[] = [
    { value: 0, label: t('min') },
    { value: 15, label: t('min') },
    { value: 30, label: t('min') },
    { value: 60, label: t('min') },
    {
      value: 'closed',
      label: t('Closed', {
        context: 'waitTimeManager',
      }),
    },
    { value: 'custom', label: t('Custom') },
  ];

  React.useEffect(() => {
    dispatch(fetchWaitTimes());
  }, [dispatch]);

  React.useEffect(() => {
    if (!activeAttractionWaitTimeId && waitTimes.length > 0) {
      setActiveAttractionWaitTimeId(waitTimes[0].id ?? null);
    }

    setCustomWaitTime(null);
  }, [waitTimes, activeAttractionWaitTimeId]);

  const loading = useSelector((state: ReduxState) => state.waitTimes.loading);

  React.useEffect(() => {
    if (mode === 'edit' && selectedWaitTimeRef.current) {
      selectedWaitTimeRef.current.scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
      });
    }
  }, [mode]);

  if (loading) {
    return (
      <main className={styles.container}>
        <Loading size="lg" />
      </main>
    );
  }

  if (mode === 'view') {
    return (
      <main className={styles.container}>
        <div className={styles['header']}>
          <h1 className={styles.title}>{t('Wait Time Manager')}</h1>

          <select
            className={styles.attractionSelector}
            value={activeAttractionWaitTimeId || ''}
            onChange={(e) => setActiveAttractionWaitTimeId(e.target.value)}
          >
            {waitTimes.map((waitTime) => (
              <option key={waitTime.id} value={waitTime.id}>
                {waitTime.attraction_name}
              </option>
            ))}
          </select>
        </div>
        <section className={styles.waitTimeSection}>
          <h2 className={styles.waitTimeLabel}>{t('Current Wait Time')}</h2>
          {activeAttractionWaitTime?.is_closed ? (
            <div className={styles.waitTimeDisplay}>
              <div className={styles.closedDisplay}>{t('Closed')}</div>
            </div>
          ) : (
            <div className={styles.waitTimeDisplay}>
              <div className={styles.waitTime}>
                {activeAttractionWaitTime?.wait_time_minutes ?? 0}
              </div>
              <div className={styles.timeUnit}>{t('min')}</div>
            </div>
          )}
        </section>
        <div className={styles.buttonContainer}>
          <button
            className={styles.updateButton}
            onClick={() => {
              setSelectedButton(null);
              setMode('edit');
            }}
          >
            {t('Update Wait Time')}
          </button>
        </div>
      </main>
    );
  }

  return (
    <main className={clsx(styles.container, styles.edit)}>
      <div className={styles['header']}>
        <h1 className={styles.title}>{t('Wait Time Manager')}</h1>
        <p className={styles.instruction}>
          {t('Please select new wait time.')}
        </p>
      </div>
      <section className={styles.optionsContainer}>
        {waitTimeOptions.map((option) =>
          typeof option.value === 'number' ? (
            <WaitTimeNumericOption
              key={option.value}
              value={option.value}
              isSelected={selectedButton === option.value}
              onClick={() => setSelectedButton(option.value)}
            />
          ) : option.value === 'custom' && customWaitTime != null ? (
            <WaitTimeNumericOption
              key={option.value}
              value={customWaitTime}
              isSelected={selectedButton === option.value}
              onClick={() => {
                setSelectedButton(option.value);
                setShowCustomWaitTimeModal(true);
              }}
            />
          ) : (
            <WaitTimeNonNumericOption
              key={option.value}
              text={option.label}
              isSelected={selectedButton === option.value}
              onClick={() => {
                if (option.value === 'custom') {
                  setShowCustomWaitTimeModal(true);
                }
                setSelectedButton(option.value);
              }}
            />
          )
        )}
      </section>
      <div className={styles.buttonContainer}>
        <button
          className={styles.updateButton}
          onClick={() => {
            if (selectedButton === 'closed') {
              dispatch(
                updateWaitTime(activeAttractionWaitTime?.id ?? '', {
                  ...activeAttractionWaitTime,
                  is_closed: true,
                })
              );
            } else if (selectedButton === 'custom' && customWaitTime != null) {
              dispatch(
                updateWaitTime(activeAttractionWaitTime?.id ?? '', {
                  ...activeAttractionWaitTime,
                  is_closed: false,
                  wait_time_minutes: customWaitTime,
                })
              );
            } else if (typeof selectedButton === 'number') {
              dispatch(
                updateWaitTime(activeAttractionWaitTime?.id ?? '', {
                  ...activeAttractionWaitTime,
                  is_closed: false,
                  wait_time_minutes: selectedButton,
                })
              );
            } else {
              throw new Error('Invalid selected button', {
                cause: selectedButton,
              });
            }
            setMode('view');
          }}
        >
          {t('Save')}
        </button>
        <button className={styles.cancelButton} onClick={() => setMode('view')}>
          {t('Cancel')}
        </button>
      </div>
      {showCustomWaitTimeModal && (
        <CustomWaitTime
          onSubmit={(value) => {
            setCustomWaitTime(value);
            setShowCustomWaitTimeModal(false);
          }}
          onClose={() => setShowCustomWaitTimeModal(false)}
        />
      )}
    </main>
  );
};
