import { FieldArray } from 'react-final-form-arrays';
import {
  Field,
  FieldRenderProps,
  useForm,
  useFormState,
} from 'react-final-form';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import React from 'react';

import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { Button } from 'client/components/v3/Common/Button';
import { TimePicker } from 'client/components/v3/Form/TimePicker/TimePicker';
import { WeekdaysInput } from 'client/components/v3/WeekdaysInput/WeekdaysInput';
import { SimpleDateInput } from 'client/components/v3/Form/Calendar/SimpleDateInput';

import styles from './SiteControlWidgetEditor.module.css';
import { FormValues } from './formValues';

export const ScheduleEditor = () => {
  const { t } = useTranslation();
  const formState = useFormState<FormValues>();
  const values = formState.values;
  const form = useForm();

  const scheduleOptions = [
    {
      text: t('Date'),
      value: 'DATE',
    },
    {
      text: t('Time'),
      value: 'TIME',
    },
    {
      text: t('Days of week'),
      value: 'DAYS_OF_WEEK',
    },
  ];

  return (
    <FieldArray name="schedules">
      {({ fields }) => (
        <>
          {fields.length !== 0 &&
            fields.map((fieldName, idx) => {
              return (
                <React.Fragment key={fieldName}>
                  <div className={styles['p-schedule__item']}>
                    <div className={styles['p-schedule__item__condition']}>
                      <div className={styles['p-schedule__item__type']}>
                        <Field name={`${fieldName}.type`}>
                          {({ input }: FieldRenderProps<string>) => (
                            <SingleDropdown
                              options={scheduleOptions}
                              selectedOption={input.value ?? 'DATE'}
                              onChange={(value) => {
                                input.onChange(value);
                                if (value === 'DATE') {
                                  form.change(
                                    `${fieldName}.startTime`,
                                    undefined
                                  );
                                  form.change(
                                    `${fieldName}.endTime`,
                                    undefined
                                  );
                                  form.change(
                                    `${fieldName}.daysOfWeek`,
                                    undefined
                                  );
                                } else if (value === 'TIME') {
                                  form.change(
                                    `${fieldName}.startTime`,
                                    '00:00'
                                  );
                                  form.change(`${fieldName}.endTime`, '00:00');
                                  form.change(
                                    `${fieldName}.startDate`,
                                    undefined
                                  );
                                  form.change(
                                    `${fieldName}.endDate`,
                                    undefined
                                  );
                                  form.change(
                                    `${fieldName}.daysOfWeek`,
                                    undefined
                                  );
                                } else if (value === 'DAYS_OF_WEEK') {
                                  form.change(
                                    `${fieldName}.startDate`,
                                    undefined
                                  );
                                  form.change(
                                    `${fieldName}.endDate`,
                                    undefined
                                  );
                                  form.change(
                                    `${fieldName}.startTime`,
                                    undefined
                                  );
                                  form.change(
                                    `${fieldName}.endTime`,
                                    undefined
                                  );
                                }
                              }}
                            />
                          )}
                        </Field>
                      </div>
                      {values.schedules &&
                        values.schedules?.length > 0 &&
                        values.schedules?.[idx].type === 'DATE' && (
                          <div
                            className={
                              styles['p-schedule__item__conditionDetails']
                            }
                          >
                            <Field name={`${fieldName}.startDate`}>
                              {({ input }) => (
                                <SimpleDateInput
                                  onChange={input.onChange}
                                  dateFrom={input.value}
                                  name={input.name}
                                  isClearable={true}
                                />
                              )}
                            </Field>
                            <p className={styles['p-schedule__date__p']}>-</p>
                            <Field name={`${fieldName}.endDate`}>
                              {({ input }) => (
                                <SimpleDateInput
                                  onChange={input.onChange}
                                  dateFrom={input.value}
                                  name={input.name}
                                  isClearable={true}
                                />
                              )}
                            </Field>
                          </div>
                        )}
                      {values.schedules &&
                        values.schedules?.length > 0 &&
                        values.schedules?.[idx].type === 'TIME' && (
                          <div
                            className={
                              styles['p-schedule__item__conditionDetails']
                            }
                          >
                            <Field name={`${fieldName}.startTime`}>
                              {({ input }) => (
                                <TimePicker
                                  allowEmpty={false}
                                  value={
                                    input.value
                                      ? moment(input.value, 'HH:mm')
                                      : moment('00:00', 'HH:mm')
                                  }
                                  onChange={(newValue) => {
                                    if (!newValue) {
                                      return;
                                    }
                                    input.onChange(newValue.format('HH:mm'));
                                  }}
                                />
                              )}
                            </Field>
                            <p className={styles['p-schedule__date__p']}>-</p>
                            <Field name={`${fieldName}.endTime`}>
                              {({ input }) => (
                                <TimePicker
                                  allowEmpty={false}
                                  value={
                                    input.value
                                      ? moment(input.value, 'HH:mm')
                                      : moment('00:00', 'HH:mm')
                                  }
                                  onChange={(newValue) => {
                                    if (!newValue) {
                                      return;
                                    }
                                    input.onChange(newValue.format('HH:mm'));
                                  }}
                                />
                              )}
                            </Field>
                          </div>
                        )}
                      {values.schedules &&
                        values.schedules?.length > 0 &&
                        values.schedules?.[idx].type === 'DAYS_OF_WEEK' && (
                          <div
                            className={
                              styles['p-schedule__item__conditionDetails']
                            }
                          >
                            <div className={styles['p-schedule__daysOfWeek']}>
                              <Field name={`${fieldName}.daysOfWeek`}>
                                {({ input }) => (
                                  <WeekdaysInput
                                    value={input.value}
                                    onChange={input.onChange}
                                  />
                                )}
                              </Field>
                            </div>
                          </div>
                        )}
                    </div>
                    <div className={styles['p-button__deleteCondition']}>
                      <Button
                        size="icon"
                        color="tertiarygray"
                        onClick={() => {
                          fields.remove(idx);
                        }}
                        iconBeforeText={
                          <i className="c-icon-outline-general-trash-03"></i>
                        }
                      />
                    </div>
                  </div>

                  {fields.length &&
                    fields.length > 1 &&
                    idx !== fields.length - 1 && (
                      <div className={styles['p-schedule__and']}>
                        {t('AND')}
                      </div>
                    )}
                </React.Fragment>
              );
            })}
          <div className={clsx(fields.length && fields.length > 0)}>
            <a
              className={styles['p-button__addCondition']}
              style={{
                height: fields.length === 0 ? '46px' : undefined,
              }}
              onClick={async () => {
                // Init empty schedule upon addition
                let index = 0;
                if (fields.length && fields.length > 0) {
                  index = fields.length;
                }
                (fields as any).insertAt(index, { type: 'DATE' });
              }}
            >
              <i className="c-icon-outline-general-plus-circle"></i>
              {t('Add Schedule')}
            </a>
          </div>
        </>
      )}
    </FieldArray>
  );
};
