import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { Loader } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';

import { ImageVideoAudioInput } from 'client/components/ImageVideoAudioInput/ImageVideoAudioInput';
import * as Swagger from 'shared/models/swagger';
import { Modal } from 'client/components/Modal/Modal';
import { bookingWidgetPMPSupportedLanguagesSelector } from 'client/reducers/user';
import { createInstantWin, updateInstantWin } from 'client/actions/instantWins';
import { getLanguageName } from 'client/libraries/i18n';
import { getArrayMutators } from 'client/libraries/util/form';
import { MessageModal } from 'client/components/MessageModal/MessageModal';
import { Box } from 'client/components/Box/Box';
import {
  Button,
  FieldWrapper,
  Input,
  TextArea,
  Select,
  DateRangeInput,
} from 'client/components/Form';

import { InstantWinDrawTriggerListEditor } from './InstantWinDrawTriggerListEditor';
import { InstantWinTierListEditor } from './InstantWinTierListEditor';
import {
  getInitialValues,
  convertFormValuesToSwagger,
  FormValues,
} from './formValues';

type Props = {
  existingInstantWin?: Swagger.InstantWin;
  open: boolean;
  onClose: () => void;
};

export const EditInstantWinModal = ({
  open,
  onClose,
  existingInstantWin,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [
    showPublishUnpublishConfirmModal,
    setShowPublishUnpublishConfirmModal,
  ] = React.useState(false);

  const initialValues = React.useMemo(
    () => getInitialValues(existingInstantWin),
    [existingInstantWin]
  );

  const supportedLanguages = useSelector(
    bookingWidgetPMPSupportedLanguagesSelector
  );

  const languageOptions = supportedLanguages.map((lang) => ({
    value: lang,
    text: getLanguageName(lang, t),
  }));

  const distributionTypeOptions = [
    {
      value: 'INSTANT_WIN_DISTRIBUTION_TYPE_QUANTITY',
      text: t('Quantity'),
    },
    {
      value: 'INSTANT_WIN_DISTRIBUTION_TYPE_PROBABILITY',
      text: t('Probability'),
    },
  ];

  return (
    <Modal
      title={t('Edit Instant Win')}
      open={open}
      onClose={onClose}
      insertRoot
      width="wide"
    >
      <Form
        initialValues={initialValues}
        onSubmit={async (values: FormValues) => {
          if (existingInstantWin) {
            await dispatch(
              updateInstantWin(
                existingInstantWin.id ?? '',
                convertFormValuesToSwagger(values)
              )
            );
          } else {
            await dispatch(
              createInstantWin(convertFormValuesToSwagger(values))
            );
          }

          onClose();
        }}
        keepDirtyOnReinitialize
        mutators={getArrayMutators()}
      >
        {({ handleSubmit, submitting, form, values }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Content>
              {submitting && (
                <Loader active={submitting}>{t('Loading')}</Loader>
              )}
              {showPublishUnpublishConfirmModal &&
                (values.status === 'INSTANT_WIN_ACTIVE' ? (
                  <MessageModal
                    open={true}
                    onClose={() => setShowPublishUnpublishConfirmModal(false)}
                    title={t('Unpublish Instant Win Page')}
                    message={t(
                      'Are you sure you wish to unpublish this instant win page?'
                    )}
                    onSubmit={() => {
                      form.change('status', 'INSTANT_WIN_INACTIVE');
                      handleSubmit();
                    }}
                  />
                ) : (
                  <MessageModal
                    open={true}
                    onClose={() => setShowPublishUnpublishConfirmModal(false)}
                    title={t('Publish Instant Win Page')}
                    message={t(
                      'Are you sure you wish to publish this instant win page?'
                    )}
                    onSubmit={() => {
                      form.change('status', 'INSTANT_WIN_ACTIVE');
                      handleSubmit();
                    }}
                  />
                ))}
              <Box mb={2} display="flex">
                <FieldWrapper label={t('Status')}>
                  {values.status === 'INSTANT_WIN_ACTIVE'
                    ? t('Published')
                    : t('Pending')}
                </FieldWrapper>

                <Box>
                  <Button.Create
                    onClick={() => setShowPublishUnpublishConfirmModal(true)}
                  >
                    {values.status === 'INSTANT_WIN_ACTIVE'
                      ? t('Save and Unpublish')
                      : t('Save and Publish')}
                  </Button.Create>
                </Box>
              </Box>

              <Box mb={2}>
                <Field name="dateRange">
                  {({ input }) => (
                    <DateRangeInput
                      label={t('Date Range')}
                      fromDate={input.value?.fromDate}
                      onChangeFromDate={(value) =>
                        input.onChange({
                          ...input.value,
                          fromDate: value,
                        })
                      }
                      toDate={input.value?.toDate}
                      onChangeToDate={(value) =>
                        input.onChange({
                          ...input.value,
                          toDate: value,
                        })
                      }
                    />
                  )}
                </Field>
              </Box>

              <Box mb={2}>
                <Field name="language">
                  {({ input }) => (
                    <Select
                      label={t('Language')}
                      options={languageOptions}
                      value={input.value}
                      onChange={(_, { value }) => input.onChange(value)}
                    />
                  )}
                </Field>
              </Box>
              <Field name="title">
                {({ input }) => (
                  <Input
                    label={t('Title')}
                    value={input.value}
                    onChange={(_, { value }) => input.onChange(value)}
                  />
                )}
              </Field>
              <Box mt={2}>
                <Field name="description">
                  {({ input }) => (
                    <TextArea
                      label={t('Description')}
                      value={input.value}
                      onChange={(_, { value }) => input.onChange(value)}
                    />
                  )}
                </Field>
              </Box>
              <Box mt={2}>
                <Field name="thumbnailUrl">
                  {({ input, meta: { touched, error } }) => (
                    <FieldWrapper label={t('Thumbnail Image (jpg, png)')}>
                      <ImageVideoAudioInput
                        fileUrls={input.value ? [input.value] : []}
                        onChange={(newValue) =>
                          newValue.length > 0
                            ? input.onChange(newValue[0])
                            : input.onChange('')
                        }
                        maxFileCount={1}
                        disableYoutubeVideos
                        error={touched && error}
                      />
                    </FieldWrapper>
                  )}
                </Field>
              </Box>

              <Box mb={2}>
                <Field name="distributionType">
                  {({ input }) => (
                    <Select
                      label={t('Distribution Type')}
                      options={distributionTypeOptions}
                      value={input.value}
                      onChange={(_, { value }) => input.onChange(value)}
                    />
                  )}
                </Field>
              </Box>

              <InstantWinDrawTriggerListEditor />
              <InstantWinTierListEditor />
            </Modal.Content>
            <Modal.Actions>
              <Button
                disabled={values.editorJsIsLoading}
                loading={submitting}
                size="middle"
                style="blue"
                type="submit"
              >
                {t('Save')}
              </Button>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
