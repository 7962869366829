import * as Swagger from 'shared/models/swagger';

export type CustomDomainPath = {
  path: string;
  digitalMapId: string;
};

export type FormValues = {
  customDomain: string;
  customDomainPaths: CustomDomainPath[];
};

export const getSettingsInitialValues = (
  organization: Swagger.Organization | null
): FormValues => {
  return {
    customDomain: organization?.digital_map_settings?.custom_domain ?? '',
    customDomainPaths:
      organization?.digital_map_settings?.custom_domain_paths?.map((path) => ({
        path: path.path ?? '',
        digitalMapId: path.digital_map_id ?? '',
      })) ?? [],
  };
};

export const convertSettingsFormValuesToOrganizationPatch = (
  values: FormValues
): Swagger.Organization$Patch => {
  return {
    digital_map_settings: {
      custom_domain: values.customDomain,
      custom_domain_paths: values.customDomainPaths.map((path) => ({
        path: path.path,
        digital_map_id: path.digitalMapId,
      })),
    },
  };
};
