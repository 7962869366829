import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import type { TFunction } from 'react-i18next';
import _ from 'lodash';

import baseStyles from 'client/base.module.css';
import { Box } from 'client/components/Box/Box';
import { Button } from 'client/components/Form';
import { CustomTable } from 'client/components/CustomTable/CustomTable';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';
import { Edit as EditIcon } from 'client/components/Icons/Edit';
import { InstantWin } from 'shared/models/swagger';
import { ReduxState } from 'client/reducers';
import { Tabs } from 'client/components/Tabs/Tabs';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { deleteInstantWin, fetchInstantWins } from 'client/actions/instantWins';
import { fetchProducts } from 'client/actions/products';
import { getLanguageName } from 'client/libraries/i18n';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';

import { TotalStats } from './TotalStats';
import { TiersStats } from './TiersStats';
import { EditInstantWinModal } from './EditInstantWinModal';

export const InstantWinList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const instantWins = useSelector((state: ReduxState) => state.instantWins.all);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  const [showEditModal, setShowEditModal] = React.useState(false);
  const [activeTabIndex, setActiveTabIndex] = React.useState<number | null>(0);

  const columns = React.useMemo(
    () => getColumns(t, activeTabIndex),
    [activeTabIndex, activeUserOrganization]
  );

  React.useEffect(() => {
    dispatch(fetchProducts());
    dispatch(fetchInstantWins());
  }, [showEditModal, activeTabIndex, activeUserOrganization]);

  const tabPanes = React.useMemo(() => {
    const publishedPages = _.orderBy(
      _.orderBy(
        instantWins.filter((p) => p.status === 'INSTANT_WIN_ACTIVE'),
        (g) => g?.page_design_contents?.title
      ),
      (g) => g.language
    );
    const pendingPages = _.orderBy(
      _.orderBy(
        instantWins.filter((p) => p.status !== 'INSTANT_WIN_ACTIVE'),
        (g) => g?.page_design_contents?.title
      ),
      (g) => g.language
    );
    return [
      {
        header: t('Pending'),
        content: () =>
          pendingPages.length === 0 ? (
            <div className={baseStyles['base-form-box__err']}>
              {t('No Pending Guidance Pages Found')}
            </div>
          ) : (
            <CustomTable items={pendingPages} columns={columns} />
          ),
      },
      {
        header: t('Published'),
        content: () =>
          publishedPages.length === 0 ? (
            <div className={baseStyles['base-form-box__err']}>
              {t('No Published Guidance Pages Found')}
            </div>
          ) : (
            <CustomTable items={publishedPages} columns={columns} />
          ),
      },
    ];
  }, [t, instantWins, columns]);
  return (
    <div>
      <Box display="flex">
        <Button
          size="middle"
          style="green"
          onClick={() => setShowEditModal(true)}
        >
          {t('Create New Instant Win')}
        </Button>
        {showEditModal && (
          <EditInstantWinModal
            open={showEditModal}
            onClose={() => setShowEditModal(false)}
          />
        )}
      </Box>

      <Box mt={2}>
        <Tabs
          panes={tabPanes}
          activeIndex={activeTabIndex}
          onActiveIndexChange={setActiveTabIndex}
        />
      </Box>
    </div>
  );
};

export type ColumnType<T> = {
  Header: string;
  translatedColumnName?: string;
  id?: string;
  accessor?: keyof T | ((row: T) => string);
  width?: 'short' | 'middle' | 'long' | string;
  Cell?: (cellInfo: { original: T }) => any;
  th?: boolean;
};

const getColumns = (
  t: TFunction,
  activeTabIndex: number | null
): ColumnType<InstantWin>[] => {
  const columns: ColumnType<InstantWin>[] = [
    {
      Header: '',
      th: true,
      width: 'minimal',
      Cell: (cellInfo) => (
        <EditDeleteCell existingInstantWin={cellInfo.original} />
      ),
    },
    {
      Header: t('Title'),
      width: 'minimal',
      accessor: (row) => row.page_design_contents?.title ?? '',
    },
    {
      Header: t('Language'),
      width: 'minimal',
      accessor: (row) =>
        getLanguageName(
          uppercaseIsoToLowercaseIso[
            row.language as keyof typeof uppercaseIsoToLowercaseIso
          ],
          t
        ) ?? '',
    },
    {
      Header: t('Description'),
      accessor: (row) => row.page_design_contents?.description ?? '',
    },
    {
      Header: t('Usage Rate'),
      Cell: (cellInfo) => (
        <TotalStats
          instantWinId={cellInfo.original.id ?? ''}
          activeTabIndex={activeTabIndex}
        />
      ),
    },
    {
      Header: t('Tier Usage Rate'),
      Cell: (cellInfo) => (
        <TiersStats
          instantWin={cellInfo.original}
          activeTabIndex={activeTabIndex}
        />
      ),
    },
  ];

  return columns;
};

const EditDeleteCell = ({
  existingInstantWin,
}: {
  existingInstantWin: InstantWin;
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [showEditModal, setShowEditModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  return (
    <Box display="flex" alignItems="center">
      {showEditModal && (
        <EditInstantWinModal
          existingInstantWin={existingInstantWin}
          open={showEditModal}
          onClose={() => setShowEditModal(false)}
        />
      )}
      <EditIcon onClick={() => setShowEditModal(true)} />

      {showDeleteModal && (
        <DeleteConfirmModal
          header={t('Delete instant win setting')}
          content={t('Are you sure you want to delete instant win setting?')}
          onConfirm={async () => {
            await dispatch(deleteInstantWin(existingInstantWin?.id ?? ''));
          }}
          onClose={() => setShowDeleteModal(false)}
          open={showDeleteModal}
          insertRoot={true}
        />
      )}

      <Box ml={2}>
        <DeleteIcon onClick={() => setShowDeleteModal(true)} />
      </Box>
    </Box>
  );
};
