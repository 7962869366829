import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  setInstantWinEventRowCount,
  setInstantWinEventCurrentPage,
} from 'client/actions/instantWinEventTableControls';
import {
  fetchInstantWinEvents,
  updateInstantWinEventListPageTokens,
} from 'client/actions/instantWinEvents';
import type { ReduxState } from 'client/reducers';
import { GenericTable } from 'client/components/GenericTable/GenericTable';
import { InstantWin, InstantWinEvent } from 'shared/models/swagger';
import { Modal } from 'client/components/Modal/Modal';
import { getInstantWinEventColumns } from 'client/components/InstantWinEventTable/util';

interface Props {
  instantWin: InstantWin;
  tierKey: string;
  open: boolean;
  onClose: () => void;
}

export const InstantWinEventListModal = ({
  instantWin,
  tierKey,
  open,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const totalSize = useSelector(
    (state: ReduxState) => state.instantWinEvents.totalSize
  );
  const pageTokens = useSelector(
    (state: ReduxState) => state.instantWinEvents.pageTokens
  );
  const currentPage = useSelector(
    (state: ReduxState) => state.instantWinEventTableControls.currentPage
  );
  const rowCount = useSelector(
    (state: ReduxState) => state.instantWinEventTableControls.rowCount
  );
  const instantWinEvents = useSelector(
    (state: ReduxState) => state.instantWinEvents.all
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const columns = getInstantWinEventColumns(t, locale);

  React.useEffect(() => {
    if (!open) {
      return;
    }
    dispatch(
      fetchInstantWinEvents({
        instant_win_id: instantWin.id,
        filter: `tier_key=${tierKey}`,
      })
    );
  }, [instantWin.id, tierKey, open]);

  const handleOnClickPrevious = () => {
    dispatch(
      updateInstantWinEventListPageTokens({
        allTokens: pageTokens.allTokens,
        currToken: pageTokens.allTokens[pageTokens.currToken].previous,
        indexToToken: pageTokens.indexToToken,
      })
    );

    dispatch(
      fetchInstantWinEvents({
        instant_win_id: instantWin.id,
        filter: `tier_key=${tierKey}`,
      })
    );
  };

  // this value is used in fetchCustomerList
  const handleOnClickNext = () => {
    const allTokens = pageTokens.allTokens;
    const nextToken = pageTokens.allTokens[pageTokens.currToken].next;
    if (nextToken != null) {
      allTokens[nextToken] = {
        previous: pageTokens.currToken,
        next: null,
      };
      dispatch(
        updateInstantWinEventListPageTokens({
          allTokens: allTokens,
          currToken: nextToken,
          indexToToken: pageTokens.indexToToken,
        })
      );
      dispatch(
        fetchInstantWinEvents({
          instant_win_id: instantWin.id,
          filter: `tier_key=${tierKey}`,
        })
      );
    }
  };

  return (
    <Modal title={t('Event List')} open={open} onClose={onClose} width={'wide'}>
      <Modal.Content>
        <GenericTable<InstantWinEvent>
          items={instantWinEvents}
          totalCount={totalSize}
          columns={columns}
          rowCount={rowCount}
          currentPage={currentPage}
          onRowCountChange={(rowCount: number) => {
            dispatch(setInstantWinEventRowCount(rowCount));
            dispatch(
              fetchInstantWinEvents({
                instant_win_id: instantWin.id,
                filter: `tier_key=${tierKey}`,
              })
            );
          }}
          onCurrentPageChange={(newCurrentPage: number) => {
            if (newCurrentPage < currentPage) {
              handleOnClickPrevious();
            } else {
              handleOnClickNext();
            }
            dispatch(setInstantWinEventCurrentPage(newCurrentPage));
          }}
        />
      </Modal.Content>
    </Modal>
  );
};
