import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useForm } from 'react-final-form';
import { useSelector } from 'react-redux';

import {
  FieldWrapper,
  Input,
  Select,
  TextArea,
  ToggleButton,
} from 'client/components/Form';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { customPagesSelector } from 'client/reducers/customPages';
import { fetchCustomPages } from 'client/actions/customPage';
import { productOptionsSelector } from 'client/reducers/products';
import { Box } from 'client/components/Box/Box';
import { EnumRadioButtonGroup } from 'client/components/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { getValidators } from 'shared/libraries/validate/validator';
import { ImageVideoAudioInput } from 'client/components/ImageVideoAudioInput/ImageVideoAudioInput';
import { digitalMapsSelector } from 'client/reducers/digitalMaps';
import { fetchDigitalMaps } from 'client/actions/digitalMaps';

export const MessagePopupEditor = () => {
  React.useEffect(() => {
    fetchCustomPages();
    fetchDigitalMaps();
  }, []);

  const { t } = useTranslation();
  const form = useForm();
  const values = form.getState().values;

  const { required } = getValidators(t);

  const customPages = useSelector(customPagesSelector);
  const organization = useSelector(activeUserOrganizationSelector);
  const pmp = Boolean((organization?.private_marketplaces || []).length > 0);
  const productOptions = useSelector(productOptionsSelector);
  const digitalMaps = useSelector(digitalMapsSelector);

  const buttonDestinationTypeOptions = [
    {
      key: 'CUSTOM_PAGE',
      value: 'CUSTOM_PAGE',
      label: t('Select a page from Homepages'),
    },
    {
      key: 'PRODUCT_PAGE',
      value: 'PRODUCT_PAGE',
      label: t('Select a product'),
    },
    {
      key: 'DIGITAL_MAP_PIN_DETAILS_PAGE',
      value: 'DIGITAL_MAP_PIN_DETAILS_PAGE',
      label: t('Select a digital map pin'),
    },
    {
      key: 'FULL_URL',
      value: 'FULL_URL',
      label: t('Enter URL directly'),
    },
  ];
  const customPageOptions = [
    {
      text: t('About Us'),
      key: '/about',
      value: '/about',
    },
    {
      text: t('FAQ'),
      key: '/faq',
      value: '/faq',
    },
    {
      text: t('COVID-19 Guidelines'),
      key: '/covid19',
      value: '/covid19',
    },
    ...customPages.map((customPage) => ({
      text: customPage.title,
      key: `/article/${customPage.path}`,
      value: `/article/${customPage.path}`,
    })),
  ];
  const productLinkOptions = productOptions.map((option) => ({
    key: pmp ? `/top/products/${option.value}` : `/products/${option.value}`,
    value: pmp ? `/top/products/${option.value}` : `/products/${option.value}`,
    text: option.text,
  }));
  const digitalMapPinLinkOptions: { value: string; text: string }[] =
    React.useMemo(() => {
      return digitalMaps
        .filter((digitalMap) => digitalMap.pins?.length)
        .map((digitalMap) =>
          digitalMap.pins?.map((pin) => ({
            value: `/maps/${digitalMap.id}?pin=${pin.key}&pind=DETAILS`,
            text: `${digitalMap.name}: ${pin.title}`,
          }))
        )
        .flat() as { value: string; text: string }[];
    }, [digitalMaps]);

  /*
          messageTitle: string;
      messageDescription: string;
      messageButtonText: string;
      messageButtonDestinationType:
        | 'PRODUCT_PAGE'
        | 'CUSTOM_PAGE'
        | 'FULL_URL'
        | 'PRIVATE_MARKETPLACE';
      messageButtonDestinationUrl: string;
      messageButtonDestinationPagePath: string;
    */

  return (
    <div>
      <Field name="thumbnailUrl">
        {({ input }) => (
          <FieldWrapper label={t('Thumbnail image (jpg, png)')}>
            <ImageVideoAudioInput
              fileUrls={input.value ? [input.value] : []}
              onChange={(newValue) =>
                newValue?.length
                  ? input.onChange(newValue[0])
                  : input.onChange('')
              }
              maxFileCount={1}
              disableYoutubeVideos
            />
          </FieldWrapper>
        )}
      </Field>
      <Field name="messageTitle">
        {({ input }) => (
          <Input
            label={t('Message Title')}
            value={input.value}
            onChange={(e, { value }) => input.onChange(value)}
          />
        )}
      </Field>
      <Field name="messageDescription">
        {({ input }) => (
          <TextArea
            label={t('Message Description')}
            value={input.value}
            onChange={(e, { value }) => input.onChange(value)}
          />
        )}
      </Field>
      <Field name="hideButton" type="checkbox">
        {({ input }) => (
          <Box mb={2} mt={2}>
            <ToggleButton
              label={t('Hide Button')}
              checked={input.checked}
              onChange={() => {
                input.onChange(!input.checked);
              }}
            />
          </Box>
        )}
      </Field>
      {!values?.hideButton && (
        <Box mb={2}>
          <FieldWrapper label={t('URL on button click')}>
            <EnumRadioButtonGroup
              name="messageButtonDestinationType"
              options={buttonDestinationTypeOptions}
              onChange={(value) => {
                if (value === 'FULL_URL') {
                  form.change('messageButtonDestinationPagePath', '');
                  form.change('messageButtonDestinationUrl', '');
                } else if (value === 'PRIVATE_MARKETPLACE') {
                  form.change(
                    'messageButtonDestinationPagePath',
                    '/top/popular'
                  );
                  form.change('messageButtonDestinationUrl', '');
                } else if (value === 'CUSTOM_PAGE') {
                  form.change('messageButtonDestinationPagePath', '/about');
                  form.change('messageButtonDestinationUrl', '');
                } else if (value === 'PRODUCT_PAGE') {
                  form.change(
                    'messageButtonDestinationPagePath',
                    productOptions.length > 0 ? productOptions[0].value : ''
                  );
                  form.change('messageButtonDestinationUrl', '');
                } else if (value === 'DIGITAL_MAP_PIN_DETAILS_PAGE') {
                  form.change(
                    'messageButtonDestinationPagePath',
                    digitalMapPinLinkOptions.length > 0
                      ? digitalMapPinLinkOptions[0]?.value
                      : ''
                  );
                  form.change('messageButtonDestinationUrl', '');
                }
              }}
            />
            {values?.messageButtonDestinationType === 'CUSTOM_PAGE' && (
              <Field name="messageButtonDestinationPagePath">
                {({ input, meta: { touched, error } }) => (
                  <Select
                    options={customPageOptions}
                    error={touched && error}
                    value={input.value}
                    onChange={(e, { value }) => {
                      input.onChange(value);
                    }}
                  />
                )}
              </Field>
            )}
            {values?.messageButtonDestinationType === 'PRODUCT_PAGE' && (
              <Field name="messageButtonDestinationPagePath">
                {({ input, meta: { touched, error } }) => (
                  <Select
                    options={productLinkOptions}
                    error={touched && error}
                    value={input.value}
                    onChange={(e, { value }) => input.onChange(value)}
                  />
                )}
              </Field>
            )}
            {values?.messageButtonDestinationType ===
              'DIGITAL_MAP_PIN_DETAILS_PAGE' && (
              <Field name="messageButtonDestinationPagePath">
                {({ input, meta: { touched, error } }) => (
                  <Select
                    search
                    options={digitalMapPinLinkOptions}
                    error={touched && error}
                    value={input.value}
                    onChange={(e, { value }) => input.onChange(value)}
                  />
                )}
              </Field>
            )}
            {values?.messageButtonDestinationType === 'FULL_URL' && (
              <Field name="messageButtonDestinationUrl" validate={required}>
                {({ input, meta: { touched, error } }) => (
                  <Input error={touched && error} {...input} />
                )}
              </Field>
            )}
          </FieldWrapper>
        </Box>
      )}
    </div>
  );
};
