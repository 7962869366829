import moment from 'moment-timezone';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';

import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import type { ReduxState } from 'client/reducers';
import type { ResourceType } from 'client/libraries/util/resourceType';
import { Button } from 'client/components/v3/Common/Button';
import { ResourceAssignmentModal } from 'client/pages/v3/Manifest/ManifestResourceAssignment/Modal/ResourceAssignmentModal';
import { StartTimeManifestReservationsTableList } from 'client/pages/v3/Manifest/ManifestDaily/ManifestDailyContents/StartTimeManifestReservationsTableList';
import { Tooltip } from 'client/components/v3/Common/Tooltip';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { fetchManifestPDF, fetchManifestCSV } from 'client/actions/manifests';
import { ManifestActiveResourceContext } from 'client/contexts/ManifestActiveResourceContext';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { fetchResourceAssignments } from 'client/actions/resourceAssignments';
import { manifestExcludedFormFieldsSelector } from 'client/reducers/manifestSettings';
import type {
  ManifestExportParams,
  ReservationColumn,
  ManifestCustomizedColumnName,
  ProductSummary,
} from 'shared/models/swagger';
import styles from 'client/pages/v3/Manifest/ManifestResourceAssignment/ManifestResourceAssignment.module.css';
import dailyStyles from 'client/pages/v3/Manifest/ManifestDaily/ManifestDaily.module.css';
import { SendEmailModal } from 'client/pages/v3/Manifest/ManifestDaily/Modal/SendEmailModal';

type Props = {
  loading: boolean;
  participationDate: string;
  reservations: ManifestReservationShape[];
  resourceKey: string;
  resourceType: ResourceType;
  groupBy: 'START_TIME' | 'TRANSPORT_ROUTE' | 'PICKUP_TIME';
  visibleColumns: ReservationColumn[];
  title: string;
  open?: boolean;
  capacity?: number;
  customizedColumnNames?: ManifestCustomizedColumnName[];
  orderByColumns: ReservationColumn[];
};

const getManifestExportDispatchFilterFieldName = (
  resourceType: ResourceType
):
  | 'dispatch_vehicles'
  | 'dispatch_crew'
  | 'dispatch_misc_resources'
  | 'dispatch_guides' => {
  if (resourceType === 'vehicle') {
    return 'dispatch_vehicles';
  } else if (resourceType === 'crew') {
    return 'dispatch_crew';
  } else if (resourceType === 'guide') {
    return 'dispatch_guides';
  }

  return 'dispatch_misc_resources';
};

export const ResourceListItem = ({
  participationDate,
  reservations,
  resourceKey,
  resourceType,
  groupBy,
  visibleColumns,
  title,
  capacity,
  customizedColumnNames,
  orderByColumns,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const csvStatus = useSelector(
    (state: ReduxState) => state.manifests.csvStatus
  );
  const pdfStatus = useSelector(
    (state: ReduxState) => state.manifests.pdfStatus
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const manifestEmailStatus = useSelector(
    (state: ReduxState) => state.manifests.emailStatus
  );
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const activeUser = useSelector(activeUserSelector);
  const [showDownloadMenu, setShowDownloadMenu] = useState<boolean>(false);
  const fieldSetRef = useRef<HTMLFieldSetElement | null>(null);
  const [openSendEmailModal, setOpenSendEmailModal] = useState<boolean>(false);
  const [openResourceAssignmentModal, setOpenResourceAssignmentModal] =
    useState<boolean>(false);
  const productSummaries = useSelector(
    (state: ReduxState) => state.products.summaries
  );

  useEffect(() => {
    const handleClickOutside = ({ target }: Event) => {
      if (
        showDownloadMenu &&
        target instanceof Node &&
        !fieldSetRef?.current?.contains(target)
      ) {
        setShowDownloadMenu(false);
      }
    };

    // Add event listeners to document for click outside
    window.document.addEventListener('mousedown', handleClickOutside);
    window.document.addEventListener('touchstart', handleClickOutside);

    return () => {
      // Remove event listeners on cleanup
      window.document.removeEventListener('mousedown', handleClickOutside);
      window.document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [showDownloadMenu]);

  const products: ProductSummary[] = [];
  reservations.forEach((reservation) => {
    const product = productSummaries.find(
      (p) => p.id === reservation.product_id
    );
    if (product) {
      products.push(product);
    }
  });

  const formattedDate = moment(participationDate).locale(locale).format('LL');
  // Special rules apply when 'TRANSPORT_ROUTE' is selected for dispatch:
  //
  // Resource Case A: resource is assigned to at least one transport route
  // 1) Reservations are expanded into separate copies for each leg of the transport route.
  // 2) These expanded reservations are sorted by transport arrival time.
  // 3) For each resource, reservations are shown only if that resource is assigned to reservation's transport
  //    route leg.
  //
  // Resource Case B: resource is only assigned to reservations not transport routes
  // 1) Reservations are shown with empty transport route info
  // 2) Reservations are grouped by pickup time
  const shouldShowTransportRouteAssignmentFormat = (
    visibleColumns ?? []
  ).includes('TRANSPORT_ROUTE');
  const manifestExportRequest: ManifestExportParams = {
    date: participationDate,
    columns: visibleColumns,
    order_by_columns: orderByColumns,
    customized_column_names: customizedColumnNames,
    should_show_transport_route_assignment_format:
      shouldShowTransportRouteAssignmentFormat,
  };
  manifestExportRequest[
    getManifestExportDispatchFilterFieldName(resourceType)
  ] = [resourceKey];
  let description = null;

  const excludedFormFieldKeys = useSelector(manifestExcludedFormFieldsSelector);

  if (resourceType === 'guide') {
    const guide = (activeUserOrganization?.guides || []).find(
      (guide) => guide.id === resourceKey
    );
    description = guide?.description;
  } else if (resourceType === 'crew') {
    const crew = (
      activeUserOrganization?.dispatch_settings?.dispatch_crew_members || []
    ).find((crew) => crew.key === resourceKey);
    description = crew?.description;
  } else if (resourceType === 'vehicle') {
    const vehicle = (
      activeUserOrganization?.dispatch_settings?.dispatch_vehicles || []
    ).find((vehicle) => vehicle.key === resourceKey);
    description = vehicle?.description;
  } else {
    const other = (
      activeUserOrganization?.dispatch_settings?.dispatch_misc_resources || []
    ).find((other) => other.key === resourceKey);
    description = other?.description;
  }
  useEffect(() => {
    if (resourceType === 'other') {
      dispatch(
        fetchResourceAssignments(
          'RESOURCE_TYPE_OTHER',
          resourceKey,
          moment
            .tz(
              participationDate,
              activeUserOrganization?.default_timezone ?? 'UTC'
            )
            .utc()
            .format(),
          moment
            .tz(
              participationDate,
              activeUserOrganization?.default_timezone ?? 'UTC'
            )
            .add(1, 'day')
            .utc()
            .format()
        )
      );
    }
  }, [participationDate, resourceType]);

  const getResourceTypeIcon = (resourceType: ResourceType) => {
    switch (resourceType) {
      case 'vehicle':
        return <i className="c-icon-solid-maps-bus"></i>;
      case 'crew':
        return <i className="c-icon-solid-users-user-circle"></i>;
      case 'other':
        return <i className="c-icon-solid-time-calendar"></i>;
      case 'guide':
        return <i className="c-icon-solid-users-user-circle"></i>;
      default:
        return <i className="c-icon-solid-time-calendar"></i>;
    }
  };

  return (
    <>
      <div className={dailyStyles['p-manifests__actions']}>
        <div className={dailyStyles['p-manifests__actions__left']}>
          <div className={styles['p-assignments__ttl']}>
            {getResourceTypeIcon(resourceType)}
            <p> {title} </p>
            {reservations.length === 0 && (
              <div>{t('No reservations assigned.')}</div>
            )}
            {description && (
              <Tooltip text={description}>
                <i className="c-icon-outline-general-info-circle"></i>
              </Tooltip>
            )}
          </div>
        </div>

        <div className={dailyStyles['p-manifests__actions__right']}>
          <fieldset
            ref={fieldSetRef}
            className={dailyStyles['p-manifests__select']}
          >
            <Button
              text={t('Download')}
              color="white"
              onClick={() => {
                setShowDownloadMenu(!showDownloadMenu);
              }}
              iconBeforeText={
                <i className="c-icon-outline-general-download-02"></i>
              }
              iconAfterText={
                <i className="c-icon-outline-arrows-chevron-selector-vertical"></i>
              }
              loading={pdfStatus === 'IN_FLIGHT' || csvStatus === 'IN_FLIGHT'}
            />
            <ul
              className={clsx(
                dailyStyles['p-manifests__select__menu'],
                showDownloadMenu && dailyStyles['is-active']
              )}
            >
              <li className={dailyStyles['p-manifests__select__menu__item']}>
                <p
                  onClick={() => {
                    setShowDownloadMenu(false);
                    dispatch(fetchManifestPDF(manifestExportRequest));
                  }}
                >
                  {t('Download as PDF')}
                </p>
              </li>
              <li className={dailyStyles['p-manifests__select__menu__item']}>
                <p
                  onClick={() => {
                    setShowDownloadMenu(false);
                    const manifestExportRequestForCSV: ManifestExportParams = {
                      ...manifestExportRequest,
                      columns: [
                        'START_TIME',
                        ...(manifestExportRequest.columns || []),
                      ],
                      order_by_columns: [
                        'START_TIME',
                        ...(manifestExportRequest.order_by_columns || []),
                      ],
                    };
                    dispatch(fetchManifestCSV(manifestExportRequestForCSV));
                  }}
                >
                  {t('Download as CSV')}
                </p>
              </li>
            </ul>
          </fieldset>
          <Button
            text={t('Send Emails')}
            size="md"
            color="white"
            onClick={() => {
              setOpenSendEmailModal(true);
            }}
            loading={manifestEmailStatus === 'IN_FLIGHT'}
          />
          {hasCustomUserRoleWritePermissions(
            activeUser,
            'MANIFEST.RESOURCE_ASSIGNMENT'
          ) && (
            <div>
              <Button
                text={t('Assign Resource')}
                color="primary"
                iconBeforeText={
                  <i className="c-icon-outline-general-edit-05"></i>
                }
                onClick={() => setOpenResourceAssignmentModal(true)}
              />
              <ResourceAssignmentModal
                open={openResourceAssignmentModal}
                onClose={() => {
                  setOpenResourceAssignmentModal(false);
                }}
                resourceType={resourceType}
                resourceKey={resourceKey}
                title={title}
                customizedColumnNames={customizedColumnNames}
                visibleColumns={visibleColumns}
              />
            </div>
          )}

          {openSendEmailModal && (
            <SendEmailModal
              defaultSubject={
                formattedDate
                  ? t('Manifest - {{formattedDate}}', {
                      formattedDate,
                    })
                  : t('Manifest')
              }
              open={openSendEmailModal}
              onClose={() => {
                setOpenSendEmailModal(false);
              }}
              manifestExportParams={manifestExportRequest}
            />
          )}
        </div>
      </div>

      {reservations.length > 0 && (
        <>
          <div className={dailyStyles['p-manifests__section']}>
            <ManifestActiveResourceContext.Provider
              value={{
                activeResourceType: resourceType,
                activeResourceKey: resourceKey,
                shouldShowTransportRouteAssignmentFormat,
              }}
            >
              <StartTimeManifestReservationsTableList
                excludedFormFieldKeys={excludedFormFieldKeys}
                reservations={reservations}
                products={products}
                visibleColumns={visibleColumns}
                capacity={capacity}
                showTimeOnly={true}
                resourceKey={resourceKey}
                resourceType={resourceType}
                customizedColumnNames={customizedColumnNames}
                groupBy={groupBy}
                insideGroup={true}
              />
            </ManifestActiveResourceContext.Provider>
          </div>
        </>
      )}
    </>
  );
};
