import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_EQUIPMENT_ASSIGNMENTS_SUCCESS,
  FETCH_EQUIPMENT_ASSIGNMENTS_FAILURE,
  FETCH_EQUIPMENT_ASSIGNMENTS_REQUEST,
  DELETE_EQUIPMENT_ASSIGNMENT_FAILURE,
  DELETE_EQUIPMENT_ASSIGNMENT_REQUEST,
  DELETE_EQUIPMENT_ASSIGNMENT_SUCCESS,
  BULK_OPERATE_EQUIPMENT_ASSIGNMENTS_FAILURE,
  BULK_OPERATE_EQUIPMENT_ASSIGNMENTS_REQUEST,
  BULK_OPERATE_EQUIPMENT_ASSIGNMENTS_SUCCESS,
  BULK_ASSIGN_EQUIPMENT_ASSIGNMENTS_FAILURE,
  BULK_ASSIGN_EQUIPMENT_ASSIGNMENTS_REQUEST,
  BULK_ASSIGN_EQUIPMENT_ASSIGNMENTS_SUCCESS,
  BATCH_FETCH_EQUIPMENT_ASSIGNMENTS_SUCCESS,
  BATCH_FETCH_EQUIPMENT_ASSIGNMENTS_FAILURE,
  BATCH_FETCH_EQUIPMENT_ASSIGNMENTS_REQUEST,
  DELETE_EQUIPMENT_ASSIGNMENT_IGNORING_FAILURE_REQUEST,
  DELETE_EQUIPMENT_ASSIGNMENT_IGNORING_FAILURE_SUCCESS,
  DELETE_EQUIPMENT_ASSIGNMENT_IGNORING_FAILURE_FAILURE,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import {
  EquipmentAssignmentOperation,
  EquipmentInstanceIdentifier,
} from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchEquipmentAssignmentsRequest = () => ({
  type: FETCH_EQUIPMENT_ASSIGNMENTS_REQUEST,
});

const fetchEquipmentAssignmentsSuccess = (response: any) => ({
  type: FETCH_EQUIPMENT_ASSIGNMENTS_SUCCESS,
  response,
});

const fetchEquipmentAssignmentsFailure = (error: any) => ({
  type: FETCH_EQUIPMENT_ASSIGNMENTS_FAILURE,
  error,
});

let fetchEquipmentAssignmentsCancel: () => void | typeof undefined;
export const fetchEquipmentAssignments =
  ({
    productInstanceId,
    equipmentId,
    dateFromStart,
    dateFromEnd,
    equipmentInstanceId,
  }: {
    productInstanceId?: string;
    equipmentId?: string;
    dateFromStart?: string;
    dateFromEnd?: string;
    equipmentInstanceId?: string;
  }) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchEquipmentAssignmentsCancel && fetchEquipmentAssignmentsCancel();
    dispatch(fetchEquipmentAssignmentsRequest());
    axios
      .get('/api/equipments/assignments', {
        params: {
          product_instance_id: productInstanceId,
          equipment_id: equipmentId,
          start_date_local_from: dateFromStart,
          start_date_local_to: dateFromEnd,
          equipment_instance_id: equipmentInstanceId,
        },
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchEquipmentAssignmentsCancel = c;
        }),
      })
      .then((result) => {
        dispatch(fetchEquipmentAssignmentsSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchEquipmentAssignmentsFailure('canceled'));
        else dispatch(fetchEquipmentAssignmentsFailure(error));
      });
  };

const deleteEquipmentAssignmentRequest = (id: string) => ({
  type: DELETE_EQUIPMENT_ASSIGNMENT_REQUEST,
  id,
});

const deleteEquipmentAssignmentSuccess = (response: any, id: string) => ({
  type: DELETE_EQUIPMENT_ASSIGNMENT_SUCCESS,
  response,
  id,
});

const deleteEquipmentAssignmentFailure = (error: any) => ({
  type: DELETE_EQUIPMENT_ASSIGNMENT_FAILURE,
  error,
});

export const deleteEquipmentAssignment =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteEquipmentAssignmentRequest(id));
    fetch(`/api/equipments/assignments/${id}`, {
      method: 'DELETE',
      headers: getHTTPRequestHeaders(getState()),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(deleteEquipmentAssignmentSuccess(result, id)))
      .catch((error) => dispatch(deleteEquipmentAssignmentFailure(error)));
  };

const bulkOperateEquipmentAssignmentsRequest = () => ({
  type: BULK_OPERATE_EQUIPMENT_ASSIGNMENTS_REQUEST,
});

const bulkOperateEquipmentAssignmentsSuccess = (response: any) => ({
  type: BULK_OPERATE_EQUIPMENT_ASSIGNMENTS_SUCCESS,
  response,
});

const bulkOperateEquipmentAssignmentsFailure = (error: any) => ({
  type: BULK_OPERATE_EQUIPMENT_ASSIGNMENTS_FAILURE,
  error,
});

export const bulkOperateEquipmentAssignments =
  (operations: EquipmentAssignmentOperation[]) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(bulkOperateEquipmentAssignmentsRequest());
    axios
      .post(
        '/api/equipments/assignments/bulkoperation',
        {
          operations,
        },
        {
          headers: getHTTPRequestHeaders(getState()),
        }
      )
      .then((result) => {
        dispatch(bulkOperateEquipmentAssignmentsSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(bulkOperateEquipmentAssignmentsFailure('canceled'));
        else dispatch(bulkOperateEquipmentAssignmentsFailure(error));
      });
  };

const bulkAssignEquipmentAssignmentsRequest = () => ({
  type: BULK_ASSIGN_EQUIPMENT_ASSIGNMENTS_REQUEST,
});

const bulkAssignEquipmentAssignmentsSuccess = (response: any) => ({
  type: BULK_ASSIGN_EQUIPMENT_ASSIGNMENTS_SUCCESS,
  response,
});

const bulkAssignEquipmentAssignmentsFailure = (error: any) => ({
  type: BULK_ASSIGN_EQUIPMENT_ASSIGNMENTS_FAILURE,
  error,
});

export const bulkAssignEquipmentAssignments =
  (props: {
    reservation_ids: string[];
    to_equipment_instance_identifiers: EquipmentInstanceIdentifier[];
    ignore_booking_source?: boolean;
  }) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(bulkAssignEquipmentAssignmentsRequest());
    return axios
      .post('/api/equipments/assignments/bulkassign', props, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(bulkAssignEquipmentAssignmentsSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(bulkAssignEquipmentAssignmentsFailure('canceled'));
        else dispatch(bulkAssignEquipmentAssignmentsFailure(error));
      });
  };

const batchFetchEquipmentAssignmentsRequest = () => ({
  type: BATCH_FETCH_EQUIPMENT_ASSIGNMENTS_REQUEST,
});

const batchFetchEquipmentAssignmentsSuccess = (responses: any) => ({
  type: BATCH_FETCH_EQUIPMENT_ASSIGNMENTS_SUCCESS,
  responses,
});

const batchFetchEquipmentAssignmentsFailure = (error: any) => ({
  type: BATCH_FETCH_EQUIPMENT_ASSIGNMENTS_FAILURE,
  error,
});

export const batchFetchEquipmentAssignments =
  (equipmentIds: string[], dateFromStart?: string, dateFromEnd?: string) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(batchFetchEquipmentAssignmentsRequest());
    const promises = equipmentIds.map((equipmentId) =>
      axios.get('/api/equipments/assignments', {
        params: {
          equipment_id: equipmentId,
          start_date_local_from: dateFromStart,
          start_date_local_to: dateFromEnd,
        },
        headers: getHTTPRequestHeaders(getState()),
      })
    );
    return Promise.all(promises)
      .then((response) => {
        dispatch(
          batchFetchEquipmentAssignmentsSuccess(response.map((r) => r.data))
        );
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(batchFetchEquipmentAssignmentsFailure('canceled'));
        else dispatch(batchFetchEquipmentAssignmentsFailure(error));
      });
  };

export const batchFetchEquipmentAssignmentsByProductInstanceIds =
  (productInstanceIds: string[]) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(batchFetchEquipmentAssignmentsRequest());
    const promises = productInstanceIds.map((productInstanceId) =>
      axios.get('/api/equipments/assignments', {
        params: {
          product_instance_id: productInstanceId,
        },
        headers: getHTTPRequestHeaders(getState()),
      })
    );
    return Promise.all(promises)
      .then((response) => {
        dispatch(
          batchFetchEquipmentAssignmentsSuccess(response.map((r) => r.data))
        );
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(batchFetchEquipmentAssignmentsFailure('canceled'));
        else dispatch(batchFetchEquipmentAssignmentsFailure(error));
      });
  };

const deleteEquipmentAssignmentIgnoringFailureRequest = (id: string) => ({
  type: DELETE_EQUIPMENT_ASSIGNMENT_IGNORING_FAILURE_REQUEST,
  id,
});

const deleteEquipmentAssignmentIgnoringFailureSuccess = (
  response: any,
  id: string
) => ({
  type: DELETE_EQUIPMENT_ASSIGNMENT_IGNORING_FAILURE_SUCCESS,
  response,
  id,
});

const deleteEquipmentAssignmentIgnoringFailureFailure = (response: any) => ({
  type: DELETE_EQUIPMENT_ASSIGNMENT_IGNORING_FAILURE_FAILURE,
  response,
});

export const deleteEquipmentAssignmentIgnoringFailure =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteEquipmentAssignmentIgnoringFailureRequest(id));
    fetch(`/api/equipments/assignments/${id}`, {
      method: 'DELETE',
      headers: getHTTPRequestHeaders(getState()),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) =>
        dispatch(deleteEquipmentAssignmentIgnoringFailureSuccess(result, id))
      )
      .catch((error) =>
        dispatch(deleteEquipmentAssignmentIgnoringFailureFailure(error))
      );
  };
