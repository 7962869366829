import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from 'client/components/v3/Form/Modal';
import { Button } from 'client/components/v3/Common/Button';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { updateOrganization } from 'client/actions/organizations';
import { ReduxState } from 'client/reducers';

type Props = {
  areaGroupName: string;
  areaGroupKey: string;
  onClose: () => void;
};

export const DeleteAreaGroupModal = ({
  areaGroupKey,
  areaGroupName,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const loading = useSelector(
    (state: ReduxState) => state.organizations.loading
  );

  return areaGroupKey ? (
    <Modal
      title={t('Delete tag')}
      open={true}
      onClose={onClose}
      insertAtRoot={true}
      rightActionChildren={
        <>
          <Button
            text={t('Cancel')}
            onClick={() => {
              onClose();
            }}
            color="white"
          />
          <Button
            text={t('OK')}
            onClick={async () => {
              const orgId = activeUserOrganization?.id || '';
              try {
                const existingCustomerAreaGroups =
                  activeUserOrganization?.customer_ledger_settings
                    ?.customer_area_groups ?? [];
                const updatedCustomerAreaGroups =
                  existingCustomerAreaGroups.filter(
                    (areaGroup) => areaGroup.key !== areaGroupKey
                  );
                await dispatch(
                  updateOrganization(orgId, 'SUPPLIER', {
                    customer_ledger_settings: {
                      ...activeUserOrganization?.customer_ledger_settings,
                      customer_area_groups:
                        updatedCustomerAreaGroups.length > 0
                          ? updatedCustomerAreaGroups
                          : [],
                    },
                  })
                );
                await onClose();
              } catch (e) {
                console.error(e);
              }
            }}
            color="red"
            loading={loading}
          />
        </>
      }
    >
      {t('Are you sure you want to delete "{{areaGroupName}}"?', {
        areaGroupName: areaGroupName,
      })}
    </Modal>
  ) : null;
};
