import { Account } from 'shared/models/swagger';

type Permission = 'NONE' | 'READ_ONLY' | 'READ_WRITE' | 'CUSTOM';

export enum CustomUserResource {
  'RESERVATION' = 1,
  'RESERVATION.LIST',
  'RESERVATION.DASHBOARD',
  'RESERVATION.REPORTS',
  'RESERVATION.DATA_DOWNLOAD',
  'RESERVATION.ACTIM_RESERVATIONS',
  'MANIFEST',
  'MANIFEST.DAILY',
  'MANIFEST.RESOURCE_ASSIGNMENT',
  'MANIFEST.CUSTOMIZE',
  'MANIFEST.EDIT_RESOURCES',
  'MANIFEST.RESOURCE_AVAILABILITY',
  'PRODUCT',
  'PRODUCT.LIST',
  'PRODUCT.PROMOTIONS',
  'PRODUCT.COMMISSIONS',
  'AVAILABILITY',
  'AVAILABILITY.WEEKLY',
  'AVAILABILITY.MONTHLY',
  'AVAILABILITY.CUSTOM',
  'BILLING',
  'BILLING.INVOICES',
  'BILLING.PERIODS',
  'REVIEW',
  'REVIEW.REVIEWS',
  'REVIEW.EMBED_CODE',
  'WAIVER',
  'WAIVER.SETTINGS',
  'WAIVER.TEMPLATES',
  'WAIVER.WAIVERS',
  'MEDIA_DOWNLOAD',
  'MEDIA_DOWNLOAD.ORDERS',
  'MEDIA_DOWNLOAD.PRICES',
  'MEDIA_DOWNLOAD.UPLOAD',
  'MEDIA_DOWNLOAD.SETTINGS',
  'DIGITAL_GUIDANCE',
  'DIGITAL_GUIDANCE.PAGES',
  'DIGITAL_GUIDANCE.COUPONS',
  'DIGITAL_GUIDANCE.STAMP_RALLIES',
  'DIGITAL_GUIDANCE.SETTINGS',
  'DIGITAL_GUIDANCE.REDEEMED_RECORDS',
  'BOOKING_SITE',
  'BOOKING_SITE.ACCESS_REPORTS',
  'BOOKING_SITE.SETTINGS',
  'BOOKING_SITE.CUSTOMIZE',
  'BOOKING_SITE.TOP_PAGE',
  'BOOKING_SITE.HOMEPAGES',
  'BOOKING_SITE.EMAILS',
  'BOOKING_SITE.EMBED',
  'BOOKING_SITE.GOOGLE_MY_BUSINESS',
  'SYSTEM_FEE',
  'SYSTEM_FEE.WEB_BOOKINGS',
  'SYSTEM_FEE.SUBSCRIPTIONS',
  'SYSTEM_FEE.SETTINGS',
  'SHIFT_MANAGEMENT',
  'SHIFT_MANAGEMENT.STAFF',
  'SHIFT_MANAGEMENT.SCHEDULE',
  'E_TICKET',
  'E_TICKET.REDEMPTIONS',
  'E_TICKET.LOCATIONS',
  'E_TICKET.VIEW_HISTORY',
  'INQUIRY',
  'INQUIRY.LIST',
  'INQUIRY.BULK_EMAIL',
  'INQUIRY.BULK_SMS',
  'INQUIRY.SMS_USAGE',
  'INQUIRY.SETTINGS',
  'CUSTOMER_LEDGER',
  'CUSTOMER_LEDGER.LIST',
  'CUSTOMER_LEDGER.SETTINGS',
  'SURVEY',
  'SURVEY.SUBMISSIONS',
  'SURVEY.SURVEYS',
  'SURVEY.SETTINGS',
  'ACCOUNT',
  'ACCOUNT.LIST',
  'E_TICKET.QR_CODES',
  'BOOKING_SITE.WEBSITEINTEGRATION',
  'REVIEW.SETTINGS',
  'E_TICKET.DAILY_USES',
  'NEWSLETTER',
  'NEWSLETTER.LIST',
  'NEWSLETTER.SEGMENTS',
  'CHANNEL_MANAGER',
  'CHANNEL_MANAGER.JARAN_IMPORT',
  'CHANNEL_MANAGER.COMMISSIONS',
  'CHANNEL_MANAGER.INVOICES',
  'CHANNEL_MANAGER.PERIODS',

  'HOME',

  'SEAT_MANAGEMENT',
  'SEAT_MANAGEMENT.ASSIGNMENTS',
  'SEAT_MANAGEMENT.WEEKLY_AVAILABILITY',
  'SEAT_MANAGEMENT.MONTHLY_AVAILABILITY',
  'SEAT_MANAGEMENT.SETTINGS',

  'GROUPS',

  'MAPS',
  'MAPS.LIST',
  'MAPS.DASHBOARD',
  'MAPS.GUEST_JOURNEYS',
  'MAPS.ACCESS_REPORTS',
  'MAPS.WAIT_TIMES',
  'MAPS.SETTINGS',

  'JOURNEY_ANALYTICS',
  'JOURNEY_ANALYTICS.DASHBOARD',
  'JOURNEY_ANALYTICS.CUSTOMER_LIST',
  'JOURNEY_ANALYTICS.CONVERSION_PATH',
  'JOURNEY_ANALYTICS.SETTINGS',
  'JOURNEY_ANALYTICS.EMBED_CODE',

  'ONSITE_JOURNEY_ANALYTICS',
  'ONSITE_JOURNEY_ANALYTICS.DASHBOARD',
  'ONSITE_JOURNEY_ANALYTICS.CUSTOMER_LIST',
  'ONSITE_JOURNEY_ANALYTICS.SETTINGS',

  'MARKETING_AUTOMATION',
  'MARKETING_AUTOMATION.DASHBOARD',
  'MARKETING_AUTOMATION.SEGMENTS',
  'MARKETING_AUTOMATION.TRIGGERS',
  'MARKETING_AUTOMATION.CONTENTS',
  'MARKETING_AUTOMATION.CAMPAIGNS',

  'MOBILE_ORDER',
  'MOBILE_ORDER.DASHBOARD',
  'MOBILE_ORDER.ORDERS',
  'MOBILE_ORDER.RESTAURANTS',
  'MOBILE_ORDER.KITCHEN',

  'SITE_CONTROL_WIDGET',
  'SITE_CONTROL_WIDGET.DASHBOARD',
  'SITE_CONTROL_WIDGET.LIST',

  'INSTANT_WIN',
  'INSTANT_WIN.INSTANT_WIN_SETTINGS',
  'INSTANT_WIN.INSTANT_WIN_EVENTS',
  'INSTANT_WIN.SETTINGS',

  // Only add values to the end of the enum to avoid breaking existing permissions

  'UNUSED_LAST_VALUE',
}

export type CustomUserResourceKey = keyof typeof CustomUserResource;

export interface ResourcePermission {
  resource: CustomUserResource;
  permission: Permission;
}

// All resources in display order
export const allResources: CustomUserResource[] = [
  CustomUserResource['HOME'],
  CustomUserResource['RESERVATION'],
  CustomUserResource['RESERVATION.LIST'],
  CustomUserResource['RESERVATION.DASHBOARD'],
  CustomUserResource['RESERVATION.REPORTS'],
  CustomUserResource['RESERVATION.DATA_DOWNLOAD'],
  CustomUserResource['RESERVATION.ACTIM_RESERVATIONS'],
  CustomUserResource['MANIFEST'],
  CustomUserResource['MANIFEST.DAILY'],
  CustomUserResource['MANIFEST.RESOURCE_ASSIGNMENT'],
  CustomUserResource['MANIFEST.CUSTOMIZE'],
  CustomUserResource['MANIFEST.EDIT_RESOURCES'],
  CustomUserResource['MANIFEST.RESOURCE_AVAILABILITY'],
  CustomUserResource['PRODUCT'],
  CustomUserResource['PRODUCT.LIST'],
  CustomUserResource['PRODUCT.PROMOTIONS'],
  CustomUserResource['PRODUCT.COMMISSIONS'],
  CustomUserResource['AVAILABILITY'],
  CustomUserResource['AVAILABILITY.WEEKLY'],
  CustomUserResource['AVAILABILITY.MONTHLY'],
  CustomUserResource['AVAILABILITY.CUSTOM'],
  CustomUserResource['BILLING'],
  CustomUserResource['BILLING.INVOICES'],
  CustomUserResource['BILLING.PERIODS'],
  CustomUserResource['REVIEW'],
  CustomUserResource['REVIEW.REVIEWS'],
  CustomUserResource['REVIEW.EMBED_CODE'],
  CustomUserResource['REVIEW.SETTINGS'],
  CustomUserResource['WAIVER'],
  CustomUserResource['WAIVER.SETTINGS'],
  CustomUserResource['WAIVER.TEMPLATES'],
  CustomUserResource['WAIVER.WAIVERS'],
  CustomUserResource['MEDIA_DOWNLOAD'],
  CustomUserResource['MEDIA_DOWNLOAD.ORDERS'],
  CustomUserResource['MEDIA_DOWNLOAD.PRICES'],
  CustomUserResource['MEDIA_DOWNLOAD.UPLOAD'],
  CustomUserResource['MEDIA_DOWNLOAD.SETTINGS'],
  CustomUserResource['DIGITAL_GUIDANCE'],
  CustomUserResource['DIGITAL_GUIDANCE.PAGES'],
  CustomUserResource['DIGITAL_GUIDANCE.COUPONS'],
  CustomUserResource['DIGITAL_GUIDANCE.STAMP_RALLIES'],
  CustomUserResource['DIGITAL_GUIDANCE.SETTINGS'],
  CustomUserResource['DIGITAL_GUIDANCE.REDEEMED_RECORDS'],
  CustomUserResource['BOOKING_SITE'],
  CustomUserResource['BOOKING_SITE.ACCESS_REPORTS'],
  CustomUserResource['BOOKING_SITE.SETTINGS'],
  CustomUserResource['BOOKING_SITE.CUSTOMIZE'],
  CustomUserResource['BOOKING_SITE.TOP_PAGE'],
  CustomUserResource['BOOKING_SITE.HOMEPAGES'],
  CustomUserResource['BOOKING_SITE.EMAILS'],
  CustomUserResource['BOOKING_SITE.WEBSITEINTEGRATION'],
  CustomUserResource['BOOKING_SITE.GOOGLE_MY_BUSINESS'],
  CustomUserResource['SYSTEM_FEE'],
  CustomUserResource['SYSTEM_FEE.WEB_BOOKINGS'],
  CustomUserResource['SYSTEM_FEE.SUBSCRIPTIONS'],
  CustomUserResource['SYSTEM_FEE.SETTINGS'],
  CustomUserResource['SHIFT_MANAGEMENT'],
  CustomUserResource['SHIFT_MANAGEMENT.STAFF'],
  CustomUserResource['SHIFT_MANAGEMENT.SCHEDULE'],
  CustomUserResource['E_TICKET'],
  CustomUserResource['E_TICKET.REDEMPTIONS'],
  CustomUserResource['E_TICKET.LOCATIONS'],
  CustomUserResource['E_TICKET.VIEW_HISTORY'],
  CustomUserResource['E_TICKET.QR_CODES'],
  CustomUserResource['E_TICKET.DAILY_USES'],
  CustomUserResource['INQUIRY'],
  CustomUserResource['INQUIRY.LIST'],
  CustomUserResource['INQUIRY.BULK_EMAIL'],
  CustomUserResource['INQUIRY.BULK_SMS'],
  CustomUserResource['INQUIRY.SMS_USAGE'],
  CustomUserResource['INQUIRY.SETTINGS'],
  CustomUserResource['CUSTOMER_LEDGER'],
  CustomUserResource['CUSTOMER_LEDGER.LIST'],
  CustomUserResource['CUSTOMER_LEDGER.SETTINGS'],
  CustomUserResource['SURVEY'],
  CustomUserResource['SURVEY.SUBMISSIONS'],
  CustomUserResource['SURVEY.SURVEYS'],
  CustomUserResource['SURVEY.SETTINGS'],
  CustomUserResource['NEWSLETTER'],
  CustomUserResource['NEWSLETTER.LIST'],
  CustomUserResource['NEWSLETTER.SEGMENTS'],
  CustomUserResource['ACCOUNT'],
  CustomUserResource['ACCOUNT.LIST'],
  CustomUserResource['CHANNEL_MANAGER'],
  CustomUserResource['CHANNEL_MANAGER.COMMISSIONS'],
  CustomUserResource['CHANNEL_MANAGER.INVOICES'],
  CustomUserResource['CHANNEL_MANAGER.PERIODS'],
  CustomUserResource['CHANNEL_MANAGER.JARAN_IMPORT'],
  CustomUserResource['SEAT_MANAGEMENT'],
  CustomUserResource['SEAT_MANAGEMENT.ASSIGNMENTS'],
  CustomUserResource['SEAT_MANAGEMENT.WEEKLY_AVAILABILITY'],
  CustomUserResource['SEAT_MANAGEMENT.MONTHLY_AVAILABILITY'],
  CustomUserResource['SEAT_MANAGEMENT.SETTINGS'],
  CustomUserResource['GROUPS'],
  CustomUserResource['MAPS'],
  CustomUserResource['MAPS.LIST'],
  CustomUserResource['MAPS.DASHBOARD'],
  CustomUserResource['MAPS.GUEST_JOURNEYS'],
  CustomUserResource['MAPS.ACCESS_REPORTS'],
  CustomUserResource['MAPS.WAIT_TIMES'],
  CustomUserResource['MAPS.SETTINGS'],
  CustomUserResource['JOURNEY_ANALYTICS'],
  CustomUserResource['JOURNEY_ANALYTICS.DASHBOARD'],
  CustomUserResource['JOURNEY_ANALYTICS.CUSTOMER_LIST'],
  CustomUserResource['JOURNEY_ANALYTICS.SETTINGS'],
  CustomUserResource['JOURNEY_ANALYTICS.EMBED_CODE'],
  CustomUserResource['ONSITE_JOURNEY_ANALYTICS'],
  CustomUserResource['ONSITE_JOURNEY_ANALYTICS.DASHBOARD'],
  CustomUserResource['ONSITE_JOURNEY_ANALYTICS.CUSTOMER_LIST'],
  CustomUserResource['ONSITE_JOURNEY_ANALYTICS.SETTINGS'],
  CustomUserResource['MARKETING_AUTOMATION'],
  CustomUserResource['MARKETING_AUTOMATION.DASHBOARD'],
  CustomUserResource['MARKETING_AUTOMATION.SEGMENTS'],
  CustomUserResource['MARKETING_AUTOMATION.TRIGGERS'],
  CustomUserResource['MARKETING_AUTOMATION.CONTENTS'],
  CustomUserResource['MARKETING_AUTOMATION.CAMPAIGNS'],
  CustomUserResource['MOBILE_ORDER'],
  CustomUserResource['MOBILE_ORDER.DASHBOARD'],
  CustomUserResource['MOBILE_ORDER.ORDERS'],
  CustomUserResource['MOBILE_ORDER.RESTAURANTS'],
  CustomUserResource['MOBILE_ORDER.KITCHEN'],
  CustomUserResource['SITE_CONTROL_WIDGET'],
  CustomUserResource['SITE_CONTROL_WIDGET.DASHBOARD'],
  CustomUserResource['SITE_CONTROL_WIDGET.LIST'],
  CustomUserResource['INSTANT_WIN'],
  CustomUserResource['INSTANT_WIN.INSTANT_WIN_SETTINGS'],
  CustomUserResource['INSTANT_WIN.INSTANT_WIN_EVENTS'],
  CustomUserResource['INSTANT_WIN.SETTINGS'],
];

export const topLevelResources = allResources.filter(
  (a) => !CustomUserResource[a].includes('.')
);

export const allSubResources: CustomUserResource[] = [];

const mapPermissionToString = (permission: Permission): string => {
  switch (permission) {
    case 'NONE':
      return 'N';
    case 'READ_ONLY':
      return 'R';
    case 'READ_WRITE':
      return 'W';
    case 'CUSTOM':
      return 'C';
  }
};

const mapStringToPermission = (s: string): Permission => {
  switch (s) {
    case 'N':
      return 'NONE';
    case 'R':
      return 'READ_ONLY';
    case 'W':
      return 'READ_WRITE';
    case 'C':
      return 'CUSTOM';
  }

  return 'NONE';
};

const VERSION = '1';

// convertResourcePermissionsToPermissionsString creates a string representation of the permissions.
// Uses one byte per resource.
export const convertResourcePermissionsToPermissionsString = (
  resourcePermissions: ResourcePermission[]
): string => {
  const permissions: string[] = [];

  for (
    let resource = CustomUserResource['RESERVATION'];
    resource < CustomUserResource['UNUSED_LAST_VALUE'];
    resource++
  ) {
    const p =
      resourcePermissions.find((rp) => rp.resource === resource)?.permission ||
      'NONE';

    permissions.push(mapPermissionToString(p));
  }
  return `${VERSION}${permissions.join('')}`;
};

export const convertPermissionsStringToResourcePermissions = (
  permissionsString: string
): ResourcePermission[] => {
  const resourcePermissions: ResourcePermission[] = [];

  const version = permissionsString[0];
  if (version !== VERSION) {
    throw new Error(`Unexpected version: ${permissionsString}`);
  }

  for (
    let idx = CustomUserResource['RESERVATION'];
    idx < CustomUserResource['UNUSED_LAST_VALUE'];
    idx++
  ) {
    const permission =
      idx < CustomUserResource['UNUSED_LAST_VALUE']
        ? mapStringToPermission(permissionsString[idx])
        : 'READ_WRITE';
    resourcePermissions.push({
      resource: idx,
      permission,
    });
  }

  return resourcePermissions;
};

export const hasCustomUserRoleReadPermissions = (
  account: Account | null,
  resourceKey: keyof typeof CustomUserResource
): boolean => {
  if (account?.role === 'CUSTOM_USER') {
    if (account?.permissions) {
      const resourcePermissions = convertPermissionsStringToResourcePermissions(
        account.permissions
      );

      const resource = CustomUserResource[resourceKey];

      // If we are checking permissions for a sub-resource, first check the top-level resource. If the top-level resource
      // is "custom", then check the sub-resource.
      if (resourceKey.includes('.')) {
        const topLevelResource =
          CustomUserResource[
            resourceKey.split('.')[0] as keyof typeof CustomUserResource
          ];

        const topLevelResourcePermission = resourcePermissions.find(
          (rp) => rp.resource === topLevelResource
        );
        if (topLevelResourcePermission?.permission === 'CUSTOM') {
          const resourcePermission = resourcePermissions.find(
            (rp) => rp.resource === resource
          );
          return resourcePermission?.permission !== 'NONE';
        }
        return topLevelResourcePermission?.permission !== 'NONE';
      } else {
        const resourcePermission = resourcePermissions.find(
          (rp) => rp.resource === resource
        );
        return resourcePermission?.permission !== 'NONE';
      }
    }
  }

  return true;
};

export const hasCustomUserRoleWritePermissions = (
  account: Account | null,
  resourceKey: keyof typeof CustomUserResource
): boolean => {
  if (account?.role === 'CUSTOM_USER') {
    if (account?.permissions) {
      const resourcePermissions = convertPermissionsStringToResourcePermissions(
        account.permissions
      );

      const resource = CustomUserResource[resourceKey];

      // If we are checking permissions for a sub-resource, first check the top-level resource. If the top-level resource
      // is "custom", then check the sub-resource.
      if (resourceKey.includes('.')) {
        const topLevelResource =
          CustomUserResource[
            resourceKey.split('.')[0] as keyof typeof CustomUserResource
          ];

        const topLevelResourcePermission = resourcePermissions.find(
          (rp) => rp.resource === topLevelResource
        );
        if (topLevelResourcePermission?.permission === 'CUSTOM') {
          const resourcePermission = resourcePermissions.find(
            (rp) => rp.resource === resource
          );
          return resourcePermission?.permission === 'READ_WRITE';
        }
        return topLevelResourcePermission?.permission === 'READ_WRITE';
      } else {
        const resourcePermission = resourcePermissions.find(
          (rp) => rp.resource === resource
        );
        return resourcePermission?.permission === 'READ_WRITE';
      }

      return false;
    }
  }

  return true;
};
