import { TFunction } from 'react-i18next';
import { Content } from '@nutmeglabs/nutmeg-sitecontrol-ui';

import { SiteControlWidget } from 'shared/models/swagger';

import { Target } from './formValues';

export const constructUrl = (baseUrl: string, target: Target) => {
  if (!baseUrl) {
    return '';
  }

  let url = baseUrl;

  if (target.path) {
    // Ensure there's a slash between baseUrl and path
    if (!url.endsWith('/') && !target.path.startsWith('/')) {
      url += '/';
    }
    url += target.path;
  }

  if (target.queries && target.queries.length > 0) {
    const queryString = target.queries
      .filter((query) => query.parameter && query.value) // Ensure both parameter and value are defined
      .map(
        (query) =>
          `${encodeURIComponent(
            query.parameter as string
          )}=${encodeURIComponent(query.value as string)}`
      )
      .join('&');

    if (queryString) {
      url += `?${queryString}`;
    }
  }

  if (target.hash) {
    url += `#${encodeURIComponent(target.hash)}`;
  }

  return url;
};

export const safeConvertToNumber = (val: any): number | undefined => {
  const num = Number(val);
  if (isNaN(num)) {
    return undefined;
  }
  return num;
};

export const getDefaultContentForCoupon = (t: TFunction): Content => ({
  type: 'COUPON',
  entranceStyle: 'SLIDE_IN',
  coupon: {
    title: t('Title Goes Here'),
    description: t('Description Text Goes Here. '),
    imageUrl: 'https://assets.ntmg.com/newsletter/default-coupon.png',
    buttons: [{ text: t('Copy Promo Code') }, { text: t('Close') }],
    couponCode: 'COUPON-123',
    copyButtonText: t('Copied'),
  },
});

export const getDefaultContentForCard = (t: TFunction): Content => ({
  type: 'CARD',
  entranceStyle: 'SLIDE_IN',
  card: {
    title: t('Title Goes Here'),
    description: t('Description Text Goes Here. '),
    button: { text: t('See More'), url: 'https://ntmg.jp' },
  },
});

export const getDefaultContentForVertical = (t: TFunction): Content => ({
  type: 'VERTICAL',
  entranceStyle: 'SLIDE_IN',
  vertical: {
    title: t('Title Goes Here'),
    description: t('Description Text Goes Here. '),
    button: { text: t('See More'), url: 'https://ntmg.jp' },
    imageUrl: 'https://assets.ntmg.com/newsletter/header-2.jpg',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.\n\nUt enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.\n\nExcepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
});

export const getDefaultContentForMultiButtons = (t: TFunction): Content => ({
  type: 'MULTI_BUTTONS',
  entranceStyle: 'SLIDE_IN',
  multiButtons: {
    imageUrl: 'https://assets.ntmg.com/newsletter/header-1.png',
    title: t('Title Goes Here'),
    description: t('Description Text Goes Here. '),
    buttons: [
      { text: t('See More'), url: 'https://ntmg.jp' },
      { text: t('View new arrivals'), url: 'https://ntmg.jp' },
      { text: t('Sign up'), url: 'https://ntmg.jp' },
      { text: t('Take a quiz'), url: 'https://ntmg.jp' },
    ],
  },
});

export const useDefaultContent = (
  type: 'COUPON' | 'CARD' | 'VERTICAL' | 'MULTI_BUTTONS',
  t: TFunction
): Content => {
  switch (type) {
    case 'COUPON':
      return getDefaultContentForCoupon(t);
    case 'CARD':
      return getDefaultContentForCard(t);
    case 'VERTICAL':
      return getDefaultContentForVertical(t);
    case 'MULTI_BUTTONS':
      return getDefaultContentForMultiButtons(t);
    default:
      return getDefaultContentForCoupon(t);
  }
};

export const useDefaultSiteControlWidget = (
  type: 'COUPON' | 'CARD' | 'VERTICAL' | 'MULTI_BUTTONS',
  t: TFunction
): SiteControlWidget => {
  // Add more properties if necessary
  return {
    content_json: JSON.stringify(useDefaultContent(type, t)),
  };
};

export const appendUtmParametersForTracking = (
  url: string,
  widgetId: string
) => {
  const utmSourceKey = 'utm_source';
  const utmSource = `${utmSourceKey}=ntmg.sitecontrolwidget`;
  const utmCampaignKey = 'utm_campaign';
  const utmCampaign = `${utmCampaignKey}=${widgetId}`;

  const hasUtmSource = url.includes(utmSourceKey);
  const hasUtmCampaign = url.includes(utmCampaignKey);

  // If both parameters are already present, replace with new values
  if (hasUtmSource && hasUtmCampaign) {
    return url
      .replace(/utm_source=[^&]*/, utmSource)
      .replace(/utm_campaign=[^&]*/, utmCampaign);
  }

  // Check if the URL already has a query string
  if (url.includes('?')) {
    url = url
      .replace(/utm_source=[^&]*/, utmSource)
      .replace(/utm_campaign=[^&]*/, utmCampaign);

    if (!hasUtmSource) {
      url += `&${utmSource}`;
    }
    if (!hasUtmCampaign) {
      url += `&${utmCampaign}`;
    }
    return url;
  } else {
    // Add both parameters if no query string exists
    return `${url}?${utmSource}&${utmCampaign}`;
  }
};
