import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import clsx from 'clsx';

import * as Swagger from 'shared/models/swagger';
import { PageContent } from 'client/components/v3/Page/PageContent';
import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { V3Page } from 'client/components/v3/Page/V3Page';
import { Button } from 'client/components/v3/Common/Button';
import { PagedGenericTable } from 'client/components/v3/PagedGenericTable/PagedGenericTable';
import {
  createSiteControlWidget,
  deleteSiteControlWidget,
  fetchSiteControlWidgets,
  updateSiteControlWidget,
} from 'client/actions/siteControlWidgets';
import { ReduxState } from 'client/reducers';
import { ColumnType } from 'client/components/v3/GenericTable/GenericTable';
import { Modal } from 'client/components/v3/Form/Modal';
import { TranslateFuncType } from 'client/components/Translate';
import { Badge, BadgeColor } from 'client/components/v3/Common/Badge';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { config } from 'client/config';

import styles from './SiteControlWidgetList.module.css';
import { InstallEmbedCodeModal } from './InstallEmbedCodeModal';
import { getAbTestOptions, getAbTestOptionText } from './util';

export const EditButton = ({
  siteControlWidget,
}: {
  siteControlWidget: Swagger.SiteControlWidget;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Link
        to={`/sitecontrolwidgets/${siteControlWidget.id}/edit`}
        style={{ textDecoration: 'none', color: 'inherit' }}
      >
        <div className={styles['p-siteControlWidget__action']}>
          <i className="c-icon-outline-general-edit-05"></i>
          <p>{t('Edit')}</p>
        </div>
      </Link>
    </>
  );
};

export const CopyButton = ({
  siteControlWidget,
}: {
  siteControlWidget: Swagger.SiteControlWidget;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeOrganization = useSelector(activeUserOrganizationSelector);

  return (
    <div
      className={styles['p-siteControlWidget__action']}
      onClick={async () => {
        // Remove unnecessary fields
        delete siteControlWidget.id;
        delete siteControlWidget.ab_test_pair;

        await dispatch(
          createSiteControlWidget({
            ...siteControlWidget,
            name: `${siteControlWidget.name} [COPY]`,
            supplier_id: activeOrganization?.id ?? '',
          })
        );
        await dispatch(fetchSiteControlWidgets());
      }}
    >
      <i className="c-icon-outline-general-copy-01"></i>
      <p>{t('Copy')}</p>
    </div>
  );
};

export const DeleteButton = ({
  siteControlWidget,
}: {
  siteControlWidget: Swagger.SiteControlWidget;
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const closeModal = () => {
    setShowDeleteModal(false);
  };

  return (
    <>
      <div
        className={clsx(
          styles['p-siteControlWidget__action'],
          styles['delete']
        )}
        onClick={() => {
          setShowDeleteModal(true);
        }}
      >
        <i className="c-icon-outline-general-trash-03"></i>
        <p>{t('Delete')}</p>
      </div>
      {showDeleteModal && (
        <Modal
          onClose={closeModal}
          insertAtRoot
          title={t('Delete Site Hacker')}
          open={true}
          rightActionChildren={
            <>
              <Button text={t('Cancel')} color="white" onClick={closeModal} />
              <Button
                text={t('Delete')}
                onClick={async () => {
                  await dispatch(
                    deleteSiteControlWidget(siteControlWidget?.id ?? '')
                  );
                  setShowDeleteModal(false);
                }}
              />
            </>
          }
        >
          <div>
            {t('Are you sure you want to delete "{{name}}"?', {
              name: siteControlWidget.name,
            })}
          </div>
        </Modal>
      )}
    </>
  );
};

const translateStatus = (status: string, t: TranslateFuncType) => {
  switch (status) {
    case 'DRAFT':
      return t('Draft');
    case 'ACTIVE':
      return t('Active');
    default:
      return t('Draft');
  }
};

const getBadgeColorForStatus = (status: string): BadgeColor => {
  switch (status) {
    case 'ACTIVE':
      return 'success';
    case 'DRAFT':
      return 'warning';
    default:
      return 'gray';
  }
};

const useColumns = (): ColumnType<Swagger.SiteControlWidget>[] => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return [
    {
      Header: t('Name'),
      id: 'name',
      accessor: (siteControlWidget: Swagger.SiteControlWidget) => (
        <div style={{ whiteSpace: 'pre-line' }}>
          {siteControlWidget.name ?? ''}
        </div>
      ),
    },
    {
      Header: t('Status'),
      id: 'status',
      accessor: (siteControlWidget: Swagger.SiteControlWidget) => (
        <Badge
          label={translateStatus(siteControlWidget.status ?? '', t)}
          color={getBadgeColorForStatus(siteControlWidget.status ?? '')}
        />
      ),
      width: 140,
    },
    {
      Header: t('View'),
      id: 'view',
      accessor: (siteControlWidget: Swagger.SiteControlWidget) => {
        if (siteControlWidget.status !== 'DRAFT') {
          return (
            <div>
              <Link to={`/sitecontrolwidgets/${siteControlWidget.id}/report`}>
                <a>{t('Report')}</a>
              </Link>
            </div>
          );
        }
        return '-';
      },
      width: 140,
    },
    ...(config.enableSiteHackerAbTest
      ? [
          {
            Header: t('AB Test'),
            id: 'abTest',
            accessor: (siteControlWidget: Swagger.SiteControlWidget) => {
              return (
                <SingleDropdown
                  options={getAbTestOptions(t)}
                  selectedOption={getAbTestOptionText(
                    t,
                    siteControlWidget.ab_test_pair?.value ?? 'NO_TEST'
                  )}
                  onChange={async (value) => {
                    if (
                      !siteControlWidget.ab_test_pair &&
                      value === 'NO_TEST'
                    ) {
                      return;
                    }

                    if (siteControlWidget.id) {
                      const newSiteControlWidget = {
                        ...siteControlWidget,
                        ab_test_pair: { value },
                      };
                      await dispatch(
                        updateSiteControlWidget(
                          siteControlWidget.id,
                          newSiteControlWidget
                        )
                      );
                      await dispatch(fetchSiteControlWidgets());
                    }
                  }}
                />
              );
            },
            width: 140,
            noOverflow: true,
          },
        ]
      : []),
    {
      Header: '',
      id: 'editCopy',
      accessor: (siteControlWidget: Swagger.SiteControlWidget) => (
        <div className={styles['p-siteControlWidget__actions']}>
          <EditButton siteControlWidget={siteControlWidget} />
          {config.enableSiteHackerAbTest && (
            <CopyButton siteControlWidget={siteControlWidget} />
          )}
        </div>
      ),
      width: 150,
    },
    {
      Header: '',
      id: 'delete',
      accessor: (siteControlWidget: Swagger.SiteControlWidget) => (
        <DeleteButton siteControlWidget={siteControlWidget} />
      ),
      width: 100,
    },
  ];
};

export const SiteControlWidgetList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const columns = useColumns();
  const loading = useSelector(
    (state: ReduxState) => state.siteControlWidgets.loading
  );
  const siteControlWidgets = useSelector(
    (state: ReduxState) => state.siteControlWidgets.all
  ).sort((a, b) => {
    if (a.status === 'ACTIVE' && b.status !== 'ACTIVE') return -1;
    if (a.status !== 'ACTIVE' && b.status === 'ACTIVE') return 1;
    return 0;
  });
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(fetchSiteControlWidgets());
  }, []);

  return (
    <V3Page>
      <PageHeader title={t('Site Hacker List')} hideNewUIToggle={true}>
        <Button
          text={t('Install Embed Code')}
          onClick={() => {
            setShowModal(true);
          }}
        />
        <Link to="/sitecontrolwidgets/new">
          <Button text={t('Create New Site Hacker')} />
        </Link>
      </PageHeader>
      <PageContent>
        <div className={styles['p-siteControlWidget']}>
          <PagedGenericTable
            loading={loading}
            columns={columns}
            allItems={siteControlWidgets}
          />
        </div>
      </PageContent>
      {showModal && (
        <InstallEmbedCodeModal
          onClose={() => {
            setShowModal(false);
          }}
        />
      )}
    </V3Page>
  );
};
