import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  FETCH_ANALYTICS_CUSTOMERS_FAILURE,
  FETCH_ANALYTICS_CUSTOMERS_REQUEST,
  FETCH_ANALYTICS_CUSTOMERS_SUCCESS,
  FETCH_ANALYTICS_CUSTOMER_FAILURE,
  FETCH_ANALYTICS_CUSTOMER_REQUEST,
  FETCH_ANALYTICS_CUSTOMER_SUCCESS,
  FETCH_CONVERSION_PATHS_FAILURE,
  FETCH_CONVERSION_PATHS_REQUEST,
  FETCH_CONVERSION_PATHS_SUCCESS,
  SET_WEB_JOURNEY_CONVERSION_PATH_SORT_BY_CONVERSION_COUNT,
  SET_WEB_JOURNEY_CONVERSION_PATH_SORT_BY_SESSION_COUNT,
} from 'client/constants/ActionTypes';
import type {
  AnalyticsCustomer,
  ConversionPath,
  SearchAnalyticsCustomersResponse,
  SearchConversionPathsResponse,
} from 'shared/models/swagger';

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_ANALYTICS_CUSTOMERS_FAILURE:
    case FETCH_ANALYTICS_CUSTOMER_FAILURE:
      return action.error;

    case FETCH_ANALYTICS_CUSTOMERS_SUCCESS:
    case FETCH_ANALYTICS_CUSTOMER_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_ANALYTICS_CUSTOMERS_REQUEST:
    case FETCH_ANALYTICS_CUSTOMER_REQUEST:
    case FETCH_CONVERSION_PATHS_REQUEST:
      return true;

    case FETCH_ANALYTICS_CUSTOMERS_FAILURE:
    case FETCH_ANALYTICS_CUSTOMER_FAILURE:
    case FETCH_CONVERSION_PATHS_FAILURE:
    case FETCH_ANALYTICS_CUSTOMERS_SUCCESS:
    case FETCH_ANALYTICS_CUSTOMER_SUCCESS:
    case FETCH_CONVERSION_PATHS_SUCCESS:
      return false;

    default:
      return state;
  }
};

const all = (
  state: AnalyticsCustomer[] = [],
  action: any
): AnalyticsCustomer[] => {
  switch (action.type) {
    case FETCH_ANALYTICS_CUSTOMERS_SUCCESS: {
      const response = action.response as SearchAnalyticsCustomersResponse;
      return response.analytics_customers;
    }

    case FETCH_ANALYTICS_CUSTOMER_SUCCESS: {
      const response = action.response as AnalyticsCustomer;
      return state.map((customer) =>
        customer.id === response.id ? response : customer
      );
    }

    default:
      return state;
  }
};

export type ConversionPathData = {
  paths: ConversionPath[];
  totalSessionCount: number;
  totalConversionCount: number;
};
const conversionPaths = (
  state: ConversionPath[] = [],
  action: any
): ConversionPath[] => {
  switch (action.type) {
    case FETCH_CONVERSION_PATHS_SUCCESS: {
      const response = action.response as SearchConversionPathsResponse;
      return response.conversion_paths;
    }

    case SET_WEB_JOURNEY_CONVERSION_PATH_SORT_BY_SESSION_COUNT: {
      const sortedPaths = state.sort((a, b) => {
        const aCount = a.session_count || 0;
        const bCount = b.session_count || 0;

        return action.sortOrder === 'DEFAULT' || action.sortOrder === 'DESC'
          ? bCount - aCount // Descending order
          : aCount - bCount; // Ascending order
      });
      return sortedPaths;
    }

    case SET_WEB_JOURNEY_CONVERSION_PATH_SORT_BY_CONVERSION_COUNT: {
      const sortedPaths = state.sort((a, b) => {
        const aCount = a.conversion_count || 0;
        const bCount = b.conversion_count || 0;

        return action.sortOrder === 'DEFAULT' || action.sortOrder === 'DESC'
          ? bCount - aCount // Descending order
          : aCount - bCount; // Ascending order
      });
      return sortedPaths;
    }

    default:
      return state;
  }
};

const totalSessionCount = (state = 0, action: any): number => {
  switch (action.type) {
    case FETCH_CONVERSION_PATHS_SUCCESS: {
      const response = action.response as SearchConversionPathsResponse;
      return response.total_session_count ?? 0;
    }

    default:
      return state;
  }
};

const totalConversionCount = (state = 0, action: any): number => {
  switch (action.type) {
    case FETCH_CONVERSION_PATHS_SUCCESS: {
      const response = action.response as SearchConversionPathsResponse;
      return response.total_conversion_count ?? 0;
    }

    default:
      return state;
  }
};

export const analyticsCustomers = combineReducers({
  error,
  loading,
  all,
  conversionPaths,
  totalSessionCount,
  totalConversionCount,
});
