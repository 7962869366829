import * as React from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { Box } from 'client/components/Box/Box';
import { V3Page } from 'client/components/v3/Page/V3Page';
import { PageContent } from 'client/components/v3/Page/PageContent';
import { fetchAnalyticsCustomer } from 'client/actions/analyticsCustomers';
import { fetchProducts } from 'client/actions/products';
import { ReduxState } from 'client/reducers';

import { Loading } from '../Loading';

import { Summary } from './Summary';
import { PageTitle } from './PageTitle';
import { DetailsPane } from './DetailsPane';

export const JourneyAnalyticsCustomerDetails = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  React.useEffect(() => {
    dispatch(fetchAnalyticsCustomer(id));
  }, [id]);

  React.useEffect(() => {
    dispatch(fetchProducts());
  }, []);

  const allCustomers = useSelector(
    (state: ReduxState) => state.analyticsCustomers.all
  );

  const customer = allCustomers.find((c) => c.id === id);
  const loading = useSelector(
    (state: ReduxState) => state.analyticsCustomers.loading
  );

  return (
    <V3Page>
      <PageHeader
        breadcrumbPaths={[
          { text: t('Customer List'), url: '/journeyanalytics/customers' },
          {
            text:
              customer?.customer_display_name ??
              customer?.id?.substring(0, 8) ??
              '',
            url: '',
          },
        ]}
      >
        <PageTitle customer={customer} />
      </PageHeader>

      <PageContent>
        <Summary customer={customer} />
        <Box mt={4}>{loading ? <Loading /> : <DetailsPane />}</Box>
      </PageContent>
    </V3Page>
  );
};
