import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import _ from 'lodash';

import { getInstantWinEventColumns } from 'client/components/InstantWinEventTable/util';
import { InstantWinEventSearchFiltersDisplayBox } from 'client/components/InstantWinEventTable/InstantWinEventSearchFiltersDisplayBox/InstantWinEventSearchFiltersDisplayBox';
import { InstantWinEventSearchSettingsModal } from 'client/components/InstantWinEventTable/InstantWinEventSearchSettingsModal/InstantWinEventSearchSettingsModal';
import { InstantWinEventTableSettingsModal } from 'client/components/InstantWinEventTable/InstantWinEventTableSettingsModal/InstantWinEventTableSettingsModal';
import {
  setLastExecutedInstantWinEventSearchCondition,
  setInstantWinEventCurrentPage,
  setInstantWinEventRowCount,
} from 'client/actions/instantWinEventTableControls';
import {
  fetchInstantWinEvents,
  updateInstantWinEventListPageTokens,
} from 'client/actions/instantWinEvents';
import { instantWinEventVisibleColumnsSelector } from 'client/reducers/instantWinEventTableControls';
import { convertSearchInstantWinEventsRequestToQueryParams } from 'client/libraries/util/searchInstantWinEvents';
import { Box } from 'client/components/Box/Box';
import { Button } from 'client/components/Form';
import {
  activeUserIsNutmegAdminSelector,
  activeUserOrganizationSelector,
} from 'client/reducers/user';
import type { ReduxState } from 'client/reducers';
import type { SearchInstantWinEventsRequest } from 'client/libraries/util/searchInstantWinEvents';
import baseStyles from 'client/base.module.css';
import searchIcon from 'client/images/ic_search.svg';
import thIcon from 'client/images/ic_th.svg';
import { InstantWinEvent } from 'shared/models/swagger';
import {
  ColumnType,
  GenericTable,
} from 'client/components/GenericTable/GenericTable';

export const InstantWinEventTable = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const rowCount = useSelector(
    (state: ReduxState) => state.instantWinEventTableControls.rowCount
  );
  const currentPage = useSelector(
    (state: ReduxState) => state.instantWinEventTableControls.currentPage
  );
  const totalHits = useSelector(
    (state: ReduxState) => state.instantWinEvents.totalSize
  );
  const pageTokens = useSelector(
    (state: ReduxState) => state.instantWinEvents.pageTokens
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const loading = useSelector(
    (state: ReduxState) => state.instantWinEvents.loading
  );
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const lastExecutedSearchCondition = useSelector(
    (state: ReduxState) =>
      state.instantWinEventTableControls.lastExecutedSearchCondition
  );

  const allColumns = getInstantWinEventColumns(t, locale);
  const visibleColumns = useSelector(instantWinEventVisibleColumnsSelector);
  const columns = React.useMemo(() => {
    const getColumns = (columnMask: string[]) => {
      return columnMask.map(
        (c) =>
          allColumns.find((col) => col.id === c) as ColumnType<InstantWinEvent>
      );
    };

    return getColumns(visibleColumns);
  }, [visibleColumns, allColumns]);

  const [searchCondition, setSearchCondition] =
    React.useState<SearchInstantWinEventsRequest>(lastExecutedSearchCondition);
  const instantWinEvents = useSelector(
    (state: ReduxState) => state.instantWinEvents.all
  );

  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);
  React.useEffect(() => {
    search();
  }, [activeUserOrganization]);

  // Re-fetch data whenever currentPage or rowCount changes
  React.useEffect(() => {
    search();
  }, [currentPage, rowCount]);

  const search = async () => {
    if (!isNutmegAdmin) {
      if (!_.isEqual(searchCondition, lastExecutedSearchCondition)) {
        await dispatch(
          setLastExecutedInstantWinEventSearchCondition(searchCondition)
        );
      }

      dispatch(
        fetchInstantWinEvents(
          convertSearchInstantWinEventsRequestToQueryParams(searchCondition)
        )
      );
    }
  };

  const reset = () => {
    setSearchCondition({
      instantWinIds: [],
      drawnDateFrom: '',
      drawnDateTo: '',
      dateFilterPreset: 'DRAWN_1_DAY',
    });
  };

  // this value is used in fetchCustomerList
  const handleOnClickPrevious = () => {
    dispatch(
      updateInstantWinEventListPageTokens({
        allTokens: pageTokens.allTokens,
        currToken: pageTokens.allTokens[pageTokens.currToken].previous,
        indexToToken: pageTokens.indexToToken,
      })
    );
    search();
  };

  // this value is used in fetchCustomerList
  const handleOnClickNext = () => {
    const allTokens = pageTokens.allTokens;
    const nextToken = pageTokens.allTokens[pageTokens.currToken].next;
    if (nextToken != null) {
      allTokens[nextToken] = {
        previous: pageTokens.currToken,
        next: null,
      };
      dispatch(
        updateInstantWinEventListPageTokens({
          allTokens: allTokens,
          currToken: nextToken,
          indexToToken: pageTokens.indexToToken,
        })
      );
      search();
    }
  };

  return (
    <div>
      {loading ? (
        <Dimmer active={loading} inverted>
          <Loader>{t('Loading')}</Loader>
        </Dimmer>
      ) : (
        <div className={clsx(baseStyles['base-main__body__header'])}>
          <div
            className={clsx(
              baseStyles['base-main__body__header__left'],
              baseStyles['spOrder-1']
            )}
          >
            <InstantWinEventSearchSettingsModal
              onReset={reset}
              onSearch={search}
              searchCondition={searchCondition}
              setSearchCondition={(condition) => setSearchCondition(condition)}
              trigger={
                <Button.Transition
                  content={
                    <>
                      <img src={searchIcon} />
                      {t('Search')}
                    </>
                  }
                />
              }
            />
          </div>
          <div
            className={clsx(
              baseStyles['base-main__body__header__right'],
              baseStyles['spOrder-2']
            )}
          >
            <InstantWinEventTableSettingsModal
              trigger={
                <a
                  className={clsx(
                    baseStyles['base-btn'],
                    baseStyles['square'],
                    baseStyles['gray']
                  )}
                >
                  <img src={thIcon} />
                </a>
              }
            />
          </div>
        </div>
      )}
      <Box mb={2}>
        <InstantWinEventSearchFiltersDisplayBox
          searchFilters={lastExecutedSearchCondition}
        />
      </Box>
      <GenericTable<InstantWinEvent>
        items={instantWinEvents}
        totalCount={totalHits}
        columns={columns}
        rowCount={rowCount}
        currentPage={currentPage}
        onRowCountChange={(rowCount: number) => {
          dispatch(setInstantWinEventRowCount(rowCount));
          search();
        }}
        onCurrentPageChange={(newCurrentPage: number) => {
          if (newCurrentPage < currentPage) {
            handleOnClickPrevious();
          } else {
            handleOnClickNext();
          }
          dispatch(setInstantWinEventCurrentPage(newCurrentPage));
        }}
      />
    </div>
  );
};
