import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'client/components/Modal/Modal';
import { Button } from 'client/components/Form';
import baseStyles from 'client/base.module.css';
import componentStyles from 'client/components/components.module.css';
import { SystemFeeInvoice } from 'shared/models/swagger';
import { getFormattedAmount } from 'client/libraries/util/getFormattedAmount';

import { FareAdjustmentItem } from './formValues';

interface Props {
  systemFeeInvoice: SystemFeeInvoice;
  onClose: () => void;
}

export const SystemFeeFareAdjustmentModalForSupplier = ({
  systemFeeInvoice,
  onClose,
}: Props) => {
  const { t } = useTranslation();

  const fareAdjustments: FareAdjustmentItem[] = React.useMemo(() => {
    return (
      systemFeeInvoice?.fare_adjustment?.fare_adjustment_items?.map(
        (item: any) => ({
          amount: getFormattedAmount(item.amount_adjustment ?? ''),
          note: item.note ?? '',
        })
      ) ?? []
    );
  }, [systemFeeInvoice]);

  return (
    <Modal open={true} title={t('Fare Adjustment')} onClose={onClose}>
      <Modal.Content>
        {fareAdjustments && fareAdjustments.length > 0 && (
          <div className={componentStyles['c-table-nowrap']}>
            <table>
              <tbody>
                <tr>
                  <th
                    className={baseStyles['base-t-48']}
                    style={{ textAlign: 'center' }}
                  >
                    {t('Adjusted Amount')}
                  </th>
                  <th
                    className={baseStyles['base-t-112']}
                    style={{ textAlign: 'center' }}
                  >
                    {t('Adjustment Reason')}
                  </th>
                </tr>
                {fareAdjustments.map((adjustment, idx) => (
                  <tr key={idx}>
                    <td
                      className={baseStyles['base-t-48']}
                      style={{ textAlign: 'right' }}
                    >
                      {adjustment.amount}
                    </td>
                    <td className={baseStyles['base-t-112']}>
                      {adjustment.note}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button style="gray" size="middle" onClick={() => onClose()}>
          {t('Close')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
