import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { FORM_ERROR } from 'final-form';
import createDecorator from 'final-form-focus';
import { v4 as uuidv4 } from 'uuid';

import {
  batchGetTranslations,
  updateTranslations,
} from 'client/actions/translations';
import { isPartnershipAgent } from 'client/libraries/util/partnership';
import { Snackbar } from 'client/components/v3/Common/Snackbar';
import { ImageVideoAudioInput } from 'client/components/v3/ImageVideoAudioInput/ImageVideoAudioInput';
import { getLanguageName } from 'client/libraries/i18n';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import { TextField } from 'client/components/v3/Form/TextField';
import { TextArea } from 'client/components/v3/Form/TextArea';
import { PcSidebarMenu } from 'client/pages/v3/BookingWidget/BookingWidgetTopPage/Menu/PcSidebarMenu';
import { Section } from 'client/pages/v3/BookingWidget/BookingWidgetSettings/Menu/menuType';
import { CollapsibleSection } from 'client/pages/v3/BookingWidget/BookingWidgetTopPage/CollapsibleSection/CollapsibleSection';
import { CollapsibleBox } from 'client/pages/v3/BookingWidget/BookingWidgetTopPage/CollapsibleSection/CollapsibleBox';
import { ProductDraggableList } from 'client/pages/v3/BookingWidget/BookingWidgetTopPage/ProductDraggableList/ProductDraggableList';
import { EditCustomIntroductionSection } from 'client/pages/v3/BookingWidget/BookingWidgetTopPage/TopPageSectionsEditor/EditCustomIntroductionSection';
import { EditRecommendedProductsSection } from 'client/pages/v3/BookingWidget/BookingWidgetTopPage/TopPageSectionsEditor/EditRecommendedProductsSection';
import { EditAdditionalImage } from 'client/pages/v3/BookingWidget/BookingWidgetTopPage/TopPageSectionsEditor/EditAdditionalImage';
import { EditCustomProductListSection } from 'client/pages/v3/BookingWidget/BookingWidgetTopPage/TopPageSectionsEditor/EditCustomProductListSection';
import { Button } from 'client/components/v3/Common/Button';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { EnumRadioButtonGroup } from 'client/components/v3/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { getProductSalesStatus } from 'client/libraries/util/getProductSalesStatus';
import { ScrollToContext } from 'client/contexts/ScrollToContext';
import {
  activeUserOrganizationSelector,
  bookingWidgetPMPSupportedLanguagesSelector,
} from 'client/reducers/user';
import { getArrayMutators } from 'client/libraries/util/form';
import { updateOrganization } from 'client/actions/organizations';
import {
  fetchProducts,
  fetchPassthroughCandidateProducts,
} from 'client/actions/products';
import { bookingWidgetProductSummariesSelector } from 'client/reducers/products';
//import { getLanguageName } from 'client/libraries/i18n';
import type { ReduxState } from 'client/reducers';
import { getOrderedProductIds } from 'client/libraries/util/getOrderedProductIds';
import { SourceLanguage } from 'shared/models/swagger';
import baseStyles from 'client/v3-base.module.css';
import styles from 'client/pages/v3/BookingWidget/BookingWidgetTopPage/BookingWidgetTopPage.module.css';
import type { Translation } from 'shared/models/swagger';

import {
  convertFormValuesToOrganizationPatch,
  getCustomSectionFormValues,
} from './FormValues';
//import { getSectionName, deleteTopPageSection } from './util';
import { getSectionName } from './util';
import type { TopPageFormValues, CustomProductList } from './FormValues';

const focusOnError: any = createDecorator();
const emptyTranslation = {
  en_us: '',
};
export const BookingWidgetTopPageBody = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const supportedLanguages = useSelector(
    bookingWidgetPMPSupportedLanguagesSelector
  );
  const allProducts = useSelector(bookingWidgetProductSummariesSelector);
  const originalTranslations = useSelector(
    (state: ReduxState) => state.translations.all
  );

  const [currentSectionId, setCurrentSectionId] = React.useState('info');
  const [showTranslateWebTitle, setShowTranslateWebTitle] =
    React.useState<boolean>(false);
  const [webTitleTranslation, setWebTitleTranslation] =
    React.useState<Translation>(emptyTranslation);
  const [showTranslateDesc, setShowTranslateDesc] =
    React.useState<boolean>(false);
  const [descTranslation, setDescTranslation] =
    React.useState<Translation>(emptyTranslation);
  const [showTranslatePhrase, setShowTranslatePhrase] =
    React.useState<boolean>(false);
  const [phraseTranslation, setPhraseTranslation] =
    React.useState<Translation>(emptyTranslation);
  const [saveSucceeded, setSaveSucceeded] = React.useState<boolean>(false);
  const sections: Section[] = [
    {
      title: t('Basic Information'),
      id: 'info',
      hasPermission: true,
    },
    {
      title: t('Customization'),
      id: 'customization',
      hasPermission: true,
    },
    {
      title: t('Product List'),
      id: 'products',
      hasPermission: true,
    },
  ];
  const [collapsibleBoxOnDrug, setCollapsibleBoxOnDrug] =
    React.useState<boolean>(false);

  const languageOptions = supportedLanguages.map((lang) => ({
    value: lang as string,
    label: getLanguageName(lang, t),
  }));
  const initialSelectedLanguage =
    (supportedLanguages ?? []).length > 0 ? supportedLanguages[0] : 'JA_JP';
  const initialProductIds = React.useMemo(
    () =>
      getOrderedProductIds(
        activeUserOrganization,
        allProducts,
        initialSelectedLanguage
      ),
    [activeUserOrganization, allProducts, initialSelectedLanguage]
  );
  const initialValues = React.useMemo(() => {
    return {
      bannerImageUrls: [
        activeUserOrganization?.booking_widget_design_params?.banner_image_url,
        ...(activeUserOrganization?.booking_widget_design_params
          ?.additional_banner_image_urls ?? []),
      ].filter((url) => Boolean(url)),
      bannerTitle:
        activeUserOrganization?.booking_widget_design_params?.banner_title,
      bannerDescription:
        activeUserOrganization?.booking_widget_design_params
          ?.banner_description,
      headerDescription:
        activeUserOrganization?.booking_widget_design_params
          ?.header_description_text,
      productListLanguage: initialSelectedLanguage,
      sectionLanguage: initialSelectedLanguage,
      productIds: initialProductIds,
      customSections: getCustomSectionFormValues(
        activeUserOrganization,
        initialSelectedLanguage
      ),
    };
  }, [initialProductIds, activeUserOrganization, initialSelectedLanguage]);
  React.useEffect(() => {
    if (activeUserOrganization) {
      dispatch(fetchProducts());
      if (isPartnershipAgent(activeUserOrganization)) {
        dispatch(
          fetchPassthroughCandidateProducts({
            agent_id:
              activeUserOrganization?.corresponding_organization_id ?? '',
          })
        );
      }
    }
  }, [activeUserOrganization]);

  React.useEffect(() => {
    dispatch(
      batchGetTranslations([
        initialValues.headerDescription || '',
        initialValues.bannerDescription || '',
        initialValues.bannerTitle || '',
      ])
    );
  }, [
    dispatch,
    initialValues.headerDescription,
    initialValues.bannerDescription,
    initialValues.bannerTitle,
  ]);
  const reset = React.useCallback(() => {
    if (originalTranslations.length > 0) {
      setWebTitleTranslation(originalTranslations[0]);
      setDescTranslation(originalTranslations?.[1]);
      setPhraseTranslation(originalTranslations?.[2]);
    } else {
      setWebTitleTranslation(emptyTranslation);
      setDescTranslation(emptyTranslation);
      setPhraseTranslation(emptyTranslation);
    }
  }, [originalTranslations]);
  React.useEffect(() => {
    reset();
  }, [originalTranslations, reset]);

  const scrollTo = React.useContext(ScrollToContext);
  const sourceLanguage = activeUserOrganization?.source_language || 'EN_US';
  const translationLanguages = (
    activeUserOrganization?.supported_languages || []
  ).filter((lang) => lang !== sourceLanguage);
  const shouldShowMultilingualSettings =
    [...new Set([sourceLanguage, ...translationLanguages])].length > 1;
  return (
    <div className={baseStyles['l-main__body']}>
      <div className={baseStyles['l-main__body__flex']}>
        <div className={baseStyles['l-main__body__flex__left']}>
          <Form
            onSubmit={async (values: TopPageFormValues) => {
              const orgId = activeUserOrganization?.id || '';
              const patch = convertFormValuesToOrganizationPatch(
                activeUserOrganization,
                values
              );

              try {
                await dispatch(updateOrganization(orgId, 'SUPPLIER', patch));
                await dispatch(
                  updateTranslations([
                    {
                      ...webTitleTranslation,
                      [sourceLanguage.toLowerCase()]: values.headerDescription,
                      source_language: sourceLanguage,
                    },
                    {
                      ...descTranslation,
                      [sourceLanguage.toLowerCase()]: values.bannerDescription,
                      source_language: sourceLanguage,
                    },
                    {
                      ...phraseTranslation,
                      [sourceLanguage.toLowerCase()]: values.bannerTitle,
                      source_language: sourceLanguage,
                    },
                  ])
                );
                scrollTo(0, 0);
                setSaveSucceeded(true);
              } catch (err) {
                return {
                  [FORM_ERROR]: t('Save Failed'),
                };
              }
            }}
            mutators={getArrayMutators()}
            decorators={[focusOnError]}
            initialValues={initialValues}
            keepDirtyOnReinitialize
          >
            {({ handleSubmit, submitting, form }) => {
              return (
                <form onSubmit={handleSubmit}>
                  {saveSucceeded && (
                    <Snackbar
                      text={t('Save Successful')}
                      color="success"
                      shouldShow={saveSucceeded}
                      onClose={() => setSaveSucceeded(false)}
                    />
                  )}
                  <section className={styles['g-section']}>
                    <CollapsibleSection
                      id="info"
                      title={t('Basic Information')}
                      setCurrentSectionId={setCurrentSectionId}
                    >
                      <ul className={styles['p-topPage-list']}>
                        <li className={styles['p-topPage-list__item']}>
                          <div className={styles['p-topPage-list__item__ttl']}>
                            <div
                              className={
                                styles['p-topPage-list__item__ttl__txt']
                              }
                            >
                              <div>{t('Website Title')}</div>
                            </div>
                          </div>
                          <div className={styles['p-topPage-list__item__body']}>
                            <div
                              className={
                                styles[
                                  'p-topPage-products__section__body__item'
                                ]
                              }
                            >
                              <Field name="headerDescription">
                                {({ input }) => <TextField {...input} />}
                              </Field>
                            </div>
                            {shouldShowMultilingualSettings && (
                              <>
                                <div
                                  className={
                                    styles[
                                      'p-topPage-products__section__body__item'
                                    ]
                                  }
                                >
                                  <Toggle
                                    label={t('Translation')}
                                    checked={showTranslateWebTitle}
                                    onChange={() =>
                                      setShowTranslateWebTitle(
                                        !showTranslateWebTitle
                                      )
                                    }
                                  />
                                </div>
                                {showTranslateWebTitle && (
                                  <div
                                    className={
                                      styles[
                                        'p-topPage-products__section__body__item'
                                      ]
                                    }
                                  >
                                    <div className={styles['p-topPage-box']}>
                                      <div
                                        className={
                                          styles['p-topPage-box__list']
                                        }
                                      >
                                        {translationLanguages.map(
                                          (lang, idx) => (
                                            <div
                                              key={idx}
                                              className={
                                                styles[
                                                  'p-topPage-box__list__item'
                                                ]
                                              }
                                            >
                                              <TextField
                                                label={getLanguageName(
                                                  uppercaseIsoToLowercaseIso[
                                                    lang
                                                  ],
                                                  t
                                                )}
                                                value={
                                                  webTitleTranslation[
                                                    lang.toLowerCase() as keyof Translation
                                                  ] || ''
                                                }
                                                onChange={(value) => {
                                                  setWebTitleTranslation({
                                                    ...webTitleTranslation,
                                                    [lang.toLowerCase()]: value,
                                                  });
                                                }}
                                              />
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </li>
                        <li className={styles['p-topPage-list__item']}>
                          <div className={styles['p-topPage-list__item__ttl']}>
                            <div
                              className={
                                styles['p-topPage-list__item__ttl__txt']
                              }
                            >
                              <div>{t('Website Description')}</div>
                            </div>
                          </div>
                          <div className={styles['p-topPage-list__item__body']}>
                            <div
                              className={
                                styles[
                                  'p-topPage-products__section__body__item'
                                ]
                              }
                            >
                              <Field name="bannerDescription">
                                {({ input }) => <TextArea {...input} />}
                              </Field>
                            </div>
                            {shouldShowMultilingualSettings && (
                              <>
                                <div
                                  className={
                                    styles[
                                      'p-topPage-products__section__body__item'
                                    ]
                                  }
                                >
                                  <Toggle
                                    label={t('Translation')}
                                    checked={showTranslateDesc}
                                    onChange={() =>
                                      setShowTranslateDesc(!showTranslateDesc)
                                    }
                                  />
                                </div>
                                {showTranslateDesc && (
                                  <div
                                    className={
                                      styles[
                                        'p-topPage-products__section__body__item'
                                      ]
                                    }
                                  >
                                    <div className={styles['p-topPage-box']}>
                                      <div
                                        className={
                                          styles['p-topPage-box__list']
                                        }
                                      >
                                        {translationLanguages.map(
                                          (lang, idx) => (
                                            <div
                                              key={idx}
                                              className={
                                                styles[
                                                  'p-topPage-box__list__item'
                                                ]
                                              }
                                            >
                                              <TextArea
                                                label={getLanguageName(
                                                  uppercaseIsoToLowercaseIso[
                                                    lang
                                                  ],
                                                  t
                                                )}
                                                value={
                                                  descTranslation[
                                                    lang.toLowerCase() as keyof Translation
                                                  ] || ''
                                                }
                                                onChange={(value) => {
                                                  setDescTranslation({
                                                    ...descTranslation,
                                                    [lang.toLowerCase()]: value,
                                                  });
                                                }}
                                              />
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </li>
                        <li className={styles['p-topPage-list__item']}>
                          <div className={styles['p-topPage-list__item__ttl']}>
                            <div
                              className={
                                styles['p-topPage-list__item__ttl__txt']
                              }
                            >
                              <div>{t('Catchphrase')}</div>
                            </div>
                          </div>
                          <div className={styles['p-topPage-list__item__body']}>
                            <div
                              className={
                                styles[
                                  'p-topPage-products__section__body__item'
                                ]
                              }
                            >
                              <Field name="bannerTitle">
                                {({ input }) => <TextField {...input} />}
                              </Field>
                            </div>
                            {shouldShowMultilingualSettings && (
                              <>
                                <div
                                  className={
                                    styles[
                                      'p-topPage-products__section__body__item'
                                    ]
                                  }
                                >
                                  <Toggle
                                    label={t('Translation')}
                                    checked={showTranslatePhrase}
                                    onChange={() =>
                                      setShowTranslatePhrase(
                                        !showTranslatePhrase
                                      )
                                    }
                                  />
                                </div>
                                {showTranslatePhrase && (
                                  <div
                                    className={
                                      styles[
                                        'p-topPage-products__section__body__item'
                                      ]
                                    }
                                  >
                                    <div className={styles['p-topPage-box']}>
                                      <div
                                        className={
                                          styles['p-topPage-box__list']
                                        }
                                      >
                                        {translationLanguages.map(
                                          (lang, idx) => (
                                            <div
                                              key={idx}
                                              className={
                                                styles[
                                                  'p-topPage-box__list__item'
                                                ]
                                              }
                                            >
                                              <TextField
                                                label={getLanguageName(
                                                  uppercaseIsoToLowercaseIso[
                                                    lang
                                                  ],
                                                  t
                                                )}
                                                value={
                                                  phraseTranslation[
                                                    lang.toLowerCase() as keyof Translation
                                                  ] || ''
                                                }
                                                onChange={(value) => {
                                                  setPhraseTranslation({
                                                    ...phraseTranslation,
                                                    [lang.toLowerCase()]: value,
                                                  });
                                                }}
                                              />
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </li>
                        <li className={styles['p-topPage-list__item']}>
                          <div className={styles['p-topPage-list__item__ttl']}>
                            <div
                              className={
                                styles['p-topPage-list__item__ttl__txt']
                              }
                            >
                              <div>{t('Key visual')}</div>
                            </div>
                          </div>
                          <div className={styles['p-topPage-list__item__body']}>
                            <div
                              className={
                                styles[
                                  'p-topPage-products__section__body__item'
                                ]
                              }
                            >
                              <Field name="bannerImageUrls">
                                {({ input }) => (
                                  <ImageVideoAudioInput
                                    fileUrls={input.value || []}
                                    onChange={(newValue) =>
                                      input.onChange(newValue)
                                    }
                                    disableYoutubeVideos
                                  />
                                )}
                              </Field>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </CollapsibleSection>
                    <CollapsibleSection
                      id="customization"
                      title={t('Customization')}
                      setCurrentSectionId={setCurrentSectionId}
                    >
                      <dl
                        className={styles['p-topPage-products__section__list']}
                      >
                        <dt
                          className={
                            styles['p-topPage-products__section__list__term']
                          }
                        >
                          <span>{t('Content Language')}</span>
                        </dt>
                        <EnumRadioButtonGroup
                          name="sectionLanguage"
                          options={languageOptions}
                          onChange={(value) => {
                            form.change(
                              'customSections',
                              getCustomSectionFormValues(
                                activeUserOrganization,
                                value as SourceLanguage
                              )
                            );
                          }}
                        />
                      </dl>
                      <FieldArray name="customSections">
                        {({ fields }) => {
                          return (
                            <>
                              {fields.map((name, idx) => (
                                <CollapsibleBox
                                  key={`${name}-${fields.value?.[idx].key}`}
                                  index={idx}
                                  id={`${name}-${fields.value?.[idx].key}`}
                                  parentId={`${name}-${fields.value?.[0].key}`}
                                  title={getSectionName(fields.value?.[idx], t)}
                                  isDeleteable={
                                    fields.value?.[idx].sectionType ===
                                    'CUSTOM_PRODUCT_LIST'
                                  }
                                  onDelete={async () => {
                                    await fields.remove(idx);
                                  }}
                                  moveItem={async (
                                    dragIndex: number,
                                    hoverIndex: number
                                  ) => {
                                    await fields.move(dragIndex, hoverIndex);
                                  }}
                                  setCollapsibleBoxOnDrug={
                                    setCollapsibleBoxOnDrug
                                  }
                                  collapsibleBoxOnDrug={collapsibleBoxOnDrug}
                                >
                                  <>
                                    {fields.value?.[idx]?.sectionType ===
                                      'ADDITIONAL_IMAGES_SECTION' && (
                                      <EditAdditionalImage
                                        name={name}
                                        parentId={`${name}-${fields.value?.[idx].key}`}
                                      />
                                    )}
                                    {fields.value?.[idx]?.sectionType ===
                                      'CUSTOM_INTRODUCTION' && (
                                      <EditCustomIntroductionSection
                                        name={name}
                                      />
                                    )}
                                    {fields.value?.[idx]?.sectionType ===
                                      'RECOMMENDED_PRODUCT_LIST' && (
                                      <EditRecommendedProductsSection
                                        name={name}
                                      />
                                    )}
                                    {fields.value?.[idx]?.sectionType ===
                                      'CUSTOM_PRODUCT_LIST' && (
                                      <EditCustomProductListSection
                                        name={name}
                                      />
                                    )}
                                  </>
                                </CollapsibleBox>
                              ))}
                              <a
                                className={styles['add__button']}
                                onClick={() => {
                                  const initialValue: CustomProductList = {
                                    sectionType: 'CUSTOM_PRODUCT_LIST',
                                    title: '',
                                    key: uuidv4(),
                                    productIds: [''],
                                  };
                                  fields.push(initialValue);
                                }}
                              >
                                <i className="c-icon-outline-general-plus-circle"></i>
                                {t('Add Custom Product List')}
                              </a>
                            </>
                          );
                        }}
                      </FieldArray>
                    </CollapsibleSection>
                    <CollapsibleSection
                      id="products"
                      title={t('Product List')}
                      setCurrentSectionId={setCurrentSectionId}
                    >
                      <dl
                        className={styles['p-topPage-products__section__list']}
                      >
                        <dt
                          className={
                            styles['p-topPage-products__section__list__term']
                          }
                        >
                          <span>{t('Content Language')}</span>
                        </dt>
                        <EnumRadioButtonGroup
                          name="productListLanguage"
                          options={languageOptions}
                          onChange={(value) => {
                            form.change(
                              'productIds',
                              getOrderedProductIds(
                                activeUserOrganization,
                                allProducts,
                                value as SourceLanguage
                              )
                            );
                          }}
                        />
                      </dl>
                      <dl
                        className={styles['p-topPage-products__section__list']}
                      >
                        <dt
                          className={
                            styles['p-topPage-products__section__list__term']
                          }
                        >
                          <span>{t('Product List')}</span>
                          <p
                            className={
                              styles['p-topPage-acBox__body__list__desc__txt']
                            }
                          >
                            {t('* drag-and-drop to reorder')}
                          </p>
                        </dt>
                        <Field name="productIds">
                          {({ input }) => (
                            <ProductDraggableList
                              options={input.value.map((productId: string) => {
                                const product = allProducts?.find(
                                  (product) => product.id === productId
                                );
                                return {
                                  key: productId,
                                  text: product?.product_name ?? '',
                                  status: getProductSalesStatus(
                                    product ?? null
                                  ),
                                };
                              })}
                              moveItem={(newItems) => {
                                input.onChange(
                                  newItems.map((item) => item.key)
                                );
                              }}
                            />
                          )}
                        </Field>
                      </dl>
                    </CollapsibleSection>
                  </section>
                  <div
                    className={clsx(
                      styles['p-topPage-products__fixed'],
                      styles['is-active']
                    )}
                  >
                    <div className={styles['p-topPage-products__fixed__main']}>
                      <Button
                        text={t('Save')}
                        type="submit"
                        color="primary"
                        size="md"
                        loading={submitting}
                        style={{
                          width: '60px',
                          fontWeight: 'var(--text-semibold)',
                        }}
                      />
                    </div>
                  </div>
                </form>
              );
            }}
          </Form>
        </div>
        <div
          className={clsx(
            baseStyles['l-main__body__flex__right'],
            baseStyles['sticky']
          )}
        >
          <PcSidebarMenu
            sections={sections}
            currentSectionId={currentSectionId}
          />
        </div>
      </div>
    </div>
  );
};
