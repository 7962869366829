import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import QRCode from 'qrcode.react';
import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { deleteWaitTime, fetchWaitTimes } from 'client/actions/waitTimes';
import {
  PagedGenericTable,
  ColumnType,
} from 'client/components/v3/PagedGenericTable/PagedGenericTable';
import { DigitalMap, Restaurant, WaitTime } from 'shared/models/swagger';
import { V3Page } from 'client/components/v3/Page/V3Page';
import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { PageContent } from 'client/components/v3/Page/PageContent';
import { Button } from 'client/components/v3/Common/Button';
import { fetchRestaurants } from 'client/actions/restaurants';
import { fetchDigitalMaps } from 'client/actions/digitalMaps';
import { Box } from 'client/components/Box/Box';
import { DeleteConfirmModal } from 'client/components/v3/DeleteConfirmModal/DeleteConfirmModal';
import { Badge } from 'client/components/v3/Common/Badge';
import { loggedInAccountIsNutmegAdminSelector } from 'client/reducers/user';
import graphIcon from 'client/images/map/ic_graph.svg';
import { config } from 'client/config';

import styles from './WaitTimeList.module.css';
import { EditWaitTimeModal } from './EditWaitTimeModal';
import { GraphModal } from './GraphModal';

type Pin = Exclude<DigitalMap['pins'], undefined>[number];

export const WaitTimeManagerLink = () => {
  const loggedInAccountIsNutmegAdmin = useSelector(
    loggedInAccountIsNutmegAdminSelector
  );

  const { t } = useTranslation();
  const [hoveredLink, setHoveredLink] = React.useState<string | null>(null);
  return (
    <>
      <div
        className={styles.manageLink}
        onMouseOver={() =>
          setHoveredLink(
            `${window.location.origin.toString()}/maps/waittimesmanager`
          )
        }
        onMouseOut={() => setHoveredLink(null)}
      >
        <Link
          target={!loggedInAccountIsNutmegAdmin ? '_blank' : undefined}
          to="/maps/waittimesmanager"
        >
          {t('Management Tool')}
        </Link>
      </div>

      {false &&
        hoveredLink &&
        createPortal(
          <div className={styles['modal-overlay']}>
            <div className={styles['modal']}>
              <QRCode value={hoveredLink ?? ''} size={200} />
            </div>
          </div>,
          document.body
        )}
    </>
  );
};

export const GraphButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <button className={styles.graphButton} onClick={onClick}>
      <img src={graphIcon} className={styles.graphButtonIcon} />
    </button>
  );
};

const Actions = ({
  waitTime,
  onClickEditButton,
}: {
  waitTime: WaitTime;
  onClickEditButton: (waitTime: WaitTime) => void;
}) => {
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showGraphModal, setShowGraphModal] = React.useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <>
      <Box display="flex" alignItems="center" gap={2}>
        <div
          className={styles.actionButton}
          onClick={() => {
            onClickEditButton(waitTime);
          }}
        >
          <i className="c-icon-outline-general-edit-05"></i>
          <p>{t('Edit')}</p>
        </div>
        <div
          className={clsx(styles.actionButton, styles.red)}
          onClick={() => {
            setShowDeleteModal(true);
          }}
        >
          <i className="c-icon-outline-general-trash-03"></i>
          <p>{t('Delete')}</p>
        </div>
        {config.enableWaitTimeHistory && (
          <GraphButton
            onClick={() => {
              setShowGraphModal(true);
            }}
          />
        )}
      </Box>
      {showDeleteModal && (
        <DeleteConfirmModal
          insertAtRoot
          header={t('Delete Wait Time')}
          content={t('Are you sure you want to delete this wait time?')}
          onConfirm={async () => {
            await dispatch(deleteWaitTime(waitTime.id ?? ''));
          }}
          onClose={() => setShowDeleteModal(false)}
        />
      )}
      {showGraphModal && (
        <GraphModal
          waitTime={waitTime}
          onClose={() => setShowGraphModal(false)}
        />
      )}
    </>
  );
};

export const WaitTimeList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showEditWaitTimeModal, setShowEditWaitTimeModal] =
    React.useState(false);
  const [editingWaitTime, setEditingWaitTime] = React.useState<WaitTime | null>(
    null
  );
  const waitTimes = useSelector((state: any) => state.waitTimes.all);
  const restaurants = useSelector((state: any) => state.restaurants.all);
  const digitalMaps = useSelector((state: any) => state.digitalMaps.all);

  const columns: ColumnType<WaitTime>[] = [
    {
      Header: t('Location'),
      accessor: (waitTime: WaitTime) => {
        if (waitTime.restaurant_id) {
          return restaurants.find(
            (restaurant: Restaurant) => restaurant.id === waitTime.restaurant_id
          )?.name;
        }
        return digitalMaps
          .find((map: DigitalMap) => map.id === waitTime.map_id)
          ?.pins?.find((pin: Pin) => pin.key === waitTime.pin_key)?.title;
      },
      id: 'location',
    },
    {
      Header: t('Location Type'),
      accessor: (waitTime: WaitTime) => {
        if (waitTime.restaurant_id) {
          return t('Restaurant');
        }
        return t('Map Pin');
      },
      id: 'locationType',
    },
    {
      Header: t('Wait Time'),
      accessor: (waitTime: WaitTime) =>
        !waitTime.enabled
          ? '-'
          : waitTime.is_closed
          ? t('Closed')
          : t('{{waitTime}} minutes', {
              waitTime: waitTime.wait_time_minutes || 0,
            }),
      id: 'waitTime',
    },
    {
      Header: t('Status'),
      accessor: (waitTime: WaitTime) =>
        waitTime.enabled ? (
          <Badge label={t('Enabled')} color="green" />
        ) : (
          <Badge label={t('Disabled')} color="gray" />
        ),
      id: 'enabled',
    },
    {
      Header: '',
      accessor: (waitTime: WaitTime) => (
        <Actions
          waitTime={waitTime}
          onClickEditButton={(waitTime: WaitTime) => {
            setShowEditWaitTimeModal(true);
            setEditingWaitTime(waitTime);
          }}
        />
      ),
      id: 'edit',
    },
  ];

  React.useEffect(() => {
    dispatch(fetchWaitTimes());
    dispatch(fetchRestaurants());
    dispatch(fetchDigitalMaps());
  }, [dispatch]);

  return (
    <V3Page>
      <PageHeader title={t('Wait Times')} />
      <PageContent>
        <div className={styles.actions}>
          <WaitTimeManagerLink />
          <Button
            text={t('Create Wait Time')}
            onClick={() => setShowEditWaitTimeModal(true)}
          />
        </div>
        <PagedGenericTable allItems={waitTimes} columns={columns} />
      </PageContent>
      {showEditWaitTimeModal && (
        <EditWaitTimeModal
          existingWaitTime={editingWaitTime}
          onClose={() => {
            setEditingWaitTime(null);
            setShowEditWaitTimeModal(false);
          }}
        />
      )}
    </V3Page>
  );
};
