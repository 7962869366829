import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import _ from 'lodash';

import { Box } from 'client/components/Box/Box';
import {
  ColumnType,
  GenericTable,
} from 'client/components/v3/GenericTable/GenericTable';
import { Badge, BadgeColor } from 'client/components/v3/Common/Badge';
import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { fetchAnalyticsCustomers } from 'client/actions/analyticsCustomers';
import { ReduxState } from 'client/reducers';
import { AnalyticsCustomer } from 'shared/models/swagger';
import { formattedDuration } from 'client/libraries/util/formattedDuration';
import { Button } from 'client/components/v3/Common/Button';
import { journeyAnalyticsSearchPresetsSelector } from 'client/reducers/supplierSettings';
import {
  setWebJourneyCustomerListActiveTab,
  setWebJourneyCustomerListCurrentPage,
  setWebJourneyCustomerListPageSize,
  setWebJourneyCustomerListSelectedPresetName,
  setWebJourneyCustomerListVisibleColumns,
} from 'client/actions/webJourneyCustomerListControls';

import { formatLandingSource } from '../JourneyAnalyticsCustomerDetails/util';

import styles from './JourneyAnalyticsCustomerList.module.css';
import { ColumnSettingsModal } from './ColumnSettingsModal';
import { SearchForm } from './SearchForm/SearchForm';

export type ColumnId =
  | 'name'
  | 'customerCategory'
  | 'tags'
  | 'sessionCount'
  | 'pageViewCount'
  | 'totalTimeSeconds'
  | 'region';

export const useColumns = (): ColumnType<AnalyticsCustomer>[] => {
  const { t } = useTranslation();
  const settings = useSelector(
    (state: ReduxState) => state.supplierSettings.journeyAnalytics
  );
  const autoTags = settings?.autotags ?? [];

  return [
    {
      Header: t('Name/Cookie ID'),
      id: 'name',
      width: 300,
      accessor: (customer) => (
        <Link to={`/journeyanalytics/customers/${customer.id}`}>
          {customer.customer_display_name || customer.id?.substring(0, 8)}
        </Link>
      ),
    },
    {
      Header: t('Classification'),
      id: 'customerCategory',
      width: 150,
      accessor: (customer) =>
        customer.classification === 'BOOKED' ? (
          <Badge label={t('Booked')} color="success" />
        ) : (
          <Badge label={t('Not Booked')} color="orange" />
        ),
    },
    {
      Header: t('Tags'),
      id: 'tags',
      accessor: (customer) => (
        <div className={styles['tags']}>
          {customer.tags?.map((tag) => {
            const color = autoTags.find((t) => t.tag_name === tag)?.tag_color;

            if (!color) {
              return null;
            }

            return <Badge key={tag} label={tag} color={color as BadgeColor} />;
          })}
        </div>
      ),
    },
    {
      Header: t('Landing Source'),
      id: 'landingSource',
      width: 250,
      accessor: (customer) =>
        formatLandingSource(customer.landing_source || ''),
    },
    {
      Header: t('Sessions'),
      id: 'sessionCount',
      accessor: (customer) => customer?.session_count || 0,
    },
    {
      Header: t('Page views'),
      id: 'pageViewCount',
      accessor: (customer) => customer?.page_view_count || 0,
    },
    {
      Header: t('Total time'),
      id: 'totalTimeSeconds',
      accessor: (customer) =>
        formattedDuration(customer?.total_time_seconds ?? 0, t),
    },
    {
      Header: t('Geo Location'),
      id: 'region',
      accessor: (customer: AnalyticsCustomer) => (
        <div className={styles['region-cell']}>{customer?.region}</div>
      ),
    },
  ];
};

export const JourneyAnalyticsCustomerList = () => {
  const presets = useSelector(journeyAnalyticsSearchPresetsSelector);
  const allColumns = useColumns();
  const [beforePresetLoading, setBeforePresetLoading] = React.useState(true);
  const [showColumnEditModal, setShowColumnEditModal] = React.useState(false);
  const visibleColumns = useSelector(
    (state: ReduxState) => state.webJourneyCustomerListControls.visibleColumns
  );
  const currentPage = useSelector(
    (state: ReduxState) => state.webJourneyCustomerListControls.currentPage
  );
  const rowCount = useSelector(
    (state: ReduxState) => state.webJourneyCustomerListControls.pageSize
  );

  const presetsLoading = useSelector(
    (state: ReduxState) => state.supplierSettings.loading
  );
  const selectedPresetName = useSelector(
    (state: ReduxState) =>
      state.webJourneyCustomerListControls.selectedPresetName
  );
  const activeTab = useSelector(
    (state: ReduxState) => state.webJourneyCustomerListControls.activeTab
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const tabOptions: { value: 'ALL' | 'BOOKED' | 'NOT_BOOKED'; text: string }[] =
    [
      {
        value: 'ALL',
        text: t('All'),
      },
      { value: 'BOOKED', text: t('Booked') },
      { value: 'NOT_BOOKED', text: t('Not Booked') },
    ];

  const selectedPreset = React.useMemo(() => {
    return presets.find((preset) => preset.preset_name === selectedPresetName);
  }, [presets, selectedPresetName]);

  React.useEffect(() => {
    if (presetsLoading) {
      setBeforePresetLoading(false);
    }
  }, [presetsLoading]);

  React.useEffect(() => {
    if (beforePresetLoading) {
      return;
    }

    if (presetsLoading) {
      return;
    }

    dispatch(fetchAnalyticsCustomers(selectedPreset?.condition_groups ?? []));
  }, [selectedPreset, presetsLoading]);

  const customers = useSelector(
    (state: ReduxState) => state.analyticsCustomers.all
  );
  const loading = useSelector(
    (state: ReduxState) => state.analyticsCustomers.loading
  );

  const filteredCustomers: AnalyticsCustomer[] = _.orderBy(
    customers.filter((customer) => {
      if (activeTab === 'ALL') {
        return true;
      }
      return customer.classification === activeTab;
    }),
    ['last_event_date_time_utc'],
    'desc'
  );

  const columns = visibleColumns.map(
    (col) =>
      allColumns.find((c) => c.id === col) as ColumnType<AnalyticsCustomer>
  );

  const pageItems = filteredCustomers.slice(
    (currentPage - 1) * rowCount,
    currentPage * rowCount
  );

  return (
    <Box ml={-4} mr={-4} mt={-4}>
      <PageHeader title={t('Customer List')} />
      <Box pl={8} pr={8}>
        <SearchForm
          selectedPresetName={selectedPresetName}
          setSelectedPresetName={(presetName) =>
            dispatch(setWebJourneyCustomerListSelectedPresetName(presetName))
          }
        />
        <Box mt={4}>
          <GenericTable
            columns={columns}
            currentPage={currentPage}
            onCurrentPageChange={(newPage) =>
              dispatch(setWebJourneyCustomerListCurrentPage(newPage))
            }
            rowCount={rowCount}
            onRowCountChange={(newRowCount) =>
              dispatch(setWebJourneyCustomerListPageSize(newRowCount))
            }
            totalCount={filteredCustomers.length}
            items={pageItems}
            headerBar={
              <div className={styles['header-bar']}>
                <ul className={styles['tab']}>
                  {tabOptions.map((option) => (
                    <li
                      key={option.value}
                      className={clsx(
                        activeTab === option.value && styles['is-active']
                      )}
                      onClick={() =>
                        dispatch(
                          setWebJourneyCustomerListActiveTab(option.value)
                        )
                      }
                    >
                      {option.text}
                    </li>
                  ))}
                </ul>
                <div className={styles['buttons']}>
                  <Button
                    onClick={() => setShowColumnEditModal(true)}
                    text={t('Edit columns')}
                    uiType="bg"
                    size="md"
                    color="white"
                    iconBeforeText={
                      <i className="c-icon-outline-layout-columns-03"></i>
                    }
                  />
                </div>
              </div>
            }
            loading={loading || (Boolean(selectedPresetName) && presetsLoading)}
          />
        </Box>
      </Box>
      {showColumnEditModal && (
        <ColumnSettingsModal
          onClose={() => setShowColumnEditModal(false)}
          columns={visibleColumns as ColumnId[]}
          onColumnsChange={(newColumns) =>
            dispatch(setWebJourneyCustomerListVisibleColumns(newColumns))
          }
        />
      )}
    </Box>
  );
};
