import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field } from 'react-final-form';

import { Box } from 'client/components/Box/Box';
import { FieldWrapper, Select } from 'client/components/Form';
import { getVerboseDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { bookingWidgetProductSummariesSelector } from 'client/reducers/products';

export const EditInstantWinTierETicketForm = () => {
  const { t } = useTranslation();

  const visibleProducts = useSelector(bookingWidgetProductSummariesSelector);

  const productOptions = visibleProducts
    .filter((p) => p.qr_checkin_settings?.should_use_e_ticket)
    .filter((p) => !p.booking_widget_settings?.is_view_only)
    .map((product) => ({
      key: product.id,
      value: product.id,
      text: getVerboseDisplayProductName(product),
    }));

  return (
    <Box mb={2}>
      <FieldWrapper label={t('Select Products')}>
        <Field name="productIdForETicket">
          {({ input }) => (
            <Select
              search
              options={productOptions}
              value={input.value}
              onChange={(_, { value }) => input.onChange(value)}
            />
          )}
        </Field>
      </FieldWrapper>
    </Box>
  );
};
