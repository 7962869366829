import * as React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Field, useFormState, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import ColorPicker from 'rc-color-picker';
import { FieldArray } from 'react-final-form-arrays';
import { v4 as uuidv4 } from 'uuid';

import { Tooltip } from 'client/components/Tooltip/Tooltip';
import { ImageVideoAudioInput } from 'client/components/ImageVideoAudioInput/ImageVideoAudioInput';
import { config } from 'client/config';
import { Box } from 'client/components/Box/Box';
import { Modal } from 'client/components/Modal/Modal';
import {
  Select,
  Input,
  FieldWrapper,
  Radio,
  ToggleButton,
} from 'client/components/Form';
import { TranslationTableHeader } from 'client/components/TranslationTableHeader/TranslationTableHeader';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import baseStyles from 'client/base.module.css';
import styles from 'client/pages/ProductEditor/ProductEditor.module.css';
import type { SourceLanguage } from 'shared/models/swagger';
import { ETicketCouponButtonPreview } from 'client/components/ETicketCouponButtonPreview/ETicketCouponButtonPreview';
import { ETicketRedemptionButtonPreview } from 'client/components/ETicketRedemptionButtonPreview/ETicketRedemptionButtonPreview';
import { ETicketStubRedemptionButtonPreview } from 'client/components/ETicketStubRedemptionButtonPreview/ETicketStubRedemptionButtonPreview';
import { hasSubscription } from 'client/libraries/util/subscriptions';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Add as AddIcon } from 'client/components/Icons/Add';

import { ExpireLimitInput } from '../ExpireLimitInput/ExpireLimitInput';
import { DraggableStubInputForm } from '../DraggableStubInputForm/DraggableStubInputForm';
import { DraggablePresetRedemptionCountInputForm } from '../DraggablePresetRedemptionCountInputForm/DraggablePresetRedemptionCountInputForm';
import { ETicketStubModal } from '../ETicketStubModal/ETicketStubModal';

import { ETicketSettingsDisplayBox } from './ETicketSettingsDisplayBox/ETicketSettingsDisplayBox';
import { ETicketDurationInput } from './ETicketDurationInput';
import { FootprintedModalDisplayKeySelector } from './FootprintedModalDisplayKeySelector';
import { CheckinEndRelativeDateTime } from './CheckinEndRelativeDateTime';
import localStyles from './ETicketSettings.module.css';

type Props = {
  productId: string;
  languageOptions: {
    value: string;
    text: string;
  }[];
  showTranslationMode: boolean;
  translationTargetLanguage: SourceLanguage | null;
  onTranslationModeChange: (arg0: boolean) => void;
  onTranslationTargetLanguageChange: (arg0: SourceLanguage | null) => void;
};
export const ETicketSettingsForm = ({
  productId,
  languageOptions,
  showTranslationMode,
  translationTargetLanguage,
  onTranslationModeChange,
  onTranslationTargetLanguageChange,
}: Props) => {
  const { t } = useTranslation();
  const values = useFormState().values;
  const form = useForm();
  const activeOrganization = useSelector(activeUserOrganizationSelector);

  const [openStub, setOpenStub] = React.useState<{
    name: string;
    key: string;
  } | null>(null);

  const [
    openRedemptionButtonCustomizePane,
    setOpenRedemptionButtonCustomizePane,
  ] = React.useState<boolean>(false);

  const [openGuidanceButtonCustomizePane, setOpenGuidanceButtonCustomizePane] =
    React.useState<boolean>(false);

  const [showRedemptionButtonCustomColor, setShowRedemptionButtonCustomColor] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    if (values && form) {
      if (values?.expirationDate?.deadlineType === 'ETERNAL') {
        form.change('displayType', 'CLOCK');
      }
      if (values?.isFreeStartDateTime) {
        form.change('shouldUseCheckinStartTimeRelative', false);
        form.change('shouldUseCheckinEndTimeRelative', false);
        form.change('shouldUseFootPrint', true);
      }
      if (!values?.shouldUseFootPrint) {
        form.change(
          'shouldShowCustomizedDisplayItemsOnFootprintedModal',
          false
        );
      }
    }
  }, [values, form]);

  const defaultColorSets = [
    {
      key: 'blue',
      colorText: t('Blue'),
      backgroundColor: '#006ABB',
      textColor: '#FFFFFF',
    },
    {
      key: 'green',
      colorText: t('Orange'),
      backgroundColor: '#FF5A26',
      textColor: '#FFFFFF',
    },
    {
      key: 'red',
      colorText: t('Red'),
      backgroundColor: '#BB0000',
      textColor: '#FFFFFF',
    },
    {
      key: 'yellow',
      colorText: t('Pink'),
      backgroundColor: '#FF9298',
      textColor: '#FFFFFF',
    },
    {
      key: 'gray',
      colorText: t('Gray'),
      backgroundColor: '#EEEEEE',
      textColor: '#888888',
    },
  ];

  const colorOptions: { key: string; text: string }[] = [
    ...defaultColorSets.map((colorSet) => {
      return {
        key: colorSet.key,
        text: colorSet.colorText,
      };
    }),
    {
      key: 'customColor',
      text: t('Custom'),
    },
  ];

  const isRedemptionCustomColor = !defaultColorSets.some((colorSet) => {
    return (
      colorSet.backgroundColor === values.redemptionButtonColor &&
      colorSet.textColor === values.redemptionButtonTextColor
    );
  });

  React.useEffect(() => {
    if (isRedemptionCustomColor) {
      setShowRedemptionButtonCustomColor(true);
    } else {
      setShowRedemptionButtonCustomColor(false);
    }
  }, [isRedemptionCustomColor]);

  return (
    <div className={styles['c-tableChild']}>
      <Modal.Box>
        <Field name="shouldUseETicket" type="checkbox">
          {({ input: shouldUseETicketInput }) => (
            <Box mb={2}>
              <ToggleButton
                label={t('Use E-ticket')}
                checked={shouldUseETicketInput.checked}
                onChange={() => {
                  shouldUseETicketInput.onChange(
                    !shouldUseETicketInput.checked
                  );
                  form.change('shouldUseEasyCheckin', false);
                  form.change('shouldCountGuests', false);
                }}
              />
            </Box>
          )}
        </Field>
      </Modal.Box>
      {values?.shouldUseETicket && (
        <>
          <Modal.Box>
            <Modal.Box column={'two'}>
              <div className={baseStyles['base-form-toggle']}>
                <label>
                  <input
                    type="checkbox"
                    checked={showTranslationMode}
                    onChange={() =>
                      onTranslationModeChange(!showTranslationMode)
                    }
                  />
                  <p></p>
                </label>
                {t('Translation mode')}
              </div>
              {showTranslationMode && (
                <Select
                  width={176}
                  options={languageOptions}
                  onChange={(e, { value }: any) => {
                    onTranslationTargetLanguageChange(value);
                  }}
                  value={translationTargetLanguage as any}
                />
              )}
            </Modal.Box>
          </Modal.Box>

          <Modal.Box>
            <FieldWrapper label={t('E-ticket Behaviors')}>
              <Field type="checkbox" name="isFreeStartDateTime">
                {({ input }) => {
                  return (
                    <Modal.Box>
                      <ToggleButton {...input} label={t('Use free pass')} />
                    </Modal.Box>
                  );
                }}
              </Field>

              <Field type="checkbox" name="shouldUseFootPrint">
                {({ input }) => {
                  return (
                    <Modal.Box>
                      <ToggleButton
                        {...input}
                        label={t('Use [Show] button')}
                        disabled={values.isFreeStartDateTime}
                      />
                    </Modal.Box>
                  );
                }}
              </Field>

              {config.enableShowFieldResponsesOnFootprintedModal && (
                <>
                  <Field
                    type="checkbox"
                    name="shouldShowCustomizedDisplayItemsOnFootprintedModal"
                  >
                    {({ input }) => {
                      return (
                        <Modal.Box>
                          <ToggleButton
                            {...input}
                            label={t('Show the field response on ETicket')}
                            disabled={!values.shouldUseFootPrint}
                          />
                        </Modal.Box>
                      );
                    }}
                  </Field>

                  {values.shouldShowCustomizedDisplayItemsOnFootprintedModal && (
                    <Modal.Box>
                      <FootprintedModalDisplayKeySelector
                        name="footprintedModalDisplayItems"
                        translationTargetLanguage={translationTargetLanguage}
                      />
                    </Modal.Box>
                  )}
                </>
              )}

              <Field type="checkbox" name="shouldUseCountGuestsWithGuestType">
                {({ input }) => {
                  return (
                    <Modal.Box>
                      <ToggleButton
                        checked={input.checked}
                        onChange={() => {
                          input.onChange(!input.checked);
                          if (!input.checked) {
                            form.change('shouldUseRedemptionCount', false);
                          }
                        }}
                        label={t('Use split eticket')}
                      />
                    </Modal.Box>
                  );
                }}
              </Field>
              {config.enableETicketRedemptionCount && (
                <Field type="checkbox" name="shouldUseRedemptionCount">
                  {({ input }) => {
                    return (
                      <Modal.Box>
                        <ToggleButton
                          checked={input.checked}
                          onChange={() => {
                            input.onChange(!input.checked);
                            if (!input.checked) {
                              form.change(
                                'shouldUseCountGuestsWithGuestType',
                                false
                              );
                            }
                          }}
                          label={t('Use as a multi-ride ticket')}
                        />
                      </Modal.Box>
                    );
                  }}
                </Field>
              )}

              {values.shouldUseRedemptionCount &&
                (values.stubs ?? []).length === 0 && (
                  <>
                    <Field name="maxRedemptionCount">
                      {({ input }) => {
                        return (
                          <Box display="flex" alignItems="center" mt={2}>
                            <div
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {t('Number of uses')}:
                            </div>
                            <Input
                              type="number"
                              value={`${input.value}`}
                              onChange={(_, { value }) => {
                                if (value.startsWith('-')) {
                                  return;
                                }

                                let newValue = undefined;

                                if (value.length > 0) {
                                  newValue = parseInt(value, 10);

                                  if (isNaN(newValue)) {
                                    newValue = undefined;
                                  } else {
                                    if (newValue < 1) {
                                      newValue = 1;
                                    }
                                  }
                                }
                                input.onChange(newValue);
                              }}
                            />
                          </Box>
                        );
                      }}
                    </Field>

                    {config.enableETicketPresetRedemptionCount && (
                      <Modal.Box>
                        <FieldArray name="presetRedemptionCounts">
                          {({ fields }) => (
                            <>
                              <div className={localStyles['c-table-list']}>
                                <Box mb={2}>
                                  <AddIcon
                                    onClick={() => {
                                      fields.push({
                                        key: uuidv4(),
                                        text: '',
                                        redemptionCount: 0,
                                      });
                                    }}
                                  />
                                </Box>
                                <table>
                                  <tbody
                                    style={{
                                      width: '100%',
                                    }}
                                  >
                                    {translationTargetLanguage && (
                                      <TranslationTableHeader
                                        hideThTag={true}
                                      />
                                    )}
                                    {fields.map((name, idx) => (
                                      <DraggablePresetRedemptionCountInputForm
                                        key={idx}
                                        productId={productId}
                                        idx={idx}
                                        name={name}
                                        translationTargetLanguage={
                                          translationTargetLanguage
                                        }
                                        onRemoveClick={() => {
                                          fields.remove(idx);
                                        }}
                                        onMoveItem={(
                                          dragIndex: number,
                                          hoverIndex: number
                                        ) => {
                                          const dragItem =
                                            fields.value[dragIndex];
                                          const newItems = [...fields.value];
                                          newItems.splice(dragIndex, 1);
                                          newItems.splice(
                                            hoverIndex,
                                            0,
                                            dragItem
                                          );
                                          newItems.forEach((item, index) => {
                                            fields.update(index, item);
                                          });
                                        }}
                                      />
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                              <span
                                className={localStyles['c-table-list__comment']}
                              >
                                {t('Drag-and-drop to reorder')}
                              </span>
                            </>
                          )}
                        </FieldArray>
                      </Modal.Box>
                    )}
                    {config.enableETicketPresetRedemptionCount && (
                      <Field type="checkbox" name="useQrScanOnly">
                        {({ input }) => {
                          return (
                            <Modal.Box>
                              <ToggleButton
                                checked={input.checked}
                                onChange={() => {
                                  input.onChange(!input.checked);
                                }}
                                label={t(
                                  'Make it available only when the QR code is scanned'
                                )}
                              />
                            </Modal.Box>
                          );
                        }}
                      </Field>
                    )}
                  </>
                )}

              <Field type="checkbox" name="showStubSettings">
                {({ input }) => {
                  return (
                    <Modal.Box>
                      <ToggleButton
                        {...input}
                        label={t('Use redemption usage')}
                      />
                    </Modal.Box>
                  );
                }}
              </Field>

              {values.showStubSettings && (
                <Modal.Box>
                  <FieldArray name="stubs">
                    {({ fields }) => (
                      <>
                        <div className={localStyles['c-table-list']}>
                          <Box mb={2}>
                            <AddIcon
                              onClick={() => {
                                fields.push({
                                  key: '',
                                  options: [],
                                });
                                setOpenStub({
                                  name: `stubs[${fields.length}]`,
                                  key: '',
                                });
                                form.change('showStubSettings', true);
                              }}
                            />
                          </Box>
                          {openStub !== null && (
                            <ETicketStubModal
                              name={openStub.name}
                              stubKey={openStub.key}
                              translationTargetLanguage={
                                translationTargetLanguage
                              }
                              open={openStub !== null}
                              onClose={() => setOpenStub(null)}
                            />
                          )}
                          <table>
                            <tbody
                              style={{
                                width: '100%',
                              }}
                            >
                              {translationTargetLanguage && (
                                <TranslationTableHeader hideThTag={true} />
                              )}
                              {fields.map((name, idx) => (
                                <DraggableStubInputForm
                                  key={idx}
                                  idx={idx}
                                  name={name}
                                  translationTargetLanguage={
                                    translationTargetLanguage
                                  }
                                  onRemoveClick={() => {
                                    fields.remove(idx);
                                  }}
                                  onEditClick={() => {
                                    setOpenStub({
                                      name: name,
                                      key: fields.value[idx].key,
                                    });
                                  }}
                                  onMoveItem={(
                                    dragIndex: number,
                                    hoverIndex: number
                                  ) => {
                                    const dragItem = fields.value[dragIndex];
                                    const newItems = [...fields.value];
                                    newItems.splice(dragIndex, 1);
                                    newItems.splice(hoverIndex, 0, dragItem);
                                    newItems.forEach((item, index) => {
                                      fields.update(index, item);
                                    });
                                  }}
                                />
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <span className={localStyles['c-table-list__comment']}>
                          {t('Drag-and-drop to reorder')}
                        </span>
                      </>
                    )}
                  </FieldArray>
                </Modal.Box>
              )}

              {config.enableETicketRedemptionTimeRelative && (
                <>
                  <Field
                    name="shouldUseCheckinStartTimeRelative"
                    type="checkbox"
                  >
                    {({ input: shouldUseCheckinStartTimeRelative }) => (
                      <Modal.Box>
                        <ToggleButton
                          label={t('Set the redemption start time')}
                          checked={shouldUseCheckinStartTimeRelative.checked}
                          onChange={() => {
                            shouldUseCheckinStartTimeRelative.onChange(
                              !shouldUseCheckinStartTimeRelative.checked
                            );
                          }}
                          disabled={values?.isFreeStartDateTime}
                        />
                      </Modal.Box>
                    )}
                  </Field>

                  {values.shouldUseCheckinStartTimeRelative && (
                    <Box mt={2}>
                      <Box>
                        <Field name="checkinStartTimeRelative">
                          {({ input }) => (
                            <ETicketDurationInput required {...input} />
                          )}
                        </Field>
                      </Box>
                      <Box mt={2}>
                        <Box mb={2}>
                          <div>
                            {t(
                              'Precautions before the start of ticket validity'
                            )}
                          </div>
                        </Box>
                        <Box mb={2}>
                          <div className={localStyles['c-table-list']}>
                            <table>
                              <tbody
                                style={{
                                  width: '100%',
                                }}
                              >
                                {translationTargetLanguage && (
                                  <TranslationTableHeader hideThTag={true} />
                                )}
                                <TranslatedField
                                  name={`noteForRedemptionModalBeforeCheckinStartTime`}
                                >
                                  {({ input, translationInput }) => (
                                    <tr>
                                      <td>
                                        <div
                                          className={baseStyles['base-flex']}
                                        >
                                          <Input {...input} />
                                        </div>
                                      </td>
                                      {translationTargetLanguage && (
                                        <td>
                                          <Input {...translationInput} />
                                        </td>
                                      )}
                                    </tr>
                                  )}
                                </TranslatedField>
                              </tbody>
                            </table>
                          </div>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </>
              )}

              {config.enableETicketCheckinEndTime && (
                <>
                  <Field name="shouldUseCheckinEndTimeRelative" type="checkbox">
                    {({ input: shouldUseCheckinEndTimeRelative }) => (
                      <Modal.Box>
                        <ToggleButton
                          label={t('Set the redemption deadline')}
                          checked={shouldUseCheckinEndTimeRelative.checked}
                          onChange={() => {
                            shouldUseCheckinEndTimeRelative.onChange(
                              !shouldUseCheckinEndTimeRelative.checked
                            );
                          }}
                          disabled={values?.isFreeStartDateTime}
                        />
                      </Modal.Box>
                    )}
                  </Field>

                  {values.shouldUseCheckinEndTimeRelative && (
                    <>
                      <Box mb={2}>
                        <div>
                          {t('Deadline for the first ticket redemption')}
                        </div>
                        <CheckinEndRelativeDateTime name="checkinEndRelativeDateTime" />
                      </Box>
                    </>
                  )}
                </>
              )}
            </FieldWrapper>
          </Modal.Box>

          <Modal.Box>
            <FieldWrapper label={t('Expiry from the first ticket redemption')}>
              <ExpireLimitInput
                name="expirationDate"
                activateImmediateExpiration={true}
              />
            </FieldWrapper>
          </Modal.Box>

          {values?.expirationDate?.deadlineType !== 'INSTANT_EXPIRATION' && (
            <Modal.Box>
              <Field name="displayType">
                {({ input }) => (
                  <FieldWrapper label={t('Display Type')}>
                    <Radio
                      label={t('Count down')}
                      checked={input.value === 'COUNTDOWN'}
                      onChange={() => {
                        input.onChange('COUNTDOWN');
                      }}
                      disabled={
                        values?.expirationDate?.deadlineType === 'ETERNAL'
                      }
                    />
                    <Radio
                      label={t('Time')}
                      checked={input.value === 'CLOCK'}
                      onChange={() => {
                        input.onChange('CLOCK');
                      }}
                    />
                  </FieldWrapper>
                )}
              </Field>
            </Modal.Box>
          )}

          <Modal.Box>
            <Field name="termsOfServiceUrl">
              {({ input }) => (
                <Input label={t('Terms of Service URL')} {...input} />
              )}
            </Field>
          </Modal.Box>

          <Modal.Box>
            <FieldWrapper label={t('Ticket Usage Precautions')}>
              <div className={localStyles['c-table-list']}>
                <table>
                  <tbody
                    style={{
                      width: '100%',
                    }}
                  >
                    {translationTargetLanguage && (
                      <TranslationTableHeader hideThTag={true} />
                    )}
                    <TranslatedField name={`noteForRedemptionModal`}>
                      {({ input, translationInput }) => (
                        <tr>
                          <td>
                            <div className={baseStyles['base-flex']}>
                              <Input {...input} />
                            </div>
                          </td>
                          {translationTargetLanguage && (
                            <td>
                              <Input {...translationInput} />
                            </td>
                          )}
                        </tr>
                      )}
                    </TranslatedField>
                  </tbody>
                </table>
              </div>
            </FieldWrapper>
          </Modal.Box>

          <Modal.Box>
            <Field name="barCode">
              {({ input }) => <Input label={t('Bar Code')} {...input} />}
            </Field>
          </Modal.Box>

          <>
            <Modal.Box>
              <details
                className={localStyles['accordion']}
                open={openRedemptionButtonCustomizePane}
                onToggle={() =>
                  setOpenRedemptionButtonCustomizePane(
                    !openRedemptionButtonCustomizePane
                  )
                }
              >
                <summary
                  className={clsx(
                    localStyles['accordion__summary'],
                    openRedemptionButtonCustomizePane
                      ? localStyles['open']
                      : null
                  )}
                  aria-controls="accordion__panel"
                >
                  <span className={clsx(baseStyles['base-link'])}>
                    {t('Customize "Redeem” button')}
                  </span>
                </summary>
                <>
                  <Modal.Box>
                    <FieldWrapper label={t('Button Text')}>
                      <div className={localStyles['c-table-list']}>
                        <table>
                          <tbody
                            style={{
                              width: '100%',
                            }}
                          >
                            {translationTargetLanguage && (
                              <TranslationTableHeader hideThTag={true} />
                            )}
                            <TranslatedField name={`redemptionButtonText`}>
                              {({ input, translationInput }) => (
                                <tr>
                                  <td>
                                    <div className={baseStyles['base-flex']}>
                                      <Input {...input} />
                                    </div>
                                  </td>
                                  {translationTargetLanguage && (
                                    <td>
                                      <Input {...translationInput} />
                                    </td>
                                  )}
                                </tr>
                              )}
                            </TranslatedField>
                          </tbody>
                        </table>
                      </div>
                    </FieldWrapper>
                  </Modal.Box>

                  <Modal.Box>
                    <FieldWrapper label={t('Button Color')}>
                      <>
                        <div className={localStyles['color-selector']}>
                          {colorOptions.map((option, index) => {
                            const colorSet = defaultColorSets.find(
                              (colorSet) => colorSet.key === option.key
                            );

                            const checked =
                              (colorSet?.backgroundColor ===
                                values.redemptionButtonColor &&
                                colorSet?.textColor ===
                                  values.redemptionButtonTextColor) ||
                              (option.key === 'customColor' &&
                                isRedemptionCustomColor);

                            return (
                              <div
                                key={index}
                                className={localStyles['color-box']}
                                onClick={() => {
                                  if (colorSet) {
                                    form.change(
                                      'redemptionButtonColor',
                                      colorSet?.backgroundColor
                                    );
                                    form.change(
                                      'redemptionButtonTextColor',
                                      colorSet?.textColor
                                    );
                                    setShowRedemptionButtonCustomColor(false);
                                  } else {
                                    form.change(
                                      'redemptionButtonColor',
                                      '#000000'
                                    );
                                    form.change(
                                      'redemptionButtonTextColor',
                                      '#FFFFFF'
                                    );
                                    setShowRedemptionButtonCustomColor(true);
                                  }
                                }}
                              >
                                <input
                                  type="checkbox"
                                  checked={checked}
                                  readOnly
                                />
                                <label>
                                  <span>{option.text}</span>
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    </FieldWrapper>
                  </Modal.Box>
                  {showRedemptionButtonCustomColor && (
                    <Modal.Box>
                      <div className={localStyles['color-selector']}>
                        <Box mr={2}>
                          <div className={baseStyles['inline-block']}>
                            <p>{t('Button Color')}</p>
                            <ColorPicker
                              color={values.redemptionButtonColor}
                              onChange={({ color }: any) =>
                                form.change('redemptionButtonColor', color)
                              }
                            />
                          </div>
                        </Box>
                        <Box ml={2}>
                          <div className={baseStyles['inline-block']}>
                            <p>{t('Text Color')}</p>
                            <ColorPicker
                              color={values.redemptionButtonTextColor}
                              onChange={({ color }: any) =>
                                form.change('redemptionButtonTextColor', color)
                              }
                            />
                          </div>
                        </Box>
                      </div>
                    </Modal.Box>
                  )}
                  <Modal.Box column="two">
                    <FieldWrapper label={t('Normal')}>
                      <ETicketRedemptionButtonPreview
                        buttonText={values.redemptionButtonText || t('Redeem')}
                        buttonColor={values.redemptionButtonColor}
                        buttonTextColor={values.redemptionButtonTextColor}
                      />
                    </FieldWrapper>
                    <FieldWrapper label={t('Ticket Usage')}>
                      <ETicketStubRedemptionButtonPreview
                        buttonText={values.redemptionButtonText || t('Redeem')}
                        buttonColor={values.redemptionButtonColor}
                        buttonTextColor={values.redemptionButtonTextColor}
                      />
                    </FieldWrapper>
                  </Modal.Box>
                </>
              </details>
            </Modal.Box>
            {hasSubscription(
              activeOrganization,
              'feature-digital-guidance'
            ) && (
              <>
                <Modal.Box>
                  <details
                    className={localStyles['accordion']}
                    open={openGuidanceButtonCustomizePane}
                    onToggle={() =>
                      setOpenGuidanceButtonCustomizePane(
                        !openGuidanceButtonCustomizePane
                      )
                    }
                  >
                    <summary
                      className={clsx(
                        localStyles['accordion__summary'],
                        openGuidanceButtonCustomizePane
                          ? localStyles['open']
                          : null
                      )}
                      aria-controls="accordion__panel"
                    >
                      <span className={clsx(baseStyles['base-link'])}>
                        {t('Customize button to online guidance')}
                      </span>
                      <Tooltip
                        text={t(
                          'If this product is set up with an online guidance page, a button to open the online guide will be added to the ticket page. By default, label on the button is "Coupon".'
                        )}
                        style={{ fontWeight: 'normal' }}
                      />
                    </summary>
                    <>
                      <Modal.Box>
                        <FieldWrapper label={t('Button Text')}>
                          <div className={localStyles['c-table-list']}>
                            <table>
                              <tbody
                                style={{
                                  width: '100%',
                                }}
                              >
                                {translationTargetLanguage && (
                                  <TranslationTableHeader hideThTag={true} />
                                )}
                                <TranslatedField name={`guidanceButtonText`}>
                                  {({ input, translationInput }) => (
                                    <tr>
                                      <td>
                                        <div
                                          className={baseStyles['base-flex']}
                                        >
                                          <Input {...input} />
                                        </div>
                                      </td>
                                      {translationTargetLanguage && (
                                        <td>
                                          <Input {...translationInput} />
                                        </td>
                                      )}
                                    </tr>
                                  )}
                                </TranslatedField>
                              </tbody>
                            </table>
                          </div>
                        </FieldWrapper>
                      </Modal.Box>

                      <Modal.Box>
                        <Field name="guidanceButtonImageUrl">
                          {({ input, meta: { touched, error } }) => (
                            <FieldWrapper label={t('Button Image')}>
                              <ImageVideoAudioInput
                                fileUrls={input.value ? [input.value] : []}
                                onChange={(newValue) =>
                                  newValue.length > 0
                                    ? input.onChange(newValue[0])
                                    : input.onChange('')
                                }
                                maxFileCount={1}
                                disableYoutubeVideos
                                error={touched && error}
                                enableSvgFormat={true}
                              />
                            </FieldWrapper>
                          )}
                        </Field>
                      </Modal.Box>

                      <Modal.Box>
                        <ETicketCouponButtonPreview
                          buttonText={values.guidanceButtonText || t('Coupon')}
                          buttonImageUrl={values.guidanceButtonImageUrl}
                        />
                      </Modal.Box>
                    </>
                  </details>
                </Modal.Box>
              </>
            )}
          </>

          <Modal.Box>
            <ETicketSettingsDisplayBox />
          </Modal.Box>
        </>
      )}
    </div>
  );
};
