import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import * as Swagger from 'shared/models/swagger';
import baseStyles from 'client/v3-base.module.css';
import { Button } from 'client/components/v3/Common/Button';
import tableStyles from 'client/components/v3/Table/TableSmall.module.css';
import { Snackbar } from 'client/components/v3/Common/Snackbar';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Pagination } from 'client/components/v3/Pagination/Pagination';
import { CustomerAreaGroup } from 'shared/models/swagger';

import styles from './AreaGroupSettings.module.css';
import { ActionButton } from './ActionButton';
import { EditAreaGroupModal } from './EditAreaGroupModal';

export const AreaGroupSettings = () => {
  const { t } = useTranslation();
  const [saveSucceeded, setSaveSucceeded] = useState<boolean>(false);
  const [editingAreaGroupIndex, setEditingAreaGroupIndex] = useState<
    number | null
  >(null);
  const activeOrganization = useSelector(activeUserOrganizationSelector);
  const areaGroups = (activeOrganization?.customer_ledger_settings
    ?.customer_area_groups ?? []) as Swagger.CustomerAreaGroup[];

  // Table handler states and functions
  const [rowCount, setRowCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageItems, setPageItems] = useState<Swagger.CustomerAreaGroup[]>([]);
  const pageCount =
    Math.floor(areaGroups.length / rowCount) +
    (areaGroups.length % rowCount ? 1 : 0);
  const pageForwardClickHandler = () => {
    if (currentPage < pageCount) {
      setCurrentPage(currentPage + 1);
    }
  };
  const pageBackClickHandler = () => {
    if (1 < currentPage) {
      setCurrentPage(currentPage - 1);
    }
  };
  useEffect(() => {
    if (areaGroups.length > 0) {
      const newPageItems = areaGroups.slice(
        (currentPage - 1) * rowCount,
        currentPage * rowCount
      );
      setPageItems(newPageItems);
    }
    // If all items deleted for instance, reset page items
    if (areaGroups.length === 0) {
      setPageItems([]);
    }
  }, [rowCount, currentPage, areaGroups]);

  return (
    <div
      className={clsx(
        styles['p-areaGroupSettings']
        // isActive && styles['is-active']
      )}
    >
      {saveSucceeded && (
        <Snackbar
          text={t('Settings Saved')}
          color="success"
          shouldShow={saveSucceeded}
        />
      )}
      <div className={styles['p-areaGroupSettings__header']}>
        <p className={styles['p-areaGroupSettings__header__ttl']}>
          {t('Area Group Settings')}
        </p>
        <div className={styles['p-areaGroupSettings__header__actions']}>
          <Button
            text={t('Create New Area Group')}
            onClick={() => {
              setEditingAreaGroupIndex(areaGroups.length);
            }}
          />
        </div>
      </div>
      <div className={styles['p-areaGroupSettings__body']}>
        <table
          className={clsx(tableStyles['c-tableSmall'], tableStyles['row'])}
        >
          <thead>
            <tr>
              <th className={baseStyles['u-width-240']}>{t('Area Group')}</th>
              <th>{t('Conditions')}</th>
              <th className={baseStyles['u-width-64']}>{t('Operate')}</th>
            </tr>
          </thead>
          {(areaGroups.length === 0 || pageItems.length === 0) && (
            <tbody>
              <tr>
                <td colSpan={3} className={styles['noData']}>
                  {t(
                    'There are no items that match the specified search conditions'
                  )}
                </td>
              </tr>
            </tbody>
          )}
          {pageItems.length > 0 && (
            <tbody>
              {pageItems.map((areaGroup: CustomerAreaGroup, index: number) => (
                <tr key={index}>
                  <td data-text={t('Area Group')}>{areaGroup.name}</td>
                  <td data-text={t('Conditions')}>
                    {areaGroup.type === 'POSTAL_CODE'
                      ? areaGroup.postal_codes?.join(', ')
                      : areaGroup.province_city_names
                          ?.map(
                            (
                              provinceCityName: Swagger.CustomerProvinceCityName
                            ) => {
                              return `${provinceCityName.province} (${(
                                provinceCityName.cities || []
                              ).join(', ')})`;
                            }
                          )
                          .join(', ')}
                  </td>
                  <td data-text={t('Operate')}>
                    <ActionButton
                      areaGroup={areaGroup}
                      lastItemOnList={index + 1 === areaGroups.length}
                      onEdit={() => {
                        const indexOfAreaGroupToEdit = areaGroups.findIndex(
                          (ag) => ag.key === areaGroup.key
                        );
                        setEditingAreaGroupIndex(indexOfAreaGroupToEdit);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      <div className={styles['p-areaGroupSettings__footer']}>
        <Pagination
          totalItems={areaGroups.length}
          currentPage={currentPage}
          pageCount={pageCount}
          selectedNumberOfLinesToDisplay={rowCount}
          onChangeNumberOfLinesToDisplay={setRowCount}
          onClickPrevButton={pageBackClickHandler}
          onClickNextButton={pageForwardClickHandler}
        />
      </div>

      {editingAreaGroupIndex !== null && (
        <EditAreaGroupModal
          areaGroupIndex={editingAreaGroupIndex}
          onClose={() => setEditingAreaGroupIndex(null)}
          onSubmitSuccess={setSaveSucceeded}
        />
      )}
    </div>
  );
};
