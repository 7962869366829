import { Organization } from 'shared/models/swagger';
import { hasSubscription } from 'client/libraries/util/subscriptions';

const supplierIds = [
  // Prod
  '811617c1-fa09-5cbb-bbd2-c51363a92984', // 長井海の手公園ソレイユの丘
];

export const isOnSiteJourneyAnalyticsSupplier = (
  org: Organization | null,
  loggedInAccountIsNutmegAdmin: boolean
) => {
  if (!org) {
    return false;
  }

  if (hasSubscription(org, 'feature-journey-analytics')) {
    return true;
  }

  if (supplierIds.includes(org.id)) {
    return true;
  }

  if (loggedInAccountIsNutmegAdmin) {
    return true;
  }

  if (
    /app\.dev\.ntmg\.com/.test(window.location.origin) ||
    /localhost:3000/.test(window.location.origin)
  ) {
    return true;
  }

  return false;
};
